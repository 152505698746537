import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
} from "../../shared/custom-react-native";
import "./css.css";
import UI from "../../shared/Components/UI/js";
import ZLayout from "./ZLayout";
const isVisible = (elem) =>
  !!elem &&
  !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
function is_outside_clicked(element_id, callback) {
  let element = document.getElementById(element_id);
  const outsideClickListener = (event) => {
    if (!element.contains(event.target) && isVisible(element)) {
      // or use: event.target.closest(selector) === null
      callback();
      removeClickListener();
    }
  };

  const removeClickListener = () => {
    document.removeEventListener("click", outsideClickListener);
  };

  document.addEventListener("click", outsideClickListener);
}

export default class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_tab_opened: false,
      is_notif_active: false,
      is_profile_active: false,
    };
  }

  componentDidMount = () => {
    UI.set_context(this.props._this);
  };

  toggle_tab = () => {
    this.setState(
      {
        is_tab_opened: !this.state.is_tab_opened,
      },
      () => {
        if (this.state.is_tab_opened) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      }
    );
  };

  notif_clicked = () => {
    if (this.state.is_notif_active) {
      this.is_clicked_outside();
    } else {
      this.setState({
        is_notif_active: !this.state.is_notif_active,
      });
    }
  };

  is_clicked_outside = () => {
    is_outside_clicked("notif_container", () => {
      this.setState({
        is_notif_active: !this.state.is_notif_active,
      });
    });
  };

  profile_clicked = () => {
    if (this.state.is_profile_active) {
      this.is_clicked_outsides();
    } else {
      this.setState({
        is_profile_active: !this.state.is_profile_active,
      });
    }
  };

  is_clicked_outsides = () => {
    is_outside_clicked("profile_container", () => {
      this.setState({
        is_profile_active: !this.state.is_profile_active,
      });
    });
  };

  

  render() {
    const _this = this.props._this;
    const { width, isMobile } = _this.state;
    const w = width;
    let active_tab = this.props.active_tab;
    let message_active_tab = this.props.message_active_tab;
    
    let tab_style = { position: "relative", display: "none" };
    if (this.state.is_tab_opened) {
      tab_style = { position: "absolute", top: 0, left: 0 };
    }

    let tab_styles = { position: "relative", display: "none" };
    if (this.state.is_notif_active) {
      tab_styles = { position: "absolute", top: 0, left: 0 };
    }
    if (this.state.is_notif_active) {
      active_tab = "";
      message_active_tab = "";
    }
    let tab_styles_prof = { position: "relative", display: "none" };
    if (this.state.is_profile_active) {
      tab_styles_prof = { position: "absolute", top: 0, left: 0 };
    }
    if (this.state.is_profile_active) {
      active_tab = "";
      message_active_tab = "";
    }
   

    if (w < 1112 ) {
      //codes for mobile
      return (
        <View
          style={{ width: "100%", height: "100%", backgroundColor: "#F9FAFF" }}
        >
          <ScrollView
            onScroll={(scroll) => {
              _this.setState(
                {
                  current_scroll: scroll,
                },
                () => {
                  if (_this.execute_scrolls) {
                    _this.execute_scrolls(_this);
                  }
                }
              );
            }}
            style={{ backgroundColor: "#F9FAFF" }}
          >
            <View style={{ flexDirection: "row" }}>
              <View
                style={{
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <img
                  style={{
                    height: 125,
                    width: w < 1015 ? 350 : 250,
                  }}
                  src={UI.LOGIN_BANNER}
                />
              </View>

              <View
                style={{
                  width: "100%",
                  height: 80,
                  flexDirection: "row",
                  padding: 25,
                  justifyContent: "right",
                  alignItems: "right",
                }}
              >
                <TouchableOpacity
                  onClick={this.toggle_tab}
                   style={{ paddingTop:10 }}
                >
                  <img style={{ height: 50, width: 50 } }src={UI.MENU_BARS} />
                </TouchableOpacity>
              </View>
            </View>

            <View style={styles.break_line}> - </View>
            <View>{this.props.children}</View>
            <View style={styles.footer_line}>
              <Text style={styles.footer_txt}> 2021 All Rights Reserved </Text>
            </View>
            <View
              style={{
                backgroundColor: "rgba(0,0,0,0.50)",
                width: "100%",
                height: "100%",
                ...tab_style,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "50%",
                  backgroundColor: "#F9FAFF",
                  height: "100%",
                }}
              >
                <View style={{}}>
                  <View style={{ alignItems: "center", flexDirection: "row" }}>
                    <img style={styles.login_banner} src={UI.LOGIN_BANNER} />
                  </View>
                </View>

                <View style={{ paddingLeft: "10%", paddingTop: "8%" }}>
                  <TouchableOpacity
                    style={{ paddingRight: 25 }}
                    onClick={() => {
                      UI.goTo("/admin/dashboard");
                    }}
                  >
                    <Text
                      style={{
                        ...styles.header_txt,
                        color: active_tab == "Home" ? "#F14336" : "#575757",
                      }}
                    >
                      Home
                    </Text>
                    <View
                      style={{
                        height: 3,
                        backgroundColor: "#F14336",
                        width: "100%",
                        alignSelf: "center",
                        opacity: active_tab == "Home" ? 1 : 0,
                      }}
                    ></View>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{ paddingRight: 25 }}
                    onClick={() => {
                      UI.goTo("/admin/calendar");
                    }}
                  >
                    <Text
                      style={{
                        ...styles.header_txt,
                        color: active_tab == "Calendar" ? "#F14336" : "#575757",
                      }}
                    >
                      Calendar
                    </Text>
                    <View
                      style={{
                        height: 3,
                        backgroundColor: "#F14336",
                        width: "100%",
                        alignSelf: "center",
                        opacity: active_tab == "Calendar" ? 1 : 0,
                      }}
                    ></View>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{ paddingRight: 25 }}
                    onClick={() => {
                      UI.goTo("/admin/booking");
                    }}
                  >
                    <Text
                      style={{
                        ...styles.header_txt,
                        color: active_tab == "Booking" ? "#F14336" : "#575757",
                      }}
                    >
                      Booking
                    </Text>
                    <View
                      style={{
                        height: 3,
                        backgroundColor: "#F14336",
                        width: "100%",
                        alignSelf: "center",
                        opacity: active_tab == "Booking" ? 1 : 0,
                      }}
                    ></View>
                  </TouchableOpacity>

                  <TouchableOpacity style={{ paddingBottom: 10 }}>
                    <Text style={styles.header_txt}> Notification </Text>
                  </TouchableOpacity>
                </View>
                {UI.box(200)}
                <View style={{ paddingLeft: 40, flexDirection: w < 435 ? "column" : "row" }}>
                  <TouchableOpacity style={{ paddingRight: 20 }}>
                    <img style={styles.profile_img} src={UI.JOSHUA} />
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{ flexDirection: "row", paddingTop: 20 }}
                    onClick={() => {
                      UI.goTo("/admin/sign_in");
                    }}
                  >
                    <View style={{ flexDirection: "row", paddingRight: 10 }}>
                      <img style={{ height: 25 }} src={UI.MAIN_LINE_3} />
                      <View style={{ paddingTop: 2 }}>
                        <img
                          style={{ height: 20, width: 20 }}
                          src={UI.MAIN_ARROW_RIGHT}
                        />
                      </View>
                    </View>

                    <Text style={styles.signout_txt}> Sign Out</Text>
                  </TouchableOpacity>
                </View>
              </View>

              <View onClick={this.toggle_tab} style={{ flex: 1 }}></View>
            </View>
          </ScrollView>
          <ZLayout _this={_this} />
        </View>
      );
    } else {
      //codes for web

      return (
        <View style={styles.main_container}>
          {/* HEADER */}
          <ScrollView
            onScroll={(scroll) => {
              _this.setState(
                {
                  current_scroll: scroll,
                },
                () => {
                  if (_this.execute_scrolls) {
                    _this.execute_scrolls(_this);
                  }
                }
              );
            }}
            style={{ backgroundColor: "#F9FAFF" }}
          >
            <View style={{ backgroundColor: "#F9FAFF" }}>
              <View style={styles.header_container}>
                <View style={{ paddingLeft: 60 }}>
                  <img style={styles.login_banner} src={UI.LOGIN_BANNER} />
                </View>

                <View style={{ flexDirection: "row", alignItems: "right" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      paddingTop: 40,
                      paddingRight: 20,
                    }}
                  >
                    <TouchableOpacity
                      style={{ paddingRight: 20 }}
                      onClick={() => {
                        UI.goTo("/admin/dashboard");
                      }}
                    >
                      <Text
                        style={{
                          ...styles.header_txt,
                          color: active_tab == "Home" ? "#F14336" : "#575757",
                        }}
                      >
                        Home
                      </Text>
                      <View
                        style={{
                          height: 3,
                          backgroundColor: "#F14336",
                          width: 40,
                          alignSelf: "center",
                          opacity: active_tab == "Home" ? 1 : 0,
                        }}
                      ></View>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ paddingRight: 20 }}
                      onClick={() => {
                        UI.goTo("/admin/calendar");
                      }}
                    >
                      <Text
                        style={{
                          ...styles.header_txt,
                          color:
                            active_tab == "Calendar" ? "#F14336" : "#575757",
                        }}
                      >
                        Calendar
                      </Text>
                      <View
                        style={{
                          height: 3,
                          backgroundColor: "#F14336",
                          width: 40,
                          alignSelf: "center",
                          opacity: active_tab == "Calendar" ? 1 : 0,
                        }}
                      ></View>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ paddingRight: 20 }}
                      onClick={() => {
                        UI.goTo("/admin/schedule");
                      }}
                    >
                      <Text
                        style={{
                          ...styles.header_txt,
                          color:
                            active_tab == "My Schedule" ? "#F14336" : "#575757",
                        }}
                      >
                        My Schedule
                      </Text>
                      <View
                        style={{
                          height: 3,
                          backgroundColor: "#F14336",
                          width: 40,
                          alignSelf: "center",
                          opacity: active_tab == "My Schedule" ? 1 : 0,
                        }}
                      ></View>
                    
                     
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ paddingRight: 20 }}
                      onClick={() => {
                        UI.goTo("/admin/booking");
                      }}
                    >
                     
                      <Text
                        style={{
                          ...styles.header_txt,
                          color:
                            active_tab == "Booking" ? "#F14336" : "#575757",
                        }}
                      >
                        Booking
                      </Text>
                      <View
                        style={{
                          height: 3,
                          backgroundColor: "#F14336",
                          width: 40,
                          alignSelf: "center",
                          opacity: active_tab == "Booking" ? 1 : 0,
                        }}
                      ></View>
                      
                    </TouchableOpacity>

                    <TouchableOpacity
                      onClick={() => {
                        UI.goTo("/admin/messages");
                      }}
                      style={{ paddingRight: "15px", paddingTop: "7px"}}
                    >
                      <img
                        style={{ height: 30, width: 30 }}
                        src={message_active_tab ? UI.ON_CLICKED_MESSAGES: UI.MESSAGES}
                      />
                    </TouchableOpacity>

                    <TouchableOpacity onClick={this.notif_clicked} style={{ paddingTop: "7px"}}>

                    <img style={{ height: 30, width: 30 }} src={ this.state.is_notif_active ? UI.ON_CLICKED_BELL: UI.BELL}  />

                    
                      {/* <Text
                        style={{
                          ...styles.header_txt,
                          color: this.state.is_notif_active
                            ? "#F14336"
                            : "#575757",
                        }}
                      >
                        Notification
                      </Text> */}
                      
                      {/* <View
                        style={{
                          height: 3,
                          backgroundColor: "#F14336",
                          width: 40,
                          alignSelf: "center",
                          opacity: this.state.is_notif_active ? 1 : 0,
                        }}
                      ></View> */}

                    </TouchableOpacity>
                    
                    <View
                      onClick={this.notif_clicked}
                      style={{
                        backgroundColor: "rgba(0,0,0,0.50)",
                        width: "100%",
                        height: "100%",
                        ...tab_styles,
                        flexDirection: "row",
                      }}
                    > 

                      <View
                        id={"notif_container"}
                        style={{
                          position: "absolute",
                          top: 90,
                          width: 500,
                          right: 100,
                        }}
                      >
                         <View
                          style={{
                            width: "60px",
                            height: "30px",
                            marginLeft: "66%",
                          }}
                        >
                          <ImageBackground
                            source={UI.Upward}
                            imageFit={ImageFit.COVER}
                            style={{ height: "50px", marginTop:"8px"}}
                          />
                        </View>
                        
                        <View
                            style={{
                              width: "100%",
                              padding:15,
                              backgroundColor: "#F26E50",
                              textAlign: "center",
                              justifyContent: "center",
                              borderRadius: "5px 5px 0px 0px" 
                            }}
                          >
                            <Text
                              style={{ color: "#FFFFFF", fontSize: "25px", }}
                            >
                              Notifications
                            </Text>
                          </View>


                      <View style={{  backgroundColor: "white",  borderRadius: "0px 0px 5px 5px" }}>
                        <View
                          style={{
                            ...Shadow._4(),
                            marginLeft: 20,
                            marginRight: 20,
                            marginTop: 30,
                            flexDirection: "row",
                          }}
                        >
                          <View style={{ padding: 15 }}>
                            <img
                              style={{
                                height: 80,
                                width: 100,
                                borderRadius: "50%",
                                marginTop: 10,
                              }}
                              src={UI.ENRICO}
                            />
                          </View>
                          <View>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Text
                                style={{
                                  color: "#575757",
                                  fontSize: 25,
                                  fontWeight: "bold",
                                  padding: 10,
                                }}
                              >
                                {" "}
                                Enrico Juliano
                              </Text>
                              <Text
                                style={{
                                  color: "#575757",
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  padding: 10,
                                  marginTop: 5,
                                  marginRight: 10,
                                }}
                              >
                                {" "}
                                9 mins
                              </Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  color: "#575757",
                                  fontSize: 16,
                                  padding: 10,
                                }}
                              >
                                You have an upcoming Consultation schedule with
                                Mr. Enrico in 15 mins.
                              </Text>
                            </View>
                          </View>
                        </View>
                        {UI.box(30)}
                        <View
                          style={{
                            ...Shadow._4(),
                            marginLeft: 20,
                            marginRight: 20,
                            flexDirection: "row",
                          }}
                        >
                          <View style={{ padding: 15 }}>
                            <img
                              style={{
                                height: 80,
                                width: 100,
                                borderRadius: "50%",
                                marginTop: 10,
                              }}
                              src={UI.ENRICO}
                            />
                          </View>
                          <View>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Text
                                style={{
                                  color: "#575757",
                                  fontSize: 25,
                                  fontWeight: "bold",
                                  padding: 10,
                                }}
                              >
                                {" "}
                                Enrico Juliano
                              </Text>
                              <Text
                                style={{
                                  color: "#575757",
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  padding: 10,
                                  marginTop: 5,
                                  marginRight: 10,
                                }}
                              >
                                {" "}
                                9 mins
                              </Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  color: "#575757",
                                  fontSize: 16,
                                  padding: 10,
                                }}
                              >
                                Your consultation with Mr. Enrico is scheduled
                                on March 10, 2021 at 10:30 am
                              </Text>
                            </View>
                          </View>   
                        </View>
                        {UI.box(50)}
                        <TouchableOpacity onClick={() => {
                        UI.goTo("/admin/view-all");
                      }} style={{paddingBottom:"20px"}}>
                          <Text
                            style={{
                              color: "#F26E50",
                              fontSize: 20,
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            See all Notifications
                          </Text>
                        </TouchableOpacity>
                      
                        </View> 
                      </View>
                    </View>
                  </View>


                  <View style={styles.profile_container}>
                
                      
                    


                    <TouchableOpacity onClick={this.profile_clicked}>
                    <img style={styles.profile_img} src={UI.JOSHUA} />
                     
                    </TouchableOpacity>
                    <View
                      onClick={this.profile_clicked}
                      style={{
                        backgroundColor: "rgba(0,0,0,0.50)",
                        width: "100%",
                        height: "100%",
                        ...tab_styles_prof,
                        flexDirection: "row",
                      }}
                    >
                      <View
                        id={"profile_container"}
                        style={{
                          position: "absolute",
                          top: 90,
                          width: 250,
                          height: 120,
                          right: 70,
                          borderRadius:"5px 5px 0px 0px"
                        }}
                      >
                           <View
                          style={{
                            width: "50px",
                            height: "30px",
                            marginLeft: "47%",
                          }}
                        >
                          <ImageBackground
                            source={UI.Upward}
                            imageFit={ImageFit.COVER}
                            style={{ height: "50px", marginTop:"11px",}}
                          />
                        </View>

                        <View style={{backgroundColor: "#F26E50", borderRadius: "5px 5px 0px 0px"  }}>
                          <Text style={{textAlign:"center", color: "#FFFFFF",  fontSize: "25px",padding:5,
                       }}>My Profile</Text>
                        </View>
                        <TouchableOpacity onClick={() => {
                            UI.goTo("/admin/edit-profile");
                             }} 
                        style={{borderRadius:"0px 0px 5px 5px", backgroundColor: "white"}}>
                          <Text style={{textAlign:"center", fontSize: "30px", padding:10}}>Edit Profile</Text>
                        </TouchableOpacity>
                        

                        {/* <View
                          style={{
                            ...Shadow._4(),
                            marginLeft: 20,
                            marginRight: 20,
                            marginTop: 50,
                            flexDirection: "row",
                          }}
                        >
                          
                          </View> */}
                      </View>
                    </View>
                  </View>
                  

                  <TouchableOpacity
                    style={styles.signout_btn}
                    onClick={UI.logout}
                  >
                    <View style={{ flexDirection: "row", paddingRight: 10 }}>
                      <img style={{ height: 25 }} src={UI.MAIN_LINE_3} />
                      <View style={{ paddingTop: 2 }}>
                        <img
                          style={{ height: 20, width: 20 }}
                          src={UI.MAIN_ARROW_RIGHT}
                        />
                      </View>
                    </View>
                    <Text style={styles.signout_txt}> Sign Out</Text>
                  </TouchableOpacity>
                </View>
              </View>

              <View style={styles.break_line}></View>

              <View style = {{}}>
                {this.props.children}
              </View>
            </View>

            <View style={styles.footer_line}>
              <Text style={styles.footer_txt}> 2021 All Rights Reserved </Text>
            </View>
          </ScrollView>
          <ZLayout _this={_this} />
        </View>
      );
    }
  }
}
const styles = StyleSheet.create({
  login_banner: {
    height: 150 * 0.8,
    width: 300 * 0.8,
  },

  break_line: {
    height: 27,
    width: "100%",
    backgroundColor: "#0F4059",
  },

  navbar_text: {
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
  },
  header_container: {
    width: "100%",
    backgroundColor: "#F9FAFF",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  header_txt: {
    fontSize: "25px",
    fontWeight: "bold",
    color: "#575757",
  },
  main_container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F9FAFF",
  },
  profile_container: {
    flexDirection: "row",
    paddingTop: 30,
    paddingRight: 20,
  },
  profile_img: {
    height: 60,
    width: 60,
    borderRadius: 50,
  },
  signout_btn: {
    flexDirection: "row",
    paddingTop: 50,
    paddingRight: 30,
  },
  signout_txt: {
    FontFace: "Quicksand",
    color: "#F26E50",
    fontWeight: "bold",
  },
  footer_line: {
    height: 45,
    width: "100%",
    backgroundColor: "#171717",
  },
  footer_txt: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 5,
    paddingBottom: 5,
  },
});

import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  r,
} from "../../shared/custom-react-native";

import UI from "../../shared/Components/UI/js";
import UserMainLayout from "../_components/User-MainLayout";

export default class LawyerInfo extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
        //Initial State Here
        data: {
          id: 0,
          email: "",
          emailVerified: null,
          profile_image: "",
          first_name: "",
          last_name: "",
          address: "",
          phone: "",
          user_type: "",
          school_graduated: "",
          graduated_at: "",
          years_of_experience: "",
          specialization_id: 0,
          proposed_fee: "",
          profile_description: "",
          metadata: null,
          created_at: "",
        },
      },
      {
        role: "client",
      }
    );
  }

  onCreate = async () => {
    const id = UI.path("id");
    this.show_loading();
    r.request({
      method: "get",
      url: "/client/lawyers/" + id,
      params: {},
      onFail: (e) => {},
      onSuccess: (e) => {
        if (e) {
          this.setState({
            data: e,
          });
        } else {
          UI.goTo("/lawyers");
        }
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const { width, data } = this.state;
    const w = width;
    const x = w < 400 ? 26 : 15;
    const item = data;
    return (
      <UserMainLayout _this={this}>
        <View style={{ width: "100%", backgroundColor: "#F9FAFF" }}>
          {UI.box(20)}

          <View style={styles.content_container}>
            <View style={styles.content_subcontainer}>
              <View style={{ flexDirection: w < 1080 ? "column" : "row" }}>
                <View style={{ paddingRight: "20px" }}>
                  <Text style={styles.lawyer_info_title}>Lawyer Info</Text>
                </View>

                <View
                  style={{
                    flexDirection: w < 1080 ? "column" : "row",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <View>
                    <Text style={styles.lawyer_info_subtitle}>Home /</Text>
                  </View>

                  <View
                    onClick={() => {
                      // UI.goTo("/Lawyer_List");
                    }}
                  >
                    <Text style={styles.lawyer_info_subtitle}>
                      Lawyer List /
                    </Text>
                  </View>

                  <View
                    onClick={() => {
                      // UI.goTo("/Lawyer_Info");
                    }}
                  >
                    <Text style={styles.lawyer_info_subtitle}>Lawyer Info</Text>
                  </View>
                </View>
              </View>

              {UI.box(30)}

              <View style={{ flexDirection: "row" }}>
                <View
                  style={{
                    ...styles.body_container,
                    paddingLeft: w < 400 ? "45px" : "55px",
                  }}
                >
                  <View
                    style={{
                      flexDirection: w < 1080 ? "column" : "row",
                      alignItems: w < 1080 ? "center" : "none",
                      width: "auto",
                    }}
                  >
                    <View style={{ marginRight: "60px", marginTop: "10px" }}>
                      <img
                        style={{ height: 200, width: 200, borderRadius: 100 }}
                        src={UI.download_link(item.profile_image)}
                      />
                    </View>

                    <View
                      style={{
                        ...styles.Line_Style,    
                        width: w < 1080 ? "100%" : "0px",    
                        marginLeft: w < 1080 ? "50px" : "0px",
                      }}
                    />

                    <View style={{ marginRight: "50px", marginTop: "30px" }}>
                      <View style={{ paddingTop: "10px" }}>
                        <Text style={styles.profile_Textfirst}>
                          {" "}
                          {item.first_name} {item.last_name}{" "}
                        </Text>
                        <Text style={styles.profile_Textsecond}>
                          {UI.specialization_map[item.specialization_id]}
                        </Text>
                        <Text style={styles.profile_Textsecond}>
                          {item.years_of_experience} Years of Experience | Class
                          of {UI.date_iso_to_year(item.graduated_at)}{" "}
                        </Text>
                      </View>

                      <View style={{ flexDirection: "row", paddingTop: "3%" }}>
                        <View style={{ paddingRight: 10 }}>
                          <img style={styles.school} src={UI.SCHOOL} />
                        </View>
                        <View style={{}}>
                          <Text style={styles.profile_ContactText}>
                            {item.school_graduated}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          paddingTop: "3%",
                          marginBottom: "10px",
                        }}
                      >
                        <View style={{ paddingRight: 10 }}>
                          <img
                            style={{ height: 20, width: x }}
                            src={UI.Map_Marker}
                          />
                        </View>
                        <View style={{}}>
                          <Text style={styles.profile_ContactText}>
                            {" "}
                            {item.address}{" "}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        ...styles.Line_Style,    
                        width: w < 1080 ? "100%" : "0px",    
                        marginLeft: w < 1080 ? "50px" : "0px",
                      }}
                    />

                    <View
                      style={{
                        paddingTop: "10px",
                        paddingRight: "50px",
                        marginTop: "7px",
                      }}
                    >
                      <View>
                        <Text
                          style={styles.Money_text}>
                          {" "}
                          ₱{item.proposed_fee}
                        </Text>
                        <Text
                         style={styles.Session_text}>
                          {" "}
                          Session
                        </Text>
                        <Text
                          styles={styles.LawyerFee_text}>
                          Lawyers Fee
                        </Text>
                      </View>

                      <TouchableOpacity onClick={() => {
                      UI.goTo("/chat");
                    }} 
                       style={styles.Message_Button}>
                        <Text style={styles.Button_Text}>Messages</Text>
                      </TouchableOpacity>
 
                      <TouchableOpacity onClick = {()=>{
                        UI.goTo("/booking/" + item.id);
                      }} style={styles.BookAppoinment_button}>
                        <Text style={styles.Button_Text}>Book Appointment</Text>
                      </TouchableOpacity>

                      <TouchableOpacity  onClick={() => {
                      UI.goTo("/dashboard");
                    }}  
                      style={styles.Back_Button}>
                        <Text style={styles.Button_Text}>Go Back</Text>
                      </TouchableOpacity>
 
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {UI.box(100)}
          </View>

          {UI.box(20)}
        </View>
      </UserMainLayout>
    );
  }
}

const styles = StyleSheet.create({
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  },

  content_subcontainer: {
    width: "auto", /// width for outside
    // backgroundColor: "white",
    borderRadius: 5,
    padding: 30, /// width for inside
    paddingTop: 35,
    justifyContent: "center",
    opacity: 1,
  },
  body_container:{
    width: "100%",
    borderRadius: "10px",
    ...Shadow._3(),
    paddingTop: "55px",
    paddingRight: "50px",
    paddingBottom: "60px",
  },
  

  lawyer_info_subtitle: {
    fontSize: "17px",
    color: "#BABBAB",
    fontStyle: "Quicksand",
  },
  lawyer_info_title: {
    fontStyle: "Quicksand",
    color: "#575757",
    fontWeight: "bold",
    fontSize: "35px",
  },

  profile_Textfirst: {
    color: "#575757",
    fontStyle: "Quicksand",
    fontSize: "30px",
  },

  profile_Textsecond: {
    color: "#BABBAB",
    fontStyle: "Quicksand",
    fontSize: "15px",
  },
  school: {
    height: 20,
    width: 20,
  },
  profile_ContactText: {
    color: "#0F4059",
    fontStyle: "Quicksand",
    fontSize: 14,
  },
  // map_marker:{
  //   height:20,
  //    width:15,
  // },
  Line_Style:{
    height: "70%",
    backgroundColor: "#575757",
    padding: 1,
    marginTop: "3%",
    marginRight: "50px",
  },
  Money_text:{
    color: "#F26E50",
    fontStyle: "Quicksand",
    fontSize: "25px",
    fontWeight: "bold",
  },
  Session_text:{
    color: "#575757",
    fontStyle: "Quicksand",
    fontSize: "25px",
    fontWeight: "bold",
  },
  LawyerFee_text:{
    color: "#0F4059",
    fontStyle: "Quicksand",
    fontSize: 14,
  },
  
  Message_Button: {
    width: 190,
    alignItems: "center",
    backgroundColor: "#F26E50",
    height: "50px",
    paddingTop: "15px",
    marginTop: "10px",
    borderRadius:"5px"
  },

  Back_Button: {
    width: 190,
    alignItems: "center",
    backgroundColor: "#F14336",
    height: "50px",
    paddingTop: "15px",
    marginTop: "10px",
    borderRadius:"5px"
  },

  BookAppoinment_button: {
    width: 190,
    alignItems: "center",
    backgroundColor: "#0F4059",
    height: "50px",
    paddingTop: "15px",
    marginTop: "10px",
    borderRadius:"5px"
  },

  Button_Text: {
    color: "#FFFFFF",
    fontSize: 14,
    fontWeight: "bold",
  },

  // main_container: {
  //   width: "100%",
  //   backgroundColor: "#F9FAFF",

  // },
});

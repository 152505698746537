import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../shared/custom-react-native";

import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";

export default class AnotherPage extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here

      isOnChat: false,
    });
  }

  render() {
    const { width, isMobile } = this.state;
    const w = width;

    return (
       <MainLayout _this = {this}>
        
        <Text>This is the another page</Text>

        <TouchableOpacity onClick = {()=>{
          UI.goBack();
        }}>
          Go Back
        </TouchableOpacity>


      </MainLayout>
    );
  }
}

/*

For Chatting

 render() {
    const { width, isMobile } = this.state;
    const w = width;


    if (isMobile) {
      //codes for mobile


      if (this.state.isOnChat) {
        return (
          <View>
            <Text>This is the inside of chat</Text>
            <TouchableOpacity onClick = {()=>{
              this.setState({
                isOnChat: false
              })
            }} ><Text>Click me to go back</Text></TouchableOpacity>
          </View>
        );
      } else {

        return (
          <View>
            <Text>This is the list of chats</Text>
            <TouchableOpacity onClick = {()=>{
              this.setState({
                isOnChat: true
              })
            }} style = {{backgroundColor: "#d3d3d3", borderRadius: 5, padding: 10}}>
              <Text>Press a chat</Text>        
            </TouchableOpacity>
          </View>
        );
      }


    } else {
      //codes for web

      return (
        <View>
          <Text>This is the web view</Text>
        </View>
      );
    }
  }

  */

import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
} from "../../shared/custom-react-native";
import "./css.css";
import UI from "../../shared/Components/UI/js";
import ZLayout from "./ZLayout";
const isVisible = (elem) =>
  !!elem &&
  !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

function is_outside_clicked(element_id, callback) {
  let element = document.getElementById(element_id);
  const outsideClickListener = (event) => {
    if (!element.contains(event.target) && isVisible(element)) {
      // or use: event.target.closest(selector) === null
      callback();
      removeClickListener();
    }
  };

  const removeClickListener = () => {
    document.removeEventListener("click", outsideClickListener);
  };

  document.addEventListener("click", outsideClickListener);
}

export default class UserMainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_tab_opened: false,
      is_notif_active: false,
    
    };
  }
  componentDidMount = () => {
    UI.set_context(this.props._this);
  };

  toggle_tab = () => {
    this.setState(
      {
        is_tab_opened: !this.state.is_tab_opened,
      },
      () => {
        if (this.state.is_tab_opened) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      }
    );
  };

  notif_clicked = () => {
    if (this.state.is_notif_active) {
      this.is_clicked_outside();
    } else {
      this.setState({
        is_notif_active: !this.state.is_notif_active,
      });
    }
  };
  
  is_clicked_outside = () => {
    is_outside_clicked("notif_container", () => {
      this.setState({
        is_notif_active: !this.state.is_notif_active,
      });
    });
  };


  render() {
    const _this = this.props._this;
    const { width, isMobile } = _this.state;
    const w = width;
    let active_tab = this.props.active_tab;
    let message_active_tab = this.props.message_active_tab;
   

    let tab_style = { position: "relative", display: "none" };
    if (this.state.is_tab_opened) {
      tab_style = { position: "absolute", top: 0, left: 0 };
    }
 
    let tabSecond_styles = { position: "relative", display: "none" };
    if (this.state.is_notif_active) {
      tabSecond_styles = { position: "absolute", top: 0, left: 0 };  
    }
    if (this.state.is_notif_active) {
      active_tab = "";
      message_active_tab = "";
    }

  
    
    
   

    if (w < 1012) {
      return (
        <View style={{ width: "100%", height: "100%" }}>
          <ScrollView
            onScroll={(scroll) => {
              _this.setState(
                {
                  current_scroll: scroll,
                },
                () => {
                  if (_this.execute_scrolls) {
                    _this.execute_scrolls(_this);
                  }
                }
              );
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                backgroundColor: "#F9FAFF",
              }}
            >
              <View style={{ paddingLeft: w < 400 ? 0 : 20 }}>
                <img
                  style={{ height: 150, width: 300 }}
                  src={UI.LOGIN_BANNER}
                />
              </View>

              <View style={{ height: 80, paddingTop: 45, paddingRight: 40 }}>
                <TouchableOpacity onClick={this.toggle_tab}>
                  <img style={{ height: 50, width: 50 }} src={UI.MENU_BARS} />
                </TouchableOpacity>
              </View>
            </View>

            <View style={styles.border}>
              <TouchableOpacity style={{ flexDirection: "row" }}>
                <View style={{ height: 30 }}>
                  {/* <img style={{ height: 20, width: 20 }} src={UI.Search} /> */}
                </View>
                {/* <Text style={styles.search_text}>Search</Text> */}
              </TouchableOpacity>
            </View>

            <View>{this.props.children}</View>

            <View style={styles.footer_container}>
              <Text style={styles.footer_text}>2021 All Rights Reserved</Text>
            </View>

            <View
              style={{
                backgroundColor: "rgba(0,0,0,0.50)",
                width: "100%",
                height: "100%",
                ...tab_style,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "80%",
                  backgroundColor: "#F9FAFF",
                  height: "100%",
                }}
              >
                <View style={{}}>
                  <View style={{ paddingLeft: 20 }}>
                    <img style={styles.login_banner} src={UI.LOGIN_BANNER} />
                  </View>
                </View>

                <View style={{ paddingLeft: "10%", paddingTop: "8%" }}>
                  <TouchableOpacity
                    style={{ paddingBottom: 10 }}
                    onClick={() => {
                      UI.goTo("/dashboard");
                    }}
                  >
                    <Text
                      style={{
                        ...styles.navbar_text,
                        color: active_tab == "Home" ? "#F14336" : "#575757",
                      }}
                    >
                      {" "}
                      HOME{" "}
                    </Text>
                    <View
                      style={{
                        height: 3,
                        backgroundColor: "#F14336",
                        width: "90%",
                        alignSelf: "left",
                        marginTop: 5,
                        opacity: active_tab == "Home" ? 1 : 0,
                      }}
                    />
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{ paddingBottom: 10 }}
                    onClick={() => {
                      UI.goTo("/consultations");
                    }}
                  >
                    <Text
                      style={{
                        ...styles.navbar_text,
                        color:
                          active_tab == "Consultation" ? "#F14336" : "#575757",
                      }}
                    >
                      {" "}
                      Consultation{" "}
                    </Text>
                    <View
                      style={{
                        height: 3,
                        backgroundColor: "#F14336",
                        width: "90%",
                        alignSelf: "left",
                        marginTop: 5,
                        opacity: active_tab == "Consultation" ? 1 : 0,
                      }}
                    />
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{ paddingBottom: 10 }}
                    onClick={() => {
                      UI.goTo("/booking");
                    }}
                  >
                    <Text
                      style={{
                        ...styles.navbar_text,
                        color: active_tab == "Booking" ? "#F14336" : "#575757",
                      }}
                    >
                      {" "}
                      Booking{" "}
                    </Text>
                    <View
                      style={{
                        height: 3,
                        backgroundColor: "#F14336",
                        width: "90%",
                        alignSelf: "left",
                        marginTop: 5,
                        opacity: active_tab == "Booking" ? 1 : 0,
                      }}
                    />
                  </TouchableOpacity>

                  <TouchableOpacity style={{ paddingBottom: 10 }}>
                    <Text
                      style={{
                        ...styles.navbar_text,
                        color: active_tab == "" ? "#F14336" : "#575757",
                      }}
                    >
                      {" "}
                      Notification{" "}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

              <View onClick={this.toggle_tab} style={{ flex: 1 }} />
            </View>
          </ScrollView>
          <ZLayout _this={_this} />
        </View>
      );
    } else {
      return (
        <View
          style={{ width: "100%", backgroundColor: "#F9FAFF", height: "100%" }}
        >
          <ScrollView
            onScroll={(scroll) => {
              _this.setState(
                {
                  current_scroll: scroll,
                },
                () => {
                  if (_this.execute_scrolls) {
                    _this.execute_scrolls(_this);
                  }
                }
              );
            }}
          >
            <View>
              <UI.Row
                _this={this}
                breakpoint={1030}
                style={styles.header_container}
              >
                <View style={{ paddingLeft: 15 }}>
                  <img style={styles.login_banner} src={UI.LOGIN_BANNER} />
                </View>

                <View style={{ flex: 1 }}></View>

                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      paddingRight: 55,
                    }}
                  >
                    <TouchableOpacity
                      style={{ paddingRight: 25 }}
                      onClick={() => {
                        UI.goTo("/dashboard");
                      }}
                    >
                      <Text
                        style={{
                          ...styles.navbar_text,
                          color: active_tab == "Home" ? "#F14336" : "#575757",
                        }}
                      >
                        Home
                      </Text>
                      <View
                        style={{
                          height: 3,
                          backgroundColor: "#F14336",
                          width: "40%",
                          alignSelf: "center",
                          marginTop: 5,
                          opacity: active_tab == "Home" ? 1 : 0,
                        }}
                      />
                    </TouchableOpacity>

                    {/* <TouchableOpacity
                      style={{ paddingRight: 25 }}
                      onClick={() => {
                        UI.goTo("/consultations");
                      }}
                    >
                      <Text
                        style={{
                          ...styles.navbar_text,
                          color:
                            active_tab == "Consultation"
                              ? "#F14336"
                              : "#575757",
                        }}
                      >
                        Consultation
                      </Text>
                      <View
                        style={{
                          height: 3,
                          backgroundColor: "#F14336",
                          width: "20%",
                          alignSelf: "center",
                          marginTop: 5,
                          opacity: active_tab == "Consultation" ? 1 : 0,
                        }}
                      />
                    </TouchableOpacity> */}

                    <TouchableOpacity
                      onClick={() => {
                        UI.goTo("/booking");
                      }}
                    >
                      <Text
                        style={{
                          ...styles.navbar_text,
                          color:
                            active_tab == "Booking" ? "#F14336" : "#575757",
                        }}
                      >
                        Booking
                      </Text>

                      <View
                        style={{
                          height: 3,
                          backgroundColor: "#F14336",
                          width: "30%",
                          alignSelf: "center",
                          marginTop: 5,
                          opacity: active_tab == "Booking" ? 1 : 0,
                        }}
                      />
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      paddingRight: 30,
                    }}
                  >
                    <TouchableOpacity onClick={() => { UI.goTo("/chat"); }}
                      style={{ paddingRight: 20, marginTop:10}}>
                      <img style={{ height: 30, width: 30 }} src={ message_active_tab ?  UI.ON_CLICKED_MESSAGES: UI.MESSAGES }/>
                    </TouchableOpacity>

                    <TouchableOpacity onClick={this.notif_clicked}
                     style={{ paddingRight: 20, marginTop:10}}>
                      <img style={{ height: 30, width: 30 }}  src={ this.state.is_notif_active ? UI.ON_CLICKED_BELL : UI.BELL}/>
                    </TouchableOpacity>

                  <TouchableOpacity onClick={() => { UI.goTo("/edit"); }}>
                    <img style={{ height: 52, width: 52, borderRadius: 50, }} src={UI.JOSHUA} />
                  </TouchableOpacity>

                    {/* //// */}
                    <View
                      onClick={this.notif_clicked}
                      style={{
                        backgroundColor: "rgba(0,0,0,0.50)",
                        width: "100%",
                        height: "110%",
                        ...tabSecond_styles,
                        flexDirection: "row",
                      }}
                    >
                      <View
                        id={"notif_container"}
                        style={{
                          position: "relative",
                          left: "54%",
                          top: 110,
                          width: 500,
                          height: "50%",
                        }}
                      >
                        <View
                          style={{
                            width: "50px",
                            height: "30px",
                            marginLeft: "65%",
                          }}
                        >
                          <ImageBackground
                            source={UI.Upward}
                            imageFit={ImageFit.COVER}
                            style={{ height: "50px", marginTop: "10px" }}
                          />
                        </View>

                        <View
                          style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                          }}
                        >
                          <View
                            style={{
                              width: "100%",
                              height: 50,
                              backgroundColor: "#F26E50",
                              textAlign: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              style={{ color: "#FFFFFF", fontSize: "25px" }}
                            >
                              Notification
                            </Text>
                          </View>

                          <View
                            style={{
                              ...Shadow._4(),
                              marginLeft: 20,
                              marginRight: 20,
                              marginTop: 25,
                              flexDirection: "row",
                            }}
                          >
                            <View style={{ padding: 15 }}>
                              <img
                                style={{
                                  height: 80,
                                  width: 100,
                                  borderRadius: "50%",
                                  marginTop: 10,
                                }}
                                src={UI.ENRICO}
                              />
                            </View>
                            <View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Text
                                  style={{
                                    color: "#575757",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    padding: 10,
                                  }}
                                >
                                  {" "}
                                  Enrico Juliano
                                </Text>
                                <Text
                                  style={{
                                    color: "#575757",
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    padding: 10,
                                    marginTop: 5,
                                    marginRight: 10,
                                  }}
                                >
                                  {" "}
                                  9 mins
                                </Text>
                              </View>
                              <View>
                                <Text
                                  style={{
                                    color: "#575757",
                                    fontSize: 16,
                                    padding: 10,
                                  }}
                                >
                                  You have an upcoming Consultation schedule
                                  with Mr. Enrico in 15 mins.
                                </Text>
                              </View>
                            </View>
                          </View>

                          <Card />

                          {UI.box(45)}
                          <TouchableOpacity onClick={() => {
                               UI.goTo("/view");
                              }} 
                          style={{ marginBottom: "10px" }}>
                            <Text  
                              style={{
                                color: "#F26E50",
                                fontSize: 20,
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              See all Notifications
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>

                  <TouchableOpacity
                    onClick={UI.logout}
                    style={{
                      flexDirection: "row",
                      paddingRight: 68,
                    }}
                  >
                    <View style={{ flexDirection: "row", paddingRight: 10 }}>
                      <img style={{ height: 25 }} src={UI.Line_3} />
                      <View style={{ paddingTop: 2 }}>
                        <img
                          style={{ height: 20, width: 20 }}
                          src={UI.ARROW_RIGHT}
                        />
                      </View>
                    </View>
                    <Text
                      style={{
                        FontFace: "Quicksand",
                        color: "#F26E50",
                        fontWeight: "bold",
                      }}
                    >
                      Sign Out
                    </Text>
                  </TouchableOpacity>
                </View>
              </UI.Row>

              <View style={styles.border}>
                {/* <TouchableOpacity style={{ flexDirection: "row" }}>
                  <View style={{ paddingTop: 12 }}>
                    <img style={{ height: 20, width: 20 }} src={UI.Search} />
                  </View>
                  <Text style={styles.search_text}>Search</Text>
                </TouchableOpacity> */}
              </View>
            </View>

            {this.props.children}

            <View style={styles.footer_container}>
              <Text style={styles.footer_text}>2021 All Rights Reserved</Text>
            </View>
          </ScrollView>

          <ZLayout _this={_this} />
        </View>
      );
    }
  }
}

const Card = (props = {}) => {
  return (
    <View
      style={{
        ...Shadow._4(),
        marginLeft: 20,
        marginRight: 20,
        marginTop: 30,
        flexDirection: "row",
      }}
    >
      <View style={{ padding: 15 }}>
        <img
          style={{
            height: 80,
            width: 100,
            borderRadius: "50%",
            marginTop: 10,
          }}
          src={UI.ENRICO}
        />
      </View>
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              color: "#575757",
              fontSize: 25,
              fontWeight: "bold",
              padding: 10,
            }}
          >
            {" "}
            Enrico Juliano
          </Text>
          <Text
            style={{
              color: "#575757",
              fontSize: 20,
              fontWeight: "bold",
              padding: 10,
              marginTop: 5,
              marginRight: 10,
            }}
          >
            {" "}
            9 mins
          </Text>
        </View>
        <View>
          <Text
            style={{
              color: "#575757",
              fontSize: 16,
              padding: 10,
            }}
          >
            You have an upcoming Consultation schedule with Mr. Enrico in 15
            mins.
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  },

  content_subcontainer: {
    width: "90%", /// width for outside
    // backgroundColor: "white",
    borderRadius: 5,
    padding: 20, /// width for inside
    paddingTop: 35,
    justifyContent: "center",
    opacity: 1,
  },

  footer_container: {
    width: "100%",
    backgroundColor: "#171717",
    alignItems: "center",
  },
  footer_text: {
    color: "#FFFFFF",
    padding: 10,
    fontStyle: "Quicksand",
    fontWeight: "bold",
    fontSize: "15px",
  },

  navbar_text: {
    FontStyle: "Quicksand",
    fontSize: "25px",
    fontWeight: "bold",
    color: "#575757",
  },

  search_text: {
    color: "#FFFFFF",
    padding: 10,
    fontStyle: "Quicksand",
    fontWeight: "bold",
    fontSize: "15px",
  },

  login_banner: {
    height: 150,
    width: 300,
  },
  border: {
    width: "100%",
    height: "40px",
    backgroundColor: "#0F4059",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  borderMini: {
    width: "100%",
    height: "20px",
    backgroundColor: "#F26E50",
  },

  header_container: {
    width: "100%",
    backgroundColor: "#F9FAFF",
    borderRadius: 5,
    alignItems: "center",
  },

  main_container: {
    width: "100%",
    backgroundColor: "#F9FAFF",
  },
});

/*

For Chatting


  */

// render() {
//   const { width, isMobile } = this.state;
//   const w = width;

//   return (
//      <MainLayout _this = {this}>

//       <View style = {{height: 500, width: "100%"}}>

//       </View>

//     </MainLayout>
//   );
// }

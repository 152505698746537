import React from "react";
import Images from "../Images/js";
import { View } from "../../custom-react-native";
import { AuthNavigator } from "../../Auth/AuthNavigator";
import mem from "../Memory/js";
import r from "../Request/js";

class ui extends Images {
  _this = null;

  set_context = (_this) => {
    this._this = _this;
  };

  search = async (url, parameters = {}) => {
    let add_to_link = await this.encodeParams(parameters);
    url = url + "?" + add_to_link;
    this.goTo(url);
  };

  encodeParams = (parameters = {}) => {
    return new Promise((resolve, reject) => {
      let new_query_string = "";
      let index = 0;
      let parameter_array = [];
      let param_keys = Object.keys(parameters);
      if (param_keys.length == 0) {
        resolve("");
      }
      param_keys.map((param_key) => {
        index++;
        let param_value = parameters[param_key];
        if (!param_value) {
          param_value = "";
        }
        parameter_array.push(param_key + "=" + param_value);
        if (index == param_keys.length) {
          new_query_string = parameter_array.join("&");
          resolve(new_query_string);
        }
      });
    });
  };

  goTo = (url) => {
    this._this.props.history.push(url);
  };

  goBack = () => {
    window.history.back();
  };
  /* 
  goTo = (url) => {
    window.location.href = url;
  };
 */
  getWidth = () => {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  };
  getHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );
  };

  colors = {
    primary: "#04CCFF",
    secondary: "#004F99",
    black: "#5D5D5D",
    yellow: "#FCC203",
  };
  box = (size) => {
    return <div style={{ height: size, width: size }}></div>;
  };

  PadView = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const width = _this.state.width;
    const paddingX = width * 0.05;

    return (
      <View
        style={{
          width: "100%",
          paddingRight: paddingX,
          paddingLeft: paddingX,
          ...props.style,
        }}
      >
        {props.children}
      </View>
    );
  };

  resize(_this) {
    const width = this.getWidth();
    if (width !== _this.state.width) {
      _this.setState({
        width: width,
        height: this.getHeight(),
      });
      this.checkIfMobile(_this);
    }
  }

  checkIfMobile = (_this) => {
    let width = _this.state.width;
    if (width < 720) {
      _this.setState({
        isMobile: true,
      });
    } else {
      _this.setState({
        isMobile: false,
      });
    }
  };

  measure = (id) => {
    let el = document.getElementById(id);
    if (el) {
      let bounds = el.getBoundingClientRect(el);
      return bounds;
    }
    return {};
  };

  error_timeout = setTimeout(() => {}, 1);
  error_form = async (state, message) => {
    return new Promise((resolve, reject) => {
      const _this = this._this;

      if (this.error_timeout) {
        clearTimeout(this.error_timeout);
      }

      let form_errors = _this.state.form_errors;
      if (!form_errors) {
        form_errors = {};
      }

      form_errors[state] = message;
      _this.setState(
        {
          form_errors: form_errors,
        },
        () => {
          resolve();

          this.error_timeout = setTimeout(() => {
            _this.setState({
              form_errors: {},
            });
          }, 5000);
        }
      );
    });
  };

  clear_form_errors = async () => {
    return new Promise((resolve, reject) => {
      const _this = this._this;
      _this.setState(
        {
          form_errors: {},
        },
        () => {
          resolve();
        }
      );
    });
  };

  initiateView = async (_this, initialState = {}, config = {}) => {
    if (!config) {
      config = {};
    }
    this._this = _this;

    _this.add_resize_function = this.add_resize_function;
    _this.add_scroll_function = this.add_scroll_function;
    _this.execute_scrolls = this.execute_scrolls;

    _this.state = {
      width: this.getWidth(),
      height: this.getHeight(),
      isMobile: this.getWidth() < 720 ? true : false,
      ...initialState,
    };

    const navigator = new AuthNavigator(_this);

    _this.componentDidMount = async () => {
      _this.execute_resizes = this.execute_resizes;
      window.addEventListener("resize", () => {
        this.execute_resizes(_this);
        this.resize(_this);
        if (_this.on_resize) {
          _this.on_resize();
        }
      });
      const user = await navigator.authenticate(config);
      mem.save("user", JSON.stringify(user));
      if (_this.onCreate) {
        _this.onCreate();
      }
    };

    _this.componentWillUnmount = () => {
      window.removeEventListener("resize", () => {
        this.resize(_this);
      });
    };
  };

  execute_resizes = (_this) => {
    this.resize(_this);
    if (_this.on_resize) {
      _this.on_resize();
    }
    if (_this.resize_functions) {
      _this.resize_functions.map((item) => {
        item.method();
      });
    }
  };

  add_resize_function = (name, method) => {
    let _this = this._this;
    if (_this.resize_functions) {
      let temp_functions = _this.resize_functions.filter(
        (obj) => obj.name != name
      );
      temp_functions.push({
        name,
        method,
      });
      _this.resize_functions = temp_functions;
    } else {
      _this.resize_functions = [];
      this.add_resize_function(name, method);
    }
  };

  uniqid = (prefix = "", random = false) => {
    const sec = Date.now() * 1000 + Math.random() * 1000;
    const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
    return `${prefix}${id}${
      random ? `.${Math.trunc(Math.random() * 100000000)}` : ""
    }`;
  };

  Row = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const isMobile = _this.state.isMobile;
    let flexDirection = "row";
    if (isMobile) {
      flexDirection = "column";
    } else {
      flexDirection = "row";
    }
    if (props.breakpoint != undefined) {
      const width = _this.state.width;
      if (width <= props.breakpoint) {
        flexDirection = "column";
        if (props.breakpoint_2 != undefined) {
          if (width <= props.breakpoint_2) {
            flexDirection = "row";
          } else {
            flexDirection = "column";
          }
        }
      } else {
        flexDirection = "row";
      }
    }
    return (
      <View style={{ ...props.style, flexDirection: flexDirection }}>
        {props.children}
      </View>
    );
  };

  Column = (props = { _this: null, style: {} }) => {
    const _this = props._this;
    const isMobile = _this.state.isMobile;
    const width = _this.state.width;
    let flexDirection = "column";
    if (isMobile) {
      flexDirection = "row";
    } else {
      flexDirection = "column";
    }
    if (props.breakpoint != undefined) {
      if (width <= props.breakpoint) {
        flexDirection = "row";
        if (props.breakpoint_2 != undefined) {
          if (width <= props.breakpoint_2) {
            flexDirection = "column";
          } else {
            flexDirection = "row";
          }
        }
      } else {
        flexDirection = "column";
      }
    }
    return (
      <View style={{ ...props.style, flexDirection: flexDirection }}>
        {props.children}
      </View>
    );
  };
  timestampToDate = (timestamp) => {
    timestamp = parseInt(timestamp);
    var dateObj = new Date(timestamp);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var day_week = dateObj.getDay();
    var year = dateObj.getUTCFullYear();
    let h_m_am_pm = dateObj.toLocaleString("en-US", {
      hour: "numeric",
      hour12: true,
      minute: "2-digit",
    });
    let newdate = year + "/" + month + "/" + day + " " + h_m_am_pm;
    let am_pm = h_m_am_pm.split(" ")[1].toLowerCase();
    let h_m = h_m_am_pm.split(" ")[0];
    let h = h_m.split(":")[0];
    let m = h_m.split(":")[1];

    let month_string = "";
    if (month == 1) {
      month_string = "January";
    } else if (month == 2) {
      month_string = "February";
    } else if (month == 3) {
      month_string = "March";
    } else if (month == 4) {
      month_string = "April";
    } else if (month == 5) {
      month_string = "May";
    } else if (month == 6) {
      month_string = "June";
    } else if (month == 7) {
      month_string = "July";
    } else if (month == 8) {
      month_string = "August";
    } else if (month == 9) {
      month_string = "September";
    } else if (month == 10) {
      month_string = "October";
    } else if (month == 11) {
      month_string = "November";
    } else if (month == 12) {
      month_string = "December";
    }

    const weeks = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const weeks3 = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    let obj = {
      year: year,
      month: month,
      day: day,
      am_pm: am_pm,
      h_m: h_m,
      hour: h,
      minute: m,
      month_string: month_string,
      day_week: day_week,
      day_week_string: weeks[day_week],
      day_week_string_3: weeks3[day_week],
    };

    return obj;
  };
  get_file = async () => {
    return new Promise((resolve, reject) => {
      let input = document.createElement("input");
      input.type = "file";

      input.onchange = (ev) => {
        const file = input.files[0];
        resolve(file);
      };
      input.click();
    });
  };

  get_file_extension = (filename) => {
    return filename?.substring(filename.lastIndexOf(".") + 1).toLowerCase();
  };

  get_image = async (other_extension = []) => {
    return new Promise((resolve, reject) => {
      let input = document.createElement("input");
      input.type = "file";
      console.log(input);

      input.onchange = (ev) => {
        const file = input.files[0];
        var url = input.value;
        var ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
        if (
          input.files &&
          input.files[0] &&
          (ext == "gif" ||
            ext == "png" ||
            ext == "jpeg" ||
            ext == "jpg" ||
            other_extension.includes(ext))
        ) {
          var reader = new FileReader();

          reader.onload = (e) => {
            resolve({ file, uri: e.target.result });
          };
          reader.readAsDataURL(input.files[0]);
        } else {
          const _this = this._this;
          if (_this) {
            if (_this.failed_modal) {
              _this.failed_modal("Invalid file format.");
            }
          }
        }
      };
      input.click();
    });
  };

  url_to_base64 = (url) => {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  };

  download_file = (image_link, tag) => {
    var a = document.createElement("a");
    a.href = image_link;
    a.download = tag;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  get_uri_from_file = async (file /* files[0] */) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };

  replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(this.escapeRegExp(find), "g"), replace);
  };

  pad = (num, size, isDecimal = false) => {
    if (num == undefined || num == null) {
      return "";
    }
    let n = num.toString();
    while (n.length < size) n = "0" + n;
    if (isDecimal) {
      if (num.length == 0) {
        num = "0";
      }
      num = Number.parseInt(num);
      let temp = num.toFixed(2);
      temp = temp.toString();
      const arr = temp.split(".");
      const suffix = arr[1];

      return n + "." + suffix;
    } else {
      return n;
    }
  };

  pad2 = (number) => {
    return this.pad(number, 2);
  };

  get_param = (param_name) => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get(param_name);
    return c;
  };
  path = (path) => {
    return this._this.props.match.params[path];
  };
  param = (param) => {
    return this.get_param(param);
  };
  title_case = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  hasError = async () => {
    const _this = this._this;
    const len = Object.keys(_this.state.form_errors).length;
    console.log(Object.keys(_this.state.form_errors));
    return len > 0 ? true : false;
  };
  hasNoError = async () => {
    const _this = this._this;
    const len = Object.keys(_this.state.form_errors).length;
    console.log(Object.keys(_this.state.form_errors));
    return len > 0 ? false : true;
  };

  onChangeText = (el, state) => {
    const _this = this._this;
    const text = el.target.value;
    _this.setState({
      [state]: text,
    });
  };

  date_dashed_to_date = (dashed /*2022-03-31 */) => {
    const split = dashed.split("-");
    const y = parseInt(split[0]);
    const m = parseInt(split[1]);
    const d = parseInt(split[2]);
    const new_d = new Date();
    new_d.setFullYear(y);
    new_d.setMonth(m - 1);
    new_d.setDate(d);
    return new_d;
  };
  date_dashed_to_iso = (dashed /*2022-03-31 */) => {
    const split = dashed.split("-");
    const y = parseInt(split[0]);
    const m = parseInt(split[1]);
    const d = parseInt(split[2]);
    const new_d = new Date();
    new_d.setFullYear(y);
    new_d.setMonth(m - 1);
    new_d.setDate(d);
    return new_d.toISOString();
  };

  date_iso_to_dashed = (iso) => {
    const d = new Date(iso);
    const year = d.getFullYear();
    const day = d.getDate();
    const month = d.getMonth() + 1;
    return year + "-" + this.pad2(month) + "-" + day;
  };
  date_obj_to_dashed = (iso) => {
    const d = iso;
    const year = d.getFullYear();
    const day = d.getDate();
    const month = d.getMonth() + 1;
    return year + "-" + this.pad2(month) + "-" + day;
  };
  date_iso_to_year = (iso) => {
    const d = new Date(iso);
    return d.getFullYear();
  };

  onChangeDate = (el, state) => {
    const _this = this._this;
    const value = el.target.value;
    const iso = this.date_dashed_to_iso(value);
    const date = this.date_dashed_to_date(value);
    _this.setState({
      [state]: iso,
      [state + "_date"]: date,
      [state + "_dashed"]: value,
    });
  };

  onChangeTime = (el) => {
    const _this = this._this;
    const value = el.target.value;
    return value;
  };

  onChangeTextOnly = (el, state) => {
    const _this = this._this;
    const text = el.target.value;
    const target = el.target;
    const with_number = target.value.match(/[0-9]/i)?.index >= 0 ? true : false;
    if (with_number) {
      target.value = target.value.replace(/[0-9]/g, "");
    }
    _this.setState({
      [state]: target.value,
    });
  };

  onChangeNumber = (el, state) => {
    const _this = this._this;
    const text = el.target.value;
    const target = el.target;

    //const with_number = target.value.match(/[a-z]/i)?.index >= 0 ? true : false;

    if (target.maxLength > 0) {
      if (target.value.length > target.maxLength) {
        target.value = target.value.slice(0, target.maxLength);
      }
    }

    if (target.value.includes(".")) {
      //count the dots, remove the last index
      var dots_count = target.value.match(/\./g).length;
      if (dots_count > 1) {
        //remove the last dot
        target.value = target.value.replace(/\.$/, "");
      }
    } else {
      target.value = target.value.replace(/\D/g, "");
    }

    _this.setState({
      [state]: target.value,
    });
  };

  handle_error = async (e) => {
    const _this = this._this;
    if (e.statusCode == 422) {
      //invalid format
      const details = e.details;
      details.map(async (item) => {
        const new_path = this.replaceAll(item.path, "/", "");
        const msg = item.message;
        await this.error_form(new_path, this.title_case(msg));
      });
    } else {
      _this.alert(e.message);
    }
  };

  logout = () => {
    const _this = this._this;

    mem.remove("jwt_token");
    _this.componentDidMount();
  };

  validate_email = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  is_empty = async (state, value, msg = "This field is required") => {
    return new Promise(async (resolve, reject) => {
      if (!value) {
        await this.error_form(state, msg);
        resolve();
      } else {
        resolve();
      }
    });
  };
  is_email = async (state, value, msg = "Please enter a valid email") => {
    return new Promise(async (resolve, reject) => {
      if (!this.validate_email(value)) {
        await this.error_form(state, msg);
        resolve();
      } else {
        resolve();
      }
    });
  };
  required_length = async (
    state,
    value,
    len,
    msg = "The length of the input is not valid"
  ) => {
    return new Promise(async (resolve, reject) => {
      const val = value ? value : "";
      if (val.length != len) {
        await this.error_form(state, msg);
        resolve();
      } else {
        resolve();
      }
    });
  };
  minimum_length = async (
    state,
    value,
    len,
    msg = "Does not reach the minimum length"
  ) => {
    return new Promise(async (resolve, reject) => {
      const val = value ? value : "";
      if (val.length < len) {
        await this.error_form(state, msg);
        resolve();
      } else {
        resolve();
      }
    });
  };

  select_file = async (state, callback) => {
    const _this = this._this;
    return new Promise(async (resolve, reject) => {
      const file = await this.get_file();
      //upload to server
      if (file) {
        _this.show_loading("Uploading...");
        await r.request({
          method: "post",
          isMultiPart: true,
          url: "/file/upload",
          params: {
            file: file,
          },
          onSuccess: async (e) => {
            const uri = await UI.get_uri_from_file(file);
            _this.setState({
              [state]: e.file.filename,
              [state + "_originalname"]: e.file.originalname,
              [state + "_uri"]: uri,
            });
            if (callback) {
              callback({
                filename: e.file.filename,
                originalname: e.file.originalname,
                file: file,
                uri: uri,
              });
            }
          },
          onFail: (e) => {
            console.log(e);
          },
          onFinish: (e) => {
            _this.hide_loading();
          },
        });
      }
    });
  };
  select_image = async (state, callback, allowed_file_extension = []) => {
    const _this = this._this;
    return new Promise(async (resolve, reject) => {
      const file = (await this.get_image(allowed_file_extension))?.file;
      //upload to server
      if (file) {
        _this.show_loading("Uploading...");
        await r.request({
          method: "post",
          isMultiPart: true,
          url: "/file/upload",
          params: {
            file: file,
          },
          onSuccess: async (e) => {
            const uri = await UI.get_uri_from_file(file);
            _this.setState({
              [state]: e.file.filename,
              [state + "_originalname"]: e.file.originalname,
              [state + "_uri"]: uri,
            });
            if (callback) {
              callback({
                filename: e.file.filename,
                originalname: e.file.originalname,
                file: file,
                uri: uri,
              });
            }
          },
          onFail: (e) => {
            console.log(e);
          },
          onFinish: (e) => {
            _this.hide_loading();
          },
        });
      }
    });
  };

  download_link = (filename) => {
    return r.hosting + "/file/retrieve/" + filename;
  };

  specialization_map = [
    "",
    "Finance",
    "Medical",
    "Bankcruptcy",
    "Product Liability",
    "Criminal Law",
  ];
}

const UI = new ui();
export default UI;

import React from "react";
import UI from "../../shared/Components/UI/js";
import {
  Image,
  ScrollView,
  Shadow,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "../../shared/custom-react-native";
import CalendarHelper from "./CalendarHelper";
import "./css.css";

const border_color = "#EBEBEB";

const WeekView = (
  props = {
    _this: null,
    context: null,
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    container_width: 0,
    width_limit: 0,
    exact_id: "",
  }
) => {
  const {
    _this,
    context,
    year,
    month,
    container_width,
    width_limit,
    exact_id,
  } = props;
  let new_id = "week_" + exact_id;
  let exact_width = container_width;
  let new_width = UI.measure(new_id).width;
  if (new_width) {
    exact_width = new_width;
  }

  let side_width = 60;
  if (exact_width <= 430) {
    side_width = 45;
  }

  return (
    <View id={new_id} style={{ width: "100%" }}>
      <WeekHeaders
        {...props}
        exact_width={exact_width}
        exact_width={exact_width}
        side_width={side_width}
      />
      <ScrollView style={{ width: "100%", maxHeight: 560 }}>
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"12:00"}
          text_2={"am"}
          offset={0}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"1:00"}
          text_2={"am"}
          offset={3600000 * 1}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"2:00"}
          text_2={"am"}
          offset={3600000 * 2}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"3:00"}
          text_2={"am"}
          offset={3600000 * 3}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"4:00"}
          text_2={"am"}
          offset={3600000 * 4}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"5:00"}
          text_2={"am"}
          offset={3600000 * 5}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"6:00"}
          text_2={"am"}
          offset={3600000 * 6}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"7:00"}
          text_2={"am"}
          offset={3600000 * 7}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"8:00"}
          text_2={"am"}
          offset={3600000 * 8}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"9:00"}
          text_2={"am"}
          offset={3600000 * 9}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"10:00"}
          text_2={"am"}
          offset={3600000 * 10}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"11:00"}
          text_2={"am"}
          offset={3600000 * 11}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"12:00"}
          text_2={"pm"}
          offset={3600000 * 12}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"1:00"}
          text_2={"pm"}
          offset={3600000 * 13}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"2:00"}
          text_2={"pm"}
          offset={3600000 * 14}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"3:00"}
          text_2={"pm"}
          offset={3600000 * 15}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"4:00"}
          text_2={"pm"}
          offset={3600000 * 16}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"5:00"}
          text_2={"pm"}
          offset={3600000 * 17}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"6:00"}
          text_2={"pm"}
          offset={3600000 * 18}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"7:00"}
          text_2={"pm"}
          offset={3600000 * 19}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"8:00"}
          text_2={"pm"}
          offset={3600000 * 20}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"9:00"}
          text_2={"pm"}
          offset={3600000 * 21}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"10:00"}
          text_2={"pm"}
          offset={3600000 * 22}
        />
        <WeekRow
          _this={_this}
          context={context}
          exact_width={exact_width}
          side_width={side_width}
          text_1={"11:00"}
          text_2={"pm"}
          offset={3600000 * 23}
        />
      </ScrollView>
    </View>
  );
};

const WeekRow = (
  props = {
    _this: null,
    context: null,
    side_width: 0,
    exact_width: 0,
    text_1: "",
    text_2: "",
    offset: 0,
  }
) => {
  const { _this, context, side_width, exact_width } = props;
  const { selected_week_items } = context.state;

  let index = 0;
  const data = context.props.data;
  const WeekItems = selected_week_items.map((day) => {
    index++;

    const hr_start = day.timestamp + props.offset;
    const hr_end = hr_start + 3599000;
    const events = data.filter(
      (item) => item.timestamp >= hr_start && item.timestamp < hr_end
    );

    let children = <View></View>;

    let onClick = () => {
      context.setState(
        {
          selected_month: day.month > 11 ? 0 : day.month < 0 ? 11 : day.month,
          selected_date: day.day,
          selected_year:
            day.month > 11
              ? day.year + 1
              : day.month < 0
              ? day.year - 1
              : day.year,
          selected_events: events,
        },
        () => {
          context.animate_sidebar();
        }
      );
    };

    if (events.length > 0) {
      children = (
        <View onClick={onClick} style={styles.day_item}>
          <Image
            height={30}
            width={30}
            tintColor={"transparent"}
            source={UI.SAMPLE_USER}
          />
          {exact_width > 380 && (
            <Text style={styles.day_item_patient}>Patient Name</Text>
          )}
        </View>
      );
    }
    let is_today = CalendarHelper.get_is_today(day.range.start);
    let additional = {};
    if (is_today) {
      additional = {
        backgroundColor: "#EDFBFF",
      };
    }
    if (index == selected_week_items.length) {
      return (
        <View style={{ ...styles.trailing_day, ...additional }}>
          {children}
        </View>
      );
    } else {
      return (
        <View style={{ ...styles.leading_day, ...additional }}>{children}</View>
      );
    }
  });

  return (
    <View style={{ width: "100%", flexDirection: "row" }}>
      <View style={{ ...styles.side_item, width: side_width }}>
        <Text
          style={{
            ...styles.header_text_2,
            fontSize: exact_width <= 430 ? 12 : 15,
          }}
        >
          {props.text_1}
        </Text>
        <Text
          style={{
            ...styles.header_text_2,
            fontSize: exact_width <= 430 ? 12 : 15,
          }}
        >
          {props.text_2}
        </Text>
      </View>
      {WeekItems}
    </View>
  );
};

const WeekHeaders = (
  props = { container_width: 0, exact_width: 0, side_width: 0, _this: null }
) => {
  const { container_width, exact_width, side_width, _this, context } = props;
  const { selected_week_items } = context.state;

  const data = context.props.data;
  const HeaderCells = selected_week_items.map((item) => {
    let is_today = CalendarHelper.get_is_today(item.range.start);
    const hr_start = item.range.start;
    const hr_end = item.range.end;
    const events = data.filter(
      (obj) => obj.timestamp >= hr_start && obj.timestamp < hr_end
    );

    const onClick = () => {
      const select_month =
        item.month > 11 ? 0 : item.month < 0 ? 11 : item.month;
      const select_day = item.day;
      const select_year =
        item.month > 11
          ? item.year + 1
          : item.month < 0
          ? item.year - 1
          : item.year;
      let d = new Date();
      d.setMonth(select_month);
      d.setDate(select_day);
      d.setFullYear(select_year);
      context.setState(
        {
          selected_month: select_month,
          selected_date: select_day,
          selected_year: select_year,
          selected_events: events,
          selected_timestamp: d.getTime(),
          calendar_type: "day",
        },
        () => {
          _this.setState({
            [context.state.my_filter_id]: "day",
          });
          context.animate_sidebar();
        }
      );
    };

    let month = item.month + 1;
    if (month == 0) {
      month = 12;
    }

    return (
      <TouchableOpacity
        onClick={onClick}
        style={{ flex: 1, backgroundColor: is_today ? "#EDFBFF" : "white" }}
      >
        <HeaderItem
          text={item.day_str + " " + month + "/" + item.day}
          align={"right"}
          {...props}
        />
      </TouchableOpacity>
    );
  });

  return (
    <View
      style={{
        ...styles.header_container,
        height: exact_width <= 670 ? 100 : null,
      }}
    >
      <View style={{ ...styles.header_side_item, width: side_width }}></View>
      {HeaderCells}
    </View>
  );
};

const HeaderItem = (
  props = { text: "", align: "left", container_width: 0, exact_width: 0 }
) => {
  let align = props.align.charAt(0).toUpperCase() + props.align.slice(1);
  const { container_width, exact_width } = props;
  return (
    <View
      style={{
        ...styles.header_item,
        ["border" + align + "Width"]: 2,
      }}
    >
      <Text
        style={{
          ...styles.header_text,
          fontSize: exact_width <= 440 ? 12 : 16,
        }}
      >
        {props.text}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  day_item_patient: {
    fontSize: 12,
    color: "black",
    marginTop: 5,
    textAlign: "center",
  },
  day_item: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  trailing_day: {
    minHeight: 91,
    maxHeight: 91,
    flex: 1,
    borderColor: border_color,
    borderStyle: "solid",
    borderBottomWidth: 2,
  },
  leading_day: {
    minHeight: 91,
    maxHeight: 91,
    flex: 1,
    borderColor: border_color,
    borderStyle: "solid",
    borderBottomWidth: 2,
    borderRightWidth: 2,
  },
  header_side_item: {
    width: 60,
    borderRightWidth: 2,
    borderColor: border_color,
    borderRightStyle: "solid",
  },
  side_item: {
    width: 60,
    borderColor: border_color,
    borderRightStyle: "solid",
    borderRightWidth: 2,
    borderBottomWidth: 2,
    padding: 10,
  },
  no_events: {
    width: "100%",
    alignItems: "center",
    paddingTop: 40,
  },
  empty_text: {
    color: "#BFBFBF",
    width: 200,
    textAlign: "center",
    fontSize: 15,
    fontWeight: "bold",
    marginTop: 30,
  },
  event_text: {
    fontSize: 10,
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  event_item: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: UI.colors.primary,
    alignSelf: "flex-end",
    margin: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  month_row: {
    borderColor: border_color,
    flex: 1,
    borderStyle: "solid",
    borderWidth: 2,
    marginTop: -2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    flexDirection: "row",
  },
  calendar_cell: {
    minHeight: 91,
    maxHeight: 91,
    flex: 1,
    borderStyle: "solid",
    borderWidth: 0,
  },
  trailing_date: {
    minHeight: 91,
    maxHeight: 91,
    flex: 1,
    borderStyle: "solid",
    borderWidth: 0,
  },
  patient_item: {
    flexDirection: "row",
    padding: 10,
    paddingBottom: 15,
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
  },
  header_1: {
    fontSize: 14,
    color: "#535353",
    fontWeight: "bold",
  },
  bottom_border: {
    padding: 20,
    borderStyle: "solid",
    borderColor: border_color,
    borderBottomWidth: 2,
  },
  overlay: {
    position: "absolute",
    backgroundColor: "black",
    borderRadius: 5,
  },
  menu_btn: {
    height: 25,
    width: 25,
    justifyContent: "center",
    alignItems: "center",
    marginTop: -2,
    marginRight: 20,
  },
  header_item: {
    flex: 1,
    borderColor: border_color,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
  },
  header_text: {
    fontSize: 15,
    color: "#2E2E2E",
    fontWeight: "bold",
    textAlign: "center",
  },
  header_text_2: {
    fontSize: 15,
    color: "#2E2E2E",
    fontWeight: "bold",
  },
  header_container: {
    minHeight: 35,
    width: "100%",
    borderColor: border_color,
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    flexDirection: "row",
  },
  side_bar: {
    height: "100%",
    width: 230,
    borderRightWidth: 2,
    borderRightStyle: "solid",
    borderColor: border_color,
    backgroundColor: "white",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    zIndex: 9,
  },
  box_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    color: UI.colors.secondary,
    flex: 1,
  },
  main_content_container: {
    borderRadius: 5,
    backgroundColor: "white",
    ...Shadow._3(),
    width: "100%",
  },
});

export default WeekView;

import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  r,
} from "../../shared/custom-react-native";

import UI from "../../shared/Components/UI/js";
import UserMainLayout from "../_components/User-MainLayout";
import mem from "../../shared/Components/Memory/js";
import { FormHelper } from "../_components/FormHelper";
import CalendarHelper from "../_components/CalendarHelper";


export default class ViewAll extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
        //Initial State Here
        data: {
          id: 0,
          email: "",
          emailVerified: null,
          profile_image: "",
          first_name: "",
          last_name: "",
          address: "",
          phone: "",
          user_type: "",
          school_graduated: "",
          graduated_at: "",
          years_of_experience: "",
          specialization_id: 0,
          proposed_fee: "",
          profile_description: "",
          metadata: null,
          created_at: "",
        },
        user: {
          id: 0,
          email: "",
          emailVerified: null,
          profile_image: "",
          first_name: "",
          last_name: "",
          address: "",
          phone: "",
          user_type: "",
          school_graduated: "",
          graduated_at: "",
          years_of_experience: "",
          specialization_id: 0,
          proposed_fee: "",
          profile_description: "",
          metadata: null,
          created_at: "",
        },

        message: "",
        consultation_time: "30",
        scheduled_at: "",
        amount: "",
        currency: "Php",

        calendar_days: [],
        selected_month: "Jan",
        selected_date: "1",
        did_time_update: false,
        selected_time: "10:00",
        available_schedules: [],
        schedule_selected: false,
        schedule_message: "",
        last_month: new Date().getMonth() + 1,
        last_date: 1,
      },
      {
        role: "client",
      }
    );
  }
  

  render() {
    const {
      width,
      data,
      user,
      calendar_days,
      available_schedules,
      schedule_selected,
      schedule_message,
      last_date,
      last_month,
    } = this.state;
    const w = width;

    const item = data;
    return (
      <UserMainLayout _this={this}>
        <View style={{ width: "100%", backgroundColor: "#F9FAFF" }}>
          {UI.box(20)}

          <View style={styles.content_container}>
            <View style={styles.content_subcontainer}>
              <Text style={styles.content_title}>Notifications</Text>

              {UI.box(10)}
           
              <View style={{ ...Shadow._4(), padding:20 }}>
                <NotificationsPage />
                <NotificationsPage />
                <NotificationsPage />
                <NotificationsPage />
                



                 
              </View>

            {UI.box(100)}
          </View>
          </View>

          {UI.box(20)}
        </View>
      </UserMainLayout>
    );
  }
}




class NotificationsPage extends Component {
    constructor(props){
        super(props);
        UI.initiateView(
            this,
            {

            },
            {
                role: "client",
            }
          );
      } 

      
    render() {     
        const { width } = this.state;
        const w = width;
    
          return(
             <View>
            <View style={{ 
              flexDirection: w < 1020 ? "column" : "row", 
              ...styles.notification_container,
             }}>
    
             
            <View style={{ flexDirection: w < 1020 ? "column" : "row" }}>
                <View style={{paddingRight:"20px", alignItems: w < 1020 ? "center" : "none" }}>
                    <img style={{ height: 80, width: 80, borderRadius: "50%",  marginTop: 10,}}
                    src={UI.ENRICO}/>
                </View>
    
                <View style={{ marginTop:"5px" , alignItems: w < 1020 ? "center" : "none", marginRight: "5px"}}>
                  <Text style={styles.Name_Text}>
                   Atty. Enrico Juliano
                  </Text>
                 
                   <Text style={styles.Body_Text}>
                   Your chosen attorney has accepted your appointment request. You will need to pay within 3 days, otherwise your appointment will be cancelled. 
                  </Text>
               </View>
            </View>
    
                <View style={{marginTop: w < 1020 ? "15px" : "25px", alignItems: w < 1020 ? "center" : "none",}}>
                    <TouchableOpacity onClick={() => {
                      UI.goTo("/details");
                    }}
                     style={styles.ViewDetails_Button}>
                    <Text style={styles.Button_Text}>
                       View Details
                    </Text>
                    </TouchableOpacity>
                </View>

    
            </View>
 
                
            <View style={{width: "95%", alignSelf:"center",
             backgroundColor: "#575757",padding: 1}}/>
            
         

           </View>
        
          )
    }
  
  }



const styles = StyleSheet.create({
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  },

  content_subcontainer: {
    width: "90%",
    // backgroundColor: "white",
    borderRadius: 5,
    padding: 10,
    paddingTop: 35,
    justifyContent: "center",
    opacity: 1,
  },

  content_title: {
    fontStyle: "Quicksand",
    color: "#575757",
    fontWeight: "bold",
    fontSize: "35px",
    paddingBottom: "10px",
  },

  notification_container:{
    borderRadius: "10px", 
   padding: "25px",
  justifyContent:"space-between"
  },

  ViewDetails_Button:{
    width: 120, 
    alignItems: "center", 
    paddingTop: 12, 
    backgroundColor: "#0F4059",
    height: "50px",
    borderRadius:"5px",
  },

  Button_Text:{
    color: "#FFFFFF", 
    fontSize: 14,
    fontWeight: "bold",
  },

  Name_Text:{
    fontStyle: "Quicksand", 
    color: "#575757", 
    fontWeight: "bold", 
    fontSize: "25px",
  },

  Body_Text:{
    fontStyle: "Quicksand", 
    color: "#0F4059", 
    fontWeight: 500, 
    fontSize: "15px",
  },
});

import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  r,
} from "../../shared/custom-react-native";
import "./css.css";
import UI from "../../shared/Components/UI/js";
import UserMainLayout from "../_components/User-MainLayout";
import { FormHelper } from "../_components/FormHelper";
import ZLayout from "../_components/ZLayout";

export default class Edit extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        address: "",
        phone: "",
        profile_image: "",
        profile_image_uri: "",
      },
      {
        role: "client",
      }
    );
  }

   /*
  onCreate = async () => {};

  handle_validation = async () => {
    await UI.clear_form_errors();
    return new Promise(async (resolve, reject) => {
      const {
        last_name,
        first_name,
        email,
        password,
        address,
        profile_image,
        confirm_password,
        phone,
      } = this.state;
      await UI.is_empty("last_name", last_name);
      await UI.is_empty("first_name", first_name);
      await UI.is_email("email", email);
      await UI.is_empty("email", email);
      //validate email here
      await UI.minimum_length(
        "password",
        password,
        8,
        "Password should be have atleast 8 characters"
      );
      await UI.is_empty("password", password);
      await UI.is_empty("address", address);
      await UI.required_length("phone", phone, 11, "Invalid phone number.");
      await UI.is_empty("phone", phone);
      await UI.is_empty("profile_image", profile_image);
      await UI.is_empty("confirm_password", confirm_password);
      if (confirm_password) {
        if (password != confirm_password) {
          await UI.error_form("confirm_password", "Passwords do not match.");
        }
      }
      if (await UI.hasNoError()) {
        resolve({
          last_name,
          first_name,
          email,
          password,
          address,
          profile_image,
          confirm_password,
          phone,
        });
      }
    });
  };

  submit = async () => {
    const values = await this.handle_validation();
    delete values["confirm_password"];
    this.show_loading();
    r.request({
      method: "post",
      url: "/client/register",
      params: values,
      onFail: (e) => {
        UI.handle_error(e);
      },
      onSuccess: (e) => {
        this.alert("Successfully created your account", () => {
          UI.goTo("/login");
        });
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };  */

  render() {
    const {
      width,
      isMobile,
      profile_image,
      profile_image_originalname,
      profile_image_uri,
    } = this.state;
    const w = width;
    const formwidth = width < 800 ? "100%" : "50%";

    return (
       
      <View style={styles.main_container}> 
         <UserMainLayout _this={this}>
       
          {/* HEADER */}

          <Text
            style={{
              color: "#575757",
              fontStyle: "Quicksand",
              fontSize: "40px",
              fontWeight: "bold",
              paddingLeft: w < 550 ? "30px" : "125px",
              paddingTop: "25px",
            }}
          >
            Edit Profile
          </Text>
          {UI.box(10)}

          {/* BODY */}
          <View style={styles.content_container}>
            {UI.box(20)}
            <View
              style={{
                width: "80%",
                backgroundColor: "white",
                borderRadius: 5,
                padding: w < 550 ? 15 : 60,
                paddingTop: 35,
                justifyContent: "center",
                opacity: 1,
              }}
            >
              <Text style={styles.user_information}>USER INFORMATION</Text>

              {UI.box(20)}
              <View>
                <UI.Row _this={this} breakpoint={800}>
                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_text}>Last Name</Text>
                    <FormHelper _this={this} state={"last_name"}>
                      <InputFormName type={"text"} placeholder={"Last Name"} />
                    </FormHelper>
                  </View>
                  {UI.box(30)}
                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_text}>First Name</Text>
                    <FormHelper _this={this} state={"first_name"}>
                      <InputFormName type={"text"} placeholder={"First Name"} />
                    </FormHelper>
                  </View>
                </UI.Row>
                <UI.Row _this={this} breakpoint={800}>
                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_text}>Address</Text>
                    <FormHelper _this={this} state={"address"}>
                      <InputForm type={"text"} placeholder={"Enter Address"} />
                    </FormHelper>
                  </View>
                  {UI.box(30)}
                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_text}> Email Address</Text>
                    <FormHelper _this={this} state={"email"}>
                      <InputForm type={"text"} placeholder={"name@email.com"} />
                    </FormHelper>
                  </View>
                </UI.Row>

                <UI.Row _this={this} breakpoint={800}>
                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_text}>Contact Number</Text>
                    <FormHelper _this={this} state={"phone"}>
                      <InputForm
                        type={"number"}
                        maxLength={11}
                        placeholder={"Enter Phone (09120000000)"}
                      />
                    </FormHelper>
                  </View>
                  {UI.box(30)}
                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_text}>Password</Text>
                    <FormHelper _this={this} state={"password"}>
                      <InputForm type={"password"} placeholder={"********"} />
                    </FormHelper>
                  </View>
                </UI.Row>

                {UI.box(10)}
                <UI.Row _this={this} breakpoint={800}>
                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_text}>Confirm Password</Text>
                    <FormHelper _this={this} state={"confirm_password"}>
                      <InputForm type={"password"} placeholder={"********"} />
                    </FormHelper>
                  </View>
                  {UI.box(30)}
                  <View style={{ width: formwidth }}></View>
                </UI.Row>
              </View>

              {UI.box(30)}
              <Text style={styles.user_information}>
                PROOF OF IDENTIFICATION
              </Text>
              <Text style={styles.user_subInformation}>
                Upload your profile picture
              </Text>
              {UI.box(15)}

              <View style={styles.user_uploadInput}>
                <FormHelper _this={this} state={"profile_image"}>
                  <TouchableOpacity
                    onClick={async () => {
                      await UI.select_image("profile_image");
                    }}
                    style={styles.upload_button}
                  >
                    {profile_image ? (
                      <img
                        style={{ height: "100%", width: "100%" }}
                        src={profile_image_uri}
                      />
                    ) : (
                      <View style={{ alignItems: "center" }}>
                        <img style={styles.upload} src={UI.UPLOAD} />
                        <Text
                          style={{ color: "#949494", fontStyle: "Quicksand" }}
                        >
                          Upload Here
                        </Text>
                      </View>
                    )}
                  </TouchableOpacity>
                </FormHelper>
                <Text style={{ paddingLeft: 35, color: "#F14336" }}>
                  {profile_image
                    ? profile_image_originalname
                    : "No File Uploaded"}
                </Text>
              </View>
              {UI.box(30)}

              <Text style={styles.user_accept}>
                By submitting, you accept our
                <Text style={{ color: "#F14336", paddingLeft: 4 }}>
                  Terms of Use{" "}
                </Text>
                and
                <Text style={{ color: "#F14336" }}> Privacy Policy </Text>
              </Text>

              {UI.box(25)}

              <View
                style={{
                  flexDirection: "row",
                  height: "100%",
                  width: "100%",
                  paddingRight: 70,
                }}
              >
                <UI.Row _this={this} breakpoint={800}>
                  <View style={{ paddingRight: 26 }}>
                    <TouchableOpacity
                      onClick={() => {
                        UI.goTo("/");
                      }}
                      style={styles.cancel_button}
                    >
                      <Text
                        style={{
                          color: "#0F4C59",
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        Cancel
                      </Text>
                    </TouchableOpacity>
                  </View>

                  <TouchableOpacity
                    onClick={() => {
                      this.submit();
                    }}
                    style={styles.register_button}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Register
                    </Text>
                  </TouchableOpacity>
                </UI.Row>
              </View>
            </View>
          </View>

          

       
          {UI.box(60)}
          </UserMainLayout>
    
      </View>
     
    
    );
  }
}

const InputForm = (props = { placeholder: "", type: "", maxLength: 0 }) => {
  let max = {};
  if (props.maxLength > 0) {
    max = { maxLength: props.maxLength };
  }
  return (
    <View style={styles.input_container}>
      <input
        style={styles.input_style}
        type={props.type}
        placeholder={props.placeholder}
        onChange={(e) => {
          if (props.type == "number") {
            UI.onChangeNumber(e, props.state);
          } else {
            UI.onChangeText(e, props.state);
          }
        }}
        {...max}
      />
    </View>
  );
};


const InputFormName = (props = { placeholder: "", type: "" }) => {
  return (
    <View style={styles.input_container}>
      <input
        style={styles.input_style}
        type={props.type}
        placeholder={props.placeholder}
        onChange={(e) => {
            UI.onChangeTextOnly(e, props.state);                  
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  user_information: {
    fontSize: 30,
    fontWeight: 600,
    fontStyle: "Quicksand",
    color: "#0F4059",
  },
  user_subInformation: {
    fontSize: 18,
    color: "#575757",
  },

  user_accept: {
    fontWeight: "bold",
    color: "#575757",
  },
  user_uploadInput: {
    flexDirection: "row",
    alignItems: "center",
  },
  signup_container: {
    flexDirection: "row",
    height: "100%",
    width: "100%",
    paddingRight: 70,
  },
  upload: {
    height: 150 * 0.35,
    width: 150 * 0.35,
  },
  login_banner: {
    height: 150,
    width: 300,
  },
  login_arrow: {
    height: 30,
    width: 30,
  },
  login_arrowButton: {
    width: 125,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#0F4C59",
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 14,
  },
  upload_button: {
    border: "1px solid",
    borderColor: "#BABBAB",
    width: "160px",
    height: "130px",
    alignItems: "center",
    justifyContent: "center",
  },
  cancel_button: {
    width: 190,
    alignItems: "center",
    backgroundColor: "white",
    paddingTop: 10,
    paddingBottom: 10,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#0F4059",
    marginTop: "10px",
  },
  register_button: {
    width: 190,
    alignItems: "center",
    backgroundColor: "#0F4059",
    paddingTop: 10,
    paddingBottom: 14,
    marginTop: "10px",
    height: 46,
  },
  input_text: {
    fontSize: "20px",
    color: "#575757",
    marginTop: "10px",
    marginBottom: "5px",
  },
  footer_text: {
    color: "#FFFFFF",
    padding: 10,
    fontStyle: "Quicksand",
    fontWeight: "bold",
    fontSize: "15px",
  },
  login_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
    paddingLeft: 10,
    paddingRight: 20,
  },
  /*   input_style: {
    paddingRight: 10,
    paddingLeft: 5,
    fontSize: 14,
    backgroundColor: "#F9FAFF",
  },
 */
  border: {
    width: "100%",
    height: "27px",
    backgroundColor: "#0F4059",
  },
  footer_container: {
    width: "100%",
    backgroundColor: "#171717",
    alignItems: "center",
  },
  header_container: {
    width: "100%",
    backgroundColor: "#F9FAFF",
    borderRadius: 5,
    flexDirection: "row",
    // justifyContent: "space-between",
  },
  content_container: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom:"45px"
  },
  main_container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F9FAFF",
  },
 
  input_style: {
    padding: 12,
    fontSize: 16,

    backgroundColor: "#F9FAFF",
  },
});

import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  r,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";

export class FormHelper extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { _this, state, style } = this.props;
    let {form_style} = _this.state;
    if (!form_style) {
      form_style = {};
    }
    if (!style) {
      style = {};
    }
    const context_state = _this.state;
    let form_errors = context_state.form_errors;
    if (!form_errors) {
      form_errors = {};
    }

    let hasError = false;
    let error_message = "";
    if (Object.keys(form_errors).includes(state)) {
      hasError = true;
      error_message = form_errors[state];
    }

    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => {
        if (React.isValidElement(child)) {
          //we pass a new props to the child
          return React.cloneElement(child, { state, _this,  });
        }
        return child;
      }
    );

    return (
      <View style = {{...style, ...form_style}}>
        <View
          style={{
            borderWidth: hasError ? 2 : 0,
            borderColor: "#c0392b",
            borderStyle: "solid",
            borderRadius: 5,
          }}
        >
          {childrenWithProps}
        </View>
        <Text style={{ marginTop: 5, fontSize: 12, color: "#c0392b" }}>
          {error_message}
        </Text>
      </View>
    );
  }
}

import { m } from "framer-motion";
import mem from "../Components/Memory/js";
import r from "../Components/Request/js";
import UI from "../Components/UI/js";

export class AuthNavigator {
  _this = null;
  constructor(_this) {
    this._this = _this;
    UI.set_context(_this);
  }

  check = async () => {
    return new Promise(async (resolve, reject) => {
      const token = mem.get("jwt_token");
      if (!token) {
        //if no token, resolve false
        mem.save("jwt_token", "false");
        resolve(false);
        return;
      }
      if (token == "false") {
        resolve(false);
        return;
      }
      await r.request({
        method: "GET",
        url: "/whoAmI",
        onSuccess: (e) => {
          resolve(e);
        },
        onFail: (e) => {
          resolve(false);
        },
      });
    });
  };

  authenticate = async (config) => {
    return new Promise(async (resolve, reject) => {
      const user = await this.check();
      if (user) {
        mem.save("role", user.user_type); //client or lawyer
        if (user.user_type != config.role) {
          //navigate to respective dashboard
          if (user.user_type == "client") {
            UI.goTo("/dashboard");
          }
          if (user.user_type == "lawyer") {
            UI.goTo("/admin/dashboard");
          }
        }

        resolve(user);
      } else {
        //When signing in, add pseudo token <unsure>
        const token = mem.get("jwt_token");
        const role = mem.get("role");

        if (config.role == "auth") {
          //go to register, just resolve
          resolve();
        } else {
          if (token) {
            mem.remove("jwt_token");
            if (role == "lawyer") {
              UI.goTo("/admin");
            } else {
              UI.goTo("/");
            }
          }
        }
      }
    });
  };
}

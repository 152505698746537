import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  Wrap,
  r,
} from "../../shared/custom-react-native";
import mem from "../../shared/Components/Memory/js";
import UI from "../../shared/Components/UI/js";
import UserMainLayout from "../_components/User-MainLayout";

export default class ConsultationList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
        //Initial State Here
        partial_lawyers: [],
        data: [],
        consult_type: "all", //all, pending, previous

        pages_array: [],
        number_of_pages: 1,
        current_page: 1,
        entries: 4,
      },
      {
        role: "client",
      }
    );
  }

  onCreate = async () => {
    this.load_data();
  };

  load_data = async () => {
    this.get_consultations(1);
  };

  get_consultations = async (page) => {
    this.show_loading();
    let additional = "";
    if (this.state.consult_type == "pending") {
      additional = "/pending";
    }
    if (this.state.consult_type == "previous") {
      additional = "/previous";
    }
    r.request({
      method: "get",
      url: "/client/consultations" + additional,
      params: {
        entries: this.state.entries,
        page: page,
      },
      onFail: (e) => {},
      onSuccess: (e) => {
        const pages = [];
        for (let i = 0; i < e.number_of_pages; i++) {
          pages.push(i + 1);
        }
        this.setState({
          data: e.data,
          number_of_pages: e.number_of_pages,
          pages_array: e.total ? pages : [],
          current_page: e.current_page,
        });
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  go_to_encounter = async (consultation_id) => {
    this.show_loading();

    r.request({
      method: "get",
      url: "/client/get/link/" + consultation_id,
      params: {},
      onFail: (e) => {
        this.alert(e.message);
      },
      onSuccess: (e) => {
        window.open(e.link, "_blank").focus();
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };
  render() {
    const { width, data, consult_type } = this.state;
    const horizontal_padding = width * 0.025;
    const w = width;
    let columns = 4;
    let minus = 160;
    const x = width;
    let pillar = 5;
    let minuses = 200;
    if (width < 960) {
      minus = 140;
      columns = 3;
    }
    if (width < 780) {
      minus = 120;
      columns = 2;
    }
    if (width < 660) {
      minus = 80;
      columns = 1;
    }
    const itemwidth = (w - minus) / columns - (horizontal_padding / 2 + 10);

    if (width < 1080) {
      minuses = 180;
      pillar = 4;
    }
    if (width < 960) {
      minuses = 160;
      pillar = 3;
    }
    if (width < 780) {
      minuses = 140;
      pillar = 2;
    }
    if (width < 660) {
      minuses = 120;
      pillar = 1;
    }
    const boxwidth = (x - minuses) / pillar - horizontal_padding / 2;

    const Data = this.state.data.map((obj) => {
      const item = obj.lawyer;
      const date = UI.timestampToDate(new Date(obj.scheduled_at).getTime());
      let Element = View;
      let onClick = () => {};
      if (consult_type == "all") {
        Element = TouchableOpacity;
        onClick = () => {
          //Create encounter link
        };
      }

      return (
        <View
          onClick={onClick}
          style={{
            width: itemwidth,
            borderRadius: "10px",
            ...Shadow._4(),
            marginRight: "20px",
            marginTop: "20px",
            alignItems: w < 730 ? "center" : "none",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingLeft: 13,
              paddingRight: w < 780 ? 35 : 13,
              paddingTop: 20,
            }}
          >
            <View style={{ marginRight: w < 780 ? "30px" : "0px" }}>
              <img
                style={styles.profile_img}
                src={UI.download_link(item.profile_image)}
              />
            </View>

            <View style={{}}>
              <Text style={styles.profile_DateText}> {date.day} </Text>
              <Text style={styles.profile_MonthText}>{date.month_string}</Text>
            </View>
          </View>

          <View
            style={{
              paddingTop: 10,
              paddingLeft: 20,
              paddingRight: w < 730 ? 35 : 20,
              paddingBottom: 20,
            }}
          >
            <Text style={styles.profile_Textfirst}>
              {" "}
              {item.first_name} {item.last_name}{" "}
            </Text>
            <Text style={styles.profile_Textsecond}>
              {UI.specialization_map[item.specialization_id]}
            </Text>
            <Text style={styles.profile_Textsecond}>
              {item.years_of_experience} Years of Experience | Class of{" "}
              {UI.date_iso_to_year(item.graduated_at)}{" "}
            </Text>
          </View>

          <View
            style={{
              paddingLeft: w < 730 ? 35 : 20,
              paddingRight: w < 730 ? 35 : 20,
              paddingBottom: 20,
            }}
          >
            <View style={{ flexDirection: "row", paddingBottom: 10 }}>
              <View style={{ paddingRight: 10 }}>
                <img style={styles.school} src={UI.SCHOOL} />
              </View>
              <View style={{}}>
                <Text style={styles.profile_ContactText}>
                  {item.school_graduated}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <View style={{ paddingRight: 10 }}>
                <img style={styles.map_marker} src={UI.Map_Marker} />
              </View>
              <View style={{}}>
                <Text style={styles.profile_ContactText}> {item.address} </Text>
              </View>
            </View>

            <TouchableOpacity
              onClick={() => {
                this.go_to_encounter(obj.id);
              }}
              style={{
                ...styles.register_button,
                width: 160,
                marginTop: 20,
                display: consult_type == "all" ? "flex" : "none",
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Go to Encounter
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.borderMini} />
        </View>
      );
    });

    return (
      <UserMainLayout _this={this} active_tab={"Consultation"}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#F9FAFF",
            paddingLeft: horizontal_padding,
            paddingRight: horizontal_padding,
          }}
        >
          {UI.box(20)}

          <View style={styles.content_container}>
            <View style={styles.content_subcontainer}>
              <View
                style={{
                  textAlign: w < 663 ? "center" : "none",
                  marginRight: w < 663 ? "50px" : "none",
                }}
              >
                <Text style={styles.content_title}>Consultation</Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: w < 663 ? "center" : "none",
                  marginRight: w < 663 ? "50px" : "none",
                }}
              >
                <TouchableOpacity
                  onClick={() => {
                    this.setState(
                      {
                        consult_type: "all",
                      },
                      () => {
                        this.get_consultations(1);
                      }
                    );
                  }}
                  style={{ paddingRight: "25px" }}
                >
                  <Text style={styles.content_subTitle}>All</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onClick={() => {
                    this.setState(
                      {
                        consult_type: "pending",
                      },
                      () => {
                        this.get_consultations(1);
                      }
                    );
                  }}
                  style={{ paddingRight: "20px" }}
                >
                  <Text style={styles.content_subTitle}>Pending</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onClick={() => {
                    this.setState(
                      {
                        consult_type: "previous",
                      },
                      () => {
                        this.get_consultations(1);
                      }
                    );
                  }}
                >
                  <Text style={styles.content_subTitle}>Previous</Text>
                </TouchableOpacity>
              </View>

              <View style={styles.consultation_container}>
                {/* FIRST */}
                {Data}
                {data.length == 0 && (
                  <Text
                    style={{
                      marginTop: 10,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    No Data Available
                  </Text>
                )}
              </View>
              <View
                style={{
                  width: "auto",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                {this.state.pages_array.map((i) => {
                  let default_style = {
                    backgroundColor: "#F26E50",
                    color: "#FFFFFF",
                  };
                  if (this.state.current_page != i) {
                    default_style = {
                      backgroundColor: "#FFFFFF",
                      color: "#F26E50",
                    };
                  }

                  return (
                    <TouchableOpacity
                      onClick={() => {
                        this.get_consultations(i);
                      }}
                      style={{
                        borderRadius: 5,
                        borderWidth: 1,
                        marginRight: "20px",
                        padding: "7px",
                        width: "50px",
                        textAlign: "center",
                        backgroundColor: default_style.backgroundColor,
                      }}
                    >
                      <Text
                        style={{ fontSize: "20px", color: default_style.color }}
                      >
                        {" "}
                        {i}{" "}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
              </View>

              <View style={{ width: w - horizontal_padding * 2 }}></View>
            </View>
          </View>

          {UI.box(50)}
        </View>
      </UserMainLayout>
    );
  }
}

const styles = StyleSheet.create({
  register_button: {
    width: 100,
    height: 39,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0F4059",
    borderRadius: 10,
  },
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  },

  content_subcontainer: {
    width: "auto",
    borderRadius: 5,
    paddingLeft: 40,
    justifyContent: "center",
    opacity: 1,
  },
  content_subTitle: {
    fontSize: "20px",
    color: "#575757",
    fontStyle: "Quicksand",
  },
  content_title: {
    fontStyle: "Quicksand",
    color: "#575757",
    fontWeight: "bold",
    fontSize: "35px",
    paddingBottom: "10px",
  },

  consultation_container: {
    flexDirection: "row",
    flexWrap: "wrap",
  },

  profile_img: {
    height: 130,
    width: 120,
    borderRadius: 50,
  },
  profile_DateText: {
    fontStyle: "#F26E50",
    fontSize: "60px",
    color: "#F26E50",
  },
  profile_MonthText: {
    fontStyle: "#F26E50",
    fontSize: "30px",
    color: "#F26E50",
  },

  profile_Textfirst: {
    color: "#575757",
    fontStyle: "Quicksand",
    fontSize: "25px",
  },

  profile_Textsecond: {
    olor: "#BABBAB",
    fontStyle: "Quicksand",
    fontSize: 14,
  },
  school: {
    height: 25,
    width: 25,
    paddingRight: 5,
  },
  map_marker: {
    height: 20,
    width: 20,
  },
  profile_ContactText: {
    color: "#0F4059",
    fontStyle: "Quicksand",
    fontSize: 14,
  },

  borderMini: {
    width: "100%",
    height: "20px",
    backgroundColor: "#F26E50",
    borderRadius: "0px 0px 10px 10px",
  },

  ///
  categories_container: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: "10px",
  },
  categories_text: {
    color: "#FFFFFF",
    fontStyle: "Quicksand",
    fontSize: "30px",
    fontWeight: "bold",
    marginTop: 8,
  },
  categories_Alternatetext: {
    color: "#FFFFFF",
    fontStyle: "Quicksand",
    fontSize: "25px",
    fontWeight: "bold",
    marginTop: 7,
  },
  categories_Solotext: {
    color: "#FFFFFF",
    fontStyle: "Quicksand",
    fontSize: "22px",
    fontWeight: "bold",
    marginTop: 5,
    textAlign: "center",
  },
  ///

  list_content_rightText: {
    fontStyle: "Quicksand",
    color: "#F26E50",
    fontWeight: "bold",
    fontSize: "30px",
    paddingTop: "5px",
  },
  list_content_rightImage: {
    height: 50,
    width: 30,
  },

  listProfile_img: {
    height: 100,
    width: 100,
    borderRadius: 50,
  },

  listProfile_TextFirst: {
    color: "#575757",
    fontStyle: "Quicksand",
    fontSize: "25px",
  },
  listProfile_TextSecond: {
    color: "#BABBAB",
    fontStyle: "Quicksand",
    fontSize: 14,
  },
  // BookAppoinment_button:{
  //   width:190,
  //   alignItems: "center",
  //    backgroundColor: "#0F4059",
  //    height:"50px",
  //    marginTop:"30px",
  //    paddingTop:"15px"
  // },
  BookAppoinment_buttonText: {
    color: "#FFFFFF",
    fontSize: 14,
    fontWeight: "bold",
  },
});

import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";
import { Spring, animated, Transition } from "react-spring";

export default class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animate: false,
    };
  }

  componentDidMount = () => {
    setInterval(() => {
      this.setState({
        animate: !this.state.animate,
      });
    }, 2000);
  };

  render() {
    return (
      <View style = {{marginTop: 500}}>
        <Transition
          items={this.state.animate}
          from={{ opacity: 0, marginTop: -100 }}
          enter={{ opacity: 1, marginTop: 0 }}
          leave={{ opacity: 0, marginTop: -400 }}
        >
          {(props) => (
            <animated.div style={props}>
              <Text>123</Text>
            </animated.div>
          )}
        </Transition>
      </View>
    );
  }
}

const styles = StyleSheet.create({});

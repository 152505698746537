import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import Calendar from "react-calendar";

export default class AdminHome extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
    });
  }

  render() {
    const { width } = this.state;
    const horizontal_padding = width * 0.025;
    const w = width;
    let columns = 3;
    let minus = 200;
    const x = width;

    let pillar = 5;
    let minuses = 200;

    if (width < 980) {
      minus = 150;
      columns = 2;
    }
    if (width < 700) {
      minus = 100;
      columns = 1;
    }
    
    const itemwidth = (w - minus) / columns - (horizontal_padding / 2 + 10);

    if (width < 1080) {
      minuses = 180;
      pillar = 4;
    }
    if (width < 960) {
      minuses = 160;
      pillar = 3;
    }
    if (width < 780) {
      minuses = 140;
      pillar = 2;
    }
    if (width < 660) {
      minuses = 120;
      pillar = 1;
    }
    const boxwidth = (x - minuses) / pillar - horizontal_padding / 2;

    return (
      <MainLayout _this={this} active_tab={"Home"} >
   {UI.box(30)}
        
       
        <Text style={styles.content_title}> Current Booking </Text>

        <View style={{padding: 25, marginLeft: 50,marginRight: 50, flexDirection:"row", alignItems:"center",
        justifyContent: "space-between", }}>

             <View style={{height: "350px", width: "335px", backgroundColor: "white"}}>
                <TouchableOpacity
                onClick={() => {
                  UI.goTo("/admin/current-bookings");
                }}
                >
                   <Text style={{fontSize: 16, textAlign: "center"}}>Total Number of Current Bookings</Text>
                </TouchableOpacity>
                  {UI.box(20)}
                  <div class="flex-wrapper">
                    <div class="single-chart">
                      <svg viewBox="0 0 36 36" class="circular-chart blue">
                        <path class="circle-bg"
                          d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path class="circle"
                          stroke-dasharray="35, 100"
                          d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <text x="18" y="20.35" class="percentage">35</text>
                      </svg>
                    </div>
                  </div>
            </View>

           <View style={{height: "350px", width: "335px", backgroundColor: "white"}}>
              <TouchableOpacity
               onClick={() => {
                UI.goTo("/admin/pending-bookings");
               }}>
                <Text style={{fontSize: 16, textAlign: "center"}}>Total Number of Pending Bookings</Text>
              </TouchableOpacity>    
                  {UI.box(20)}
                  <div class="flex-wrapper">
                    <div class="single-chart">
                      <svg viewBox="0 0 36 36" class="circular-chart orange">
                        <path class="circle-bg"
                          d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path class="circle"
                          stroke-dasharray="10, 100"
                          d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <text x="18" y="20.35" class="percentage">10</text>
                      </svg>
                    </div>
                  </div>
             </View>

          <View style={{height: "350px", width: "335px",backgroundColor: "white"}}>
             <TouchableOpacity
               >
                <Text style={{fontSize: 16, textAlign: "center"}}>Total Number of Completed Bookings</Text>
             </TouchableOpacity>
                  {UI.box(20)}
                  <div class="flex-wrapper">
                    <div class="single-chart">
                      <svg viewBox="0 0 36 36" class="circular-chart green">
                        <path class="circle-bg"
                          d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path class="circle"
                          stroke-dasharray="50, 100"
                          d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <text x="18" y="20.35" class="percentage">50</text>
                      </svg>
                    </div>
                 </div>
          </View>

       </View>


        <Text style={styles.content_title}> Categories </Text>

        <View style={styles.categories_container}>
          <TouchableOpacity
            onClick={() => {
              this.setState(
                {
                  specialization_id: 1,
                },
                this.onCreate
              );
            }}
          >
            <ImageBackground
              imageFit={ImageFit.COVER}
              source={UI.Finance}
              style={{
                marginTop: "20px",
                marginRight: "20px",
                height: "100px",
                borderRadius: "10px",
                ...Shadow._4(),
                width: boxwidth,
                padding: "20px",
                alignItems: "center",
              }}
            >
              <Text style={styles.categories_text}>Finance</Text>
            </ImageBackground>
          </TouchableOpacity>

          <TouchableOpacity
            onClick={() => {
              this.setState(
                {
                  specialization_id: 2,
                },
                this.onCreate
              );
            }}
          >
            <ImageBackground
              imageFit={ImageFit.COVER}
              source={UI.Medical}
              style={{
                marginTop: "20px",
                marginRight: "20px",
                height: "100px",
                borderRadius: "10px",
                ...Shadow._4(),
                width: boxwidth,
                padding: "20px",
                alignItems: "center",
              }}
            >
              <Text style={styles.categories_text}>Medical</Text>
            </ImageBackground>
          </TouchableOpacity>

          <TouchableOpacity
            onClick={() => {
              this.setState(
                {
                  specialization_id: 3,
                },
                this.onCreate
              );
            }}
          >
            <ImageBackground
              imageFit={ImageFit.COVER}
              source={UI.Bankrutpcy}
              style={{
                marginTop: "20px",
                marginRight: "20px",
                height: "100px",
                borderRadius: "10px",
                ...Shadow._4(),
                width: boxwidth,
                padding: "20px",
                alignItems: "center",
              }}
            >
              <Text style={styles.categories_text}>Bankruptcy</Text>
            </ImageBackground>
          </TouchableOpacity>

          <TouchableOpacity
            onClick={() => {
              this.setState(
                {
                  specialization_id: 4,
                },
                this.onCreate
              );
            }}
          >
            <ImageBackground
              imageFit={ImageFit.COVER}
              source={UI.Product}
              style={{
                marginTop: "20px",
                marginRight: "20px",
                height: "100px",
                borderRadius: "10px",
                ...Shadow._4(),
                width: boxwidth,
                alignItems: "center",
                paddingLeft: "10px",
                paddingTop: "20px",
                paddingRight: "10px",
                paddingBottom: "25px",
                justifyContent: "center",
              }}
            >
              <Text style={styles.categories_Solotext}>
                Product Liability
              </Text>
            </ImageBackground>
          </TouchableOpacity>

          <TouchableOpacity
            onClick={() => {
              this.setState(
                {
                  specialization_id: 5,
                },
                this.onCreate
              );
            }}
          >
            <ImageBackground
              imageFit={ImageFit.COVER}
              source={UI.Criminal}
              style={{
                marginTop: "20px",
                marginRight: "20px",
                height: "100px",
                borderRadius: "10px",
                ...Shadow._4(),
                width: boxwidth,
                alignItems: "center",
                paddingLeft: "10px",
                paddingTop: "25px",
                paddingRight: "10px",
                paddingBottom: "25px",
                justifyContent: "center",
              }}
            >
              <Text style={styles.categories_Alternatetext}>
                Criminal Law
              </Text>
            </ImageBackground>
          </TouchableOpacity>
        </View>
     
        {UI.box(50)}
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  main_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#f3f3f3",
  },
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  },

  content_subcontainer: {
    width: "auto",
    borderRadius: 5,
    padding: 10, /// width for inside
    paddingLeft: 55,
    justifyContent: "center",
    opacity: 1,
  },
  content_title: {
    fontStyle: "Quicksand",
    color: "#575757",
    fontWeight: "bold",
    fontSize: "35px",
    paddingBottom: "10px",
    marginLeft: 50,
  },
  categories_container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginLeft: 50,
    marginRight: 50,
  },
  categories_text: {
    color: "#FFFFFF",
    fontStyle: "Quicksand",
    fontSize: "30px",
    fontWeight: "bold",
    marginTop: 8,
  },
  categories_Alternatetext: {
    color: "#FFFFFF",
    fontStyle: "Quicksand",
    fontSize: "25px",
    fontWeight: "bold",
    marginTop: 7,
    textAlign: "center",
  },
  categories_Solotext: {
    color: "#FFFFFF",
    fontStyle: "Quicksand",
    fontSize: "22px",
    fontWeight: "bold",
    marginTop: 5,
    textAlign: "center",
  },
  borderMini: {
    width: "100%",
    height: "20px",
    backgroundColor: "#F26E50",
    borderRadius: "0px 0px 10px 10px",
  },
});

import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  r,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";
import { FormHelper } from "../_components/FormHelper";
import mem from "../../shared/Components/Memory/js";
import ZLayout from "../_components/ZLayout";

export default class AdminSignin extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
        //Initial State Here
        email: "",
        password: "",
      },
      {
        role: "auth",
      }
    );
  }

  onCreate = () => {};

  handle_validation = async () => {
    await UI.clear_form_errors();
    return new Promise(async (resolve, reject) => {
      const { email, password } = this.state;

      await UI.is_email("email", email);
      await UI.is_empty("email", email);
      await UI.minimum_length(
        "password",
        password,
        8,
        "Password should be have atleast 8 characters"
      );
      await UI.is_empty("password", password);
      if (await UI.hasNoError()) {
        resolve({
          email,
          password,
        });
      }
    });
  };

  submit = async () => {
    const values = await this.handle_validation();
    this.show_loading();
    r.request({
      method: "post",
      url: "/lawyer/login",
      params: values,
      onFail: (e) => {
        UI.handle_error(e);
      },
      onSuccess: (e) => {
        const { token } = e;
        mem.save("jwt_token", token);
        this.componentDidMount();
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const _this = this.props._this;
    const { width } = this.state;
  

    return (
      <View style={styles.main_container}>
        <ZLayout _this={this} />
        {/* LEFT BOX */}
        <View id={"white_container"} style={styles.first_container}>
          <View style={styles.firstBody_container}>
            <img style={styles.login_banner} src={UI.LOGIN_BANNER} />
            <Text style={styles.sign_in_text}>Welcome Back</Text>
            <Text style={styles.sign_in_subtext}>
              Hello there, sign in to continue
            </Text>
            {UI.box(20)}
            <FormHelper _this={this} state={"email"}>
              <InputForm
                _this={this}
                type={"text"}
                placeholder={"Email Address"}
              />
              
            </FormHelper>
            {UI.box(10)}
           
            <FormHelper _this={this} state={"password"}>
             {/* <InputForm
                _this={this}
                type={"password"} 
                placeholder={"Password"}
              /> */}

                 <InputFormPassword />
              
             </FormHelper>
           

            {UI.box(5)}

            <TouchableOpacity>
              <Text style={styles.forgot_password}>Forgot Password?</Text>
            </TouchableOpacity>
            {UI.box(30)}

            <TouchableOpacity
              onClick={() => {
                this.submit();
              }}
              style={styles.signin_btn}
            >
              <Text style={styles.sigin_btn_text}>Sign In</Text>
            </TouchableOpacity>

            {UI.box(40)}
            <Text style={styles.signin_with}>Or sign in with</Text>
            {UI.box(15)}

            <View style={styles.social_container}>
              <SocialButton icon={UI.LOGIN_GOOGLE} />
              <SocialButton icon={UI.LOGIN_APPLE} />
              <SocialButton icon={UI.LOGIN_FACEBOOK} />
            </View>

            {UI.box(35)}

            <TouchableOpacity
              onClick={() => {
                UI.goTo("/register");
              }}
              style={styles.signup_container}
            >
              <Text style={styles.donthave_text}>Don't have an account?</Text>

              <View style={styles.row}>
                <Text style={styles.signup_text}>Sign Up</Text>
                <img style={styles.chevron} src={UI.CHEVRON} />
              </View>
            </TouchableOpacity>
          </View>
        </View>

        {/* RIGHT BOX */}
        <ImageBackground
          style={{ flex: width > 730 ? 1 : 0 }}
          imageFit={ImageFit.COVER}
          source={UI.LOGIN_BG}
        ></ImageBackground>
      </View>
    );
  }
}

const SocialButton = (props = { icon: UI.LOGIN_GOOGLE }) => {
  return (
    <TouchableOpacity style={{ height: 44, width: 44 }}>
      <img style={{ height: 44, width: 44 }} src={props.icon} />
    </TouchableOpacity>
  );
};

const InputForm = (props = { placeholder: "", type: "", state: "" }) => {
  const _this = props._this;
  const pad = (_this.state.width / 2) * 0.35;
  const white_container =
    UI.measure("white_container").width ?? _this.state.width / 2;

  return (
    <View
      style={{
        ...styles.input_container,
        width: white_container - pad,
        minWidth: 260,
      }}
    >
      <input
        style={styles.input_style}
        type={props.type}
        placeholder={props.placeholder}
        onChange={(e) => {
          UI.onChangeText(e, props.state);
        }}
      />
    </View>
  );
};

class InputFormPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      is_Password_Shown: false,
    }; 

  }
  
  toggle_Password = () => {  
    this.setState(
    {
      is_Password_Shown: !this.state.is_Password_Shown,
    },
    );
  };

 
  render() {     
        const props = this.props;
        return(
          
          <View style={{ borderBottomWidth: 2, borderBottomColor: "#D3D3D3",borderBottomStyle: "solid", flexDirection:"row", 
         }}>

          <View style={{ width:"90%", }}>
            <input style={styles.input_style} 
            type={this.state.is_Password_Shown ? "text" : "password" }
              placeholder={"Password"}
               onChange={(e) => {
                UI.onChangeText(e, props.state);
              }}/>
          </View>

          <TouchableOpacity onClick={this.toggle_Password} style={{ width:"10%", alignSelf:"center", }}> 
              <View style={{paddingLeft:"15%"}}>
              <img style={{ height:30,width:30,}} src={this.state.is_Password_Shown ? UI.EYE_OPEN : UI.EYE_CLOSE} />
              </View>
          </TouchableOpacity>
      
        </View>
      
        )
  }

}

const styles = StyleSheet.create({
  chevron: {
    height: 15,
    width: 15,
  },
  signup_text: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#F14336",
  },
  row: {
    flexDirection: "row",
  },
  donthave_text: {
    fontSize: 12,
    color: "#575757",
    fontWeight: "bold",
  },
  signup_container: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  social_container: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 200,
    alignSelf: "center",
  },
  signin_with: {
    fontSize: 12,
    color: "#575757",
    fontWeight: "bold",
    textAlign: "center",
  },
  sigin_btn_text: {
    fontSize: 16,
    color: "white",
    textAlign: "center",
  },
  signin_btn: {
    width: "100%",
    backgroundColor: "#0F4059",
    borderRadius: 8,
    paddingTop: 10,
    paddingBottom: 10,
  },
  forgot_password: {
    color: "#F14336",
    fontWeight: "bold",
    textAlign: "right",
  },
  input_style: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 5,
    paddingLeft: 5,
    fontSize: 16,
    fontStyle: "Quicksand",
    color: "#575757",
    backgroundColor: "transparent",
  },
  input_container: {
    borderBottomWidth: 2,
    borderBottomColor: "#D3D3D3",
    borderBottomStyle: "solid",
  },
  sign_in_text: {
    color: "#575757",
    fontStyle: "Quicksand",
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "left",
  },
  sign_in_subtext: {
    color: "#575757",
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "left",
  },
  login_banner: {
    height: 424 * 0.35,
    width: 752 * 0.35,
  },

  first_container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F9FAFF",
  },
  main_container: {
    height: "100%",
    width: "100%",
    flexDirection: "row",
  },
});

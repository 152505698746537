import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  Wrap,
  r,
} from "../../shared/custom-react-native";
import mem from "../../shared/Components/Memory/js";
import UI from "../../shared/Components/UI/js";
import UserMainLayout from "../_components/User-MainLayout";

export default class LawyerList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
        //Initial State Here
        current_page: 1,
        entries: 4,
        data: [],
        number_of_pages: 1,
        pages_array: [],
        specialization_id: 1,
      },
      {
        role: "client",
      }
    );
  }

  onCreate = async (page = 1) => {
    this.load_data({
      include: "specialization_id=" + this.state.specialization_id,
      entries: this.state.entries,
      page: page,
    });
  };

  load_data = (params) => {
    this.show_loading();
    r.request({
      method: "get",
      url: "/client/lawyers",
      params: params,
      onFail: (e) => {},
      onSuccess: (e) => {
        const pages = [];
        for (let i = 0; i < e.number_of_pages; i++) {
          pages.push(i + 1);
        }
        this.setState({
          data: e.data,
          number_of_pages: e.number_of_pages,
          pages_array: e.total ? pages : [],
          current_page: e.current_page,
        });
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const { width } = this.state;
    const horizontal_padding = width * 0.025;
    const w = width;
    let columns = 4;
    let minus = 160;
    const x = width;
    let pillar = 5;
    let minuses = 200;
    if (width < 960) {
      minus = 140;
      columns = 3;
    }
    if (width < 780) {
      minus = 120;
      columns = 2;
    }
    if (width < 660) {
      minus = 80;
      columns = 1;
    }
    const itemwidth = (w - minus) / columns - (horizontal_padding / 2 + 10);

    if (width < 1080) {
      minuses = 180;
      pillar = 4;
    }
    if (width < 960) {
      minuses = 160;
      pillar = 3;
    }
    if (width < 780) {
      minuses = 140;
      pillar = 2;
    }
    if (width < 660) {
      minuses = 120;
      pillar = 1;
    }
    const boxwidth = (x - minuses) / pillar - horizontal_padding / 2;

    const Lawyers = this.state.data.map((item) => {
      return (
        <View
          style={{
            ...styles.lawyers_container,
            width: itemwidth,
            alignItems: w < 730 ? "center" : "none",
          }}
        >
          <View
            style={{
              justifyContent: "space-between",
              paddingLeft: 13,
              paddingRight: w < 365 ? 35 : 13,
              paddingTop: 20,
            }}
          >
            <View style={{ alignSelf: "center" }}>
              <img
                style={styles.profile_img}
                src={UI.download_link(item.profile_image)}
              />
            </View>
          </View>

          <View
            style={{
              ...styles.profileText_container,
              paddingRight: w < 730 ? 35 : 20, 
            }}
          >
            <Text style={styles.profile_Textfirst}>
              {item.first_name} {item.last_name}{" "}
            </Text>
            <Text style={styles.profile_Textsecond}>
              {UI.specialization_map[item.specialization_id]}
            </Text>
            <Text style={styles.profile_Textsecond}>
              {item.years_of_experience} Years of Experience | Class of{" "}
              {UI.date_iso_to_year(item.graduated_at)}{" "}
            </Text>
          </View>

          <View
            style={{
              paddingLeft: w < 730 ? 35 : 20,
              paddingRight: w < 730 ? 35 : 20,
              paddingBottom: 20,
            }}
          >
            <View style={{ flexDirection: "row", paddingBottom: 10 }}>
              <View style={{ paddingRight: 10 }}>
                <img style={styles.school} src={UI.SCHOOL} />
              </View>
              <View style={{}}>
                <Text style={styles.profile_ContactText}>
                  {item.school_graduated}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row", paddingBottom: 10 }}>
              <View style={{ paddingRight: 10 }}>
                <img style={styles.map_marker} src={UI.Map_Marker} />
              </View>
              <View style={{}}>
                <Text style={styles.profile_ContactText}> {item.address} </Text>
              </View>
            </View>

            <View style={{ alignItems: "center" }}>
              <TouchableOpacity
                onClick={() => {
                  UI.goTo("/lawyers/" + item.id);
                }}
                style={styles.BookAppoinment_button}
              >
                <Text style={styles.BookAppoinment_buttonText}>
                  Book Appointment
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.borderMini} />
        </View>
      );
    });

    return (
      <UserMainLayout _this={this} active_tab = {"Booking"}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#F9FAFF",
            paddingLeft: horizontal_padding,
            paddingRight: horizontal_padding,    
          }}
        >
          {UI.box(20)}

          <View style={styles.content_container}>
            <View style={styles.content_subcontainer}>
              <View style={{ flexDirection: w < 470 ? "column" : "row" }}>
                <View style={{ paddingRight: "20px" }}>
                  <Text style={styles.lawyer_list_title}>Lawyer List</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    marginTop: "15px",
                    marginBottom: "20px",
                  }}
                >
                  <TouchableOpacity
                    onClick={() => {
                      UI.goTo("/dashboard");
                    }}
                  >
                    <Text style={styles.lawyer_list_subtitle}>Home /</Text>
                  </TouchableOpacity>

                  <View
                    onClick={() => {
                      UI.goTo("/Lawyer_List");
                    }}
                  >
                    <Text style={styles.lawyer_list_subtitle}>
                      Lawyer List /
                    </Text>
                  </View>
                </View>
              </View>

              {UI.box(20)}

              <Text style={styles.content_title}> Categories </Text>

              <View style={styles.categories_container}>
                <TouchableOpacity
                  onClick={() => {
                    this.setState(
                      {
                        specialization_id: 1,
                      },
                      this.onCreate
                    );
                  }}
                >
                  <ImageBackground
                    imageFit={ImageFit.COVER}
                    source={UI.Finance}
                    style={{
                      ...styles.FirstBox_style,
                      width: boxwidth, 
                    }}
                  >
                    <Text style={styles.categories_text}>Finance</Text>
                  </ImageBackground>
                </TouchableOpacity>

                <TouchableOpacity
                  onClick={() => {
                    this.setState(
                      {
                        specialization_id: 2,
                      },
                      this.onCreate
                    );
                  }}
                >
                  <ImageBackground
                    imageFit={ImageFit.COVER}
                    source={UI.Medical}
                    style={{
                      ...styles.FirstBox_style,
                      width: boxwidth, 
                    }}
                  >
                    <Text style={styles.categories_text}>Medical</Text>
                  </ImageBackground>
                </TouchableOpacity>

                <TouchableOpacity
                  onClick={() => {
                    this.setState(
                      {
                        specialization_id: 3,
                      },
                      this.onCreate
                    );
                  }}
                >
                  <ImageBackground
                    imageFit={ImageFit.COVER}
                    source={UI.Bankrutpcy}
                    style={{
                      ...styles.FirstBox_style,
                      width: boxwidth,
                    }}
                  >
                    <Text style={styles.categories_text}>Bankruptcy</Text>
                  </ImageBackground>
                </TouchableOpacity>

                <TouchableOpacity
                  onClick={() => {
                    this.setState(
                      {
                        specialization_id: 4,
                      },
                      this.onCreate
                    );
                  }}
                >
                  <ImageBackground
                    imageFit={ImageFit.COVER}
                    source={UI.Product}
                    style={{
                      ...styles.SecondBox_style,
                      width: boxwidth,
                    }}
                  >
                    <Text style={styles.categories_Solotext}>
                      Product Liability
                    </Text>
                  </ImageBackground>
                </TouchableOpacity>

                <TouchableOpacity
                  onClick={() => {
                    this.setState(
                      {
                        specialization_id: 5,
                      },
                      this.onCreate
                    );
                  }}
                >
                  <ImageBackground
                    imageFit={ImageFit.COVER}
                    source={UI.Criminal}
                    style={{
                     ...styles.ThirdBox_style,
                      width: boxwidth,
                    }}
                  >
                    <Text style={styles.categories_Alternatetext}>
                      Criminal Law
                    </Text>
                  </ImageBackground>
                </TouchableOpacity>
              </View>

              {UI.box(20)}

              <View style={styles.consultation_container}>{Lawyers}</View>

              <View
                style={{
                  width: "auto",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                {this.state.pages_array.map((i) => {
                  let default_style = {
                    backgroundColor: "#F26E50",
                    color: "#FFFFFF",
                  };
                  if (this.state.current_page != i) {
                    default_style = {
                      backgroundColor: "#FFFFFF",
                      color: "#F26E50",
                    };
                  }

                  return (
                    <TouchableOpacity
                      onClick={() => {
                        this.load_data({
                          include:
                            "specialization_id=" + this.state.specialization_id,
                          entries: this.state.entries,
                          page: i,
                        });
                      }}
                    
                      style={{
                        ...styles.pagination_style,
                        backgroundColor: default_style.backgroundColor,
                      }}
                    >
                      <Text
                        style={{ fontSize: "20px", color: default_style.color }}
                      >
                        {" "}
                        {i}{" "}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
              </View>
            </View>
          </View>

          {UI.box(80)}
        </View>
      </UserMainLayout>
    );
  }
}

const styles = StyleSheet.create({
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  },

  content_subcontainer: {
    width: "auto",
    borderRadius: 5,
    padding: 10, /// width for inside
    paddingLeft: 40,
    justifyContent: "center",
    opacity: 1,
  },
  content_title: {
    fontStyle: "Quicksand",
    color: "#575757",
    fontWeight: "bold",
    fontSize: "35px",
    paddingBottom: "10px",
  },

  lawyer_list_title: {
    fontStyle: "Quicksand",
    color: "#575757",
    fontWeight: "bold",
    fontSize: "35px",
  },

  lawyer_list_subtitle: {
    fontSize: "17px",
    color: "#BABBAB",
    fontStyle: "Quicksand",
  },

  consultation_container: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  lawyers_container:{
    borderRadius: "10px",
    ...Shadow._4(),
    marginRight: "20px",
    marginTop: "20px",
    alignSelf: "flex-start",
  },

  profileText_container:{
    paddingTop: 10,
    paddingLeft: 20,
    alignItems: "center",
    paddingBottom: 20,
  },

  profile_img: {
    height: 130,
    width: 120,
    borderRadius: 50,
    alignSelf: "center",
  },
  profile_DateText: {
    fontStyle: "#F26E50",
    fontSize: "60px",
    color: "#F26E50",
  },
  profile_MonthText: {
    fontStyle: "#F26E50",
    fontSize: "30px",
    color: "#F26E50",
  },

  profile_Textfirst: {
    color: "#575757",
    fontStyle: "Quicksand",
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "center",
  },

  profile_Textsecond: {
    olor: "#BABBAB",
    fontStyle: "Quicksand",
    fontSize: 14,
  },
  school: {
    height: 25,
    width: 25,
    paddingRight: 5,
  },
  map_marker: {
    height: 20,
    width: 20,
  },
  profile_ContactText: {
    olor: "#0F4059",
    fontStyle: "Quicksand",
    fontSize: 14,
  },

  borderMini: {
    width: "100%",
    height: "20px",
    backgroundColor: "#F26E50",
    borderRadius: "0px 0px 10px 10px",
  },

  BookAppoinment_button: {
    width: 190,
    alignItems: "center",
    backgroundColor: "#0F4059",
    height: "50px",
    paddingTop: "15px",
  },
  BookAppoinment_buttonText: {
    color: "#FFFFFF",
    fontSize: 14,
    fontWeight: "bold",
  },

  ///
  categories_container: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: "10px",
  },
  categories_text: {
    color: "#FFFFFF",
    fontStyle: "Quicksand",
    fontSize: "30px",
    fontWeight: "bold",
    marginTop: 8,
  },
  categories_Alternatetext: {
    color: "#FFFFFF",
    fontStyle: "Quicksand",
    fontSize: "25px",
    fontWeight: "bold",
    marginTop: 7,
    textAlign: "center",
  },
  categories_Solotext: {
    color: "#FFFFFF",
    fontStyle: "Quicksand",
    fontSize: "22px",
    fontWeight: "bold",
    marginTop: 5,
    textAlign: "center",
  },

  FirstBox_style:{
    marginTop: "20px",
    marginRight: "20px",
    height: "100px",
    borderRadius: "10px",
    ...Shadow._4(),
    padding: "20px",
    alignItems: "center",
  },
  SecondBox_style:{
    marginTop: "20px",
    marginRight: "20px",
    height: "100px",
    borderRadius: "10px",
    ...Shadow._4(),
    alignItems: "center",
    paddingLeft: "10px",
    paddingTop: "20px",
    paddingRight: "10px",
    paddingBottom: "25px",
    justifyContent: "center",
  },
  ThirdBox_style:{
    marginTop: "20px",
    marginRight: "20px",
    height: "100px",
    borderRadius: "10px",
    ...Shadow._4(),
    alignItems: "center",
    paddingLeft: "10px",
    paddingTop: "25px",
    paddingRight: "10px",
    paddingBottom: "25px",
  },
  pagination_style:{
    borderRadius: 5,
    borderWidth: 1,
    marginRight: "20px",
    padding: "7px",
    width: "50px",
    textAlign: "center",
  }

});

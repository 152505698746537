//Images are stored in public folder
class Images {
  /* ADMIN - WEB */
  CHEVRON = "/img/chevron.png";
  LOGIN_APPLE = "/img/login_apple.png";
  LOGIN_BANNER = "/img/login_banner.png";
  LOGIN_BG = "/img/login_bg.png";
  LOGIN_FACEBOOK = "/img/login_facebook.png";
  LOGIN_GOOGLE = "/img/login_google.png";
  LOGIN_BUTTON = "/img/login_button.png";
  UPLOAD_BUTTON = "/img/upload_button.png";
  HEADER_CREATE = "/img/header_create.png";
  MAIN_LINE_3 = "/img/main_line_3.png";
  MAIN_ARROW_RIGHT = "/img/main_arrow_right.png";
  JOSHUA = "/img/joshua.png";
  CARMEN = "/img/carmen.png";
  SCHOOL = "/img/school.png";
  MAP_MARKER_ALT = "/img/map_marker_alt.png";
  BOX_BOTTOM = "/img/box_bottom.png";
  VIEW_ALL = "/img/view_all.png";
  CALENDAR_TOP = "/img/calendar_top.png";
  FILTER_SLIDERS = "/img/filter_sliders.png";
  AMANDA = "/img/amanda.png";
  CHRISTIAN = "/img/christian.png";
  ENRICO = "/img/enrico.png";
  CARET_LEFT = "/img/caret_left.png";
  CARET_RIGHT = "/img/caret_right.png";
  CLOSE = "/img/close.png";
  MENU_BARS = "/img/menu_bars.png";
  NOTIFICATIONS = "/img/notifications.png";
  CALENDAR_EXAMPLE = "/img/calendar_example.png";
  ON_CLICKED_BELL = "/img/on_clicked_bell.png";
  ON_CLICKED_MESSAGES = "/img/on_clicked_messages.png";

  UPLOAD = "/img/upload.png";
  LOGIN_ARROW = "/img/login_arrow.png";
  ARROW_RIGHT = "/img/arrow-right.png";
  MESSAGES = "/img/messages.png";
  BELL = "/img/bell.png";
  Line_3 = "/img/line_3.png";
  Search = "/img/search.png";
  Map_Marker = "/img/map_marker.png";
  Bankrutpcy = "/img/bankrutpcy.png";
  Criminal = "/img/criminal.png";
  Finance = "/img/finance.png";
  Medical = "/img/medical.png";
  Product = "/img/product.png";
  Plus = "/img/plus.png";
  Ellipse = "/img/Ellipse.png";
  Clock = "/img/clock.png";
  Happy = "/img/happy.png";
  Paperclip = "/img/paperclip.png";
  Path = "/img/Path.png";
  Circle = "/img/Circle.png";
  Upward = "/img/Upward.png";
  Grey = "/img/Greysearch.png";
  CARET_DOWN = "/img/caret-down.png";

  CHEVRON_RIGHT = "/img/chevron_right.png";
  CHEVRON_LEFT = "/img/chevron_left.png";
  LOADER = "/img/loader.png";
  EYE_OPEN = "/img/eye_open.png";
  EYE_CLOSE = "/img/eye_close.png";
}

export default Images;

import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  Wrap,
  r,
} from "../../shared/custom-react-native";

import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";

export default class BookingList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
        //Initial State Here
        // signin_string: "Hello world",
        // icon: UI.LOGIN_BANNER,
      },
      {
        role: "lawyer",
      }
    );
  }

  onCreate = async () => {
    this.show_loading();
    r.request({
      method: "get",
      url: "/lawyers/available-schedule",
      params: {},
      onFail: (e) => {},
      onSuccess: (e) => {},
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const { width } = this.state;
    const horizontal_padding = width * 0.015;
    const w = width;
    let columns = 4;
    let minus = 120;
    if (width < 1100) {
      minus = 80;
      columns = 3;
    }
    if (width < 960) {
      minus = 120;
      columns = 2;
    }
    if (width < 660) {
      minus = 80;
      columns = 1;
    }
    const itemwidth = (w - minus) / columns - (horizontal_padding / 2 + 10);
    const left_padding = horizontal_padding - 20;
    return (
      <MainLayout _this={this}>
        <View style={{ width: "100%", backgroundColor: "#F9FAFF",  paddingLeft: horizontal_padding,
            paddingRight: horizontal_padding, }}>
          {UI.box(20)}

          <View style={styles.content_container}>
            <View style={styles.content_subcontainer}>

            <View style={{ flexDirection: w < 515 ? "column" : "row", 
            paddingLeft: left_padding,
            paddingRight: horizontal_padding, }}>
                <View style={{marginLeft:""}}>
                  <Text style={styles.content_title}>Current Bookings</Text>
{/* 
                  <TouchableOpacity style ={{marginLeft: w < 900 ?  500 : 800}} >
                        <View style={{height: 45, width: 130 , backgroundColor: "#F26E50", flexDirection:"row"}}>
                            <Text style={{fontSize: 16, color: "#FFFFFF", margin: 10}}>Filter By  </Text>

                            <img style = {{height: 25, width: 25, margin: 10, marginTop: 10}}src={UI.FILTER_SLIDERS}/>
                        </View>
                </TouchableOpacity> */}

                </View>

            
                 <TouchableOpacity style={{ flexDirection:"row", marginLeft:"auto", marginTop:"10px"  }} >
                        <View style={{height: 45, width: 130 , backgroundColor: "#F26E50", flexDirection:"row", borderRadius: "5px 5px 5px 5px"}}>
                            <Text style={{fontSize: 16, color: "#FFFFFF", margin: 10, paddingLeft:"3px"}}>Filter By  </Text>

                            <img style = {{height: 25, width: 25, margin: 10, marginTop: 10}}src={UI.FILTER_SLIDERS}/>
                        </View>
                </TouchableOpacity>
         
                
              </View>
            
           

              <View style={styles.consultation_container}>
                {/* FIRST */}

                <View
                  style={{
                    width: itemwidth,
                    borderRadius: "10px",
                    ...Shadow._4(),
                    marginRight: "20px",
                    marginTop: "20px",
                    alignItems: w < 730 ? "center" : "none",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingLeft: 13,
                      paddingRight: w < 365 ? 35 : 13,
                    }}
                  >
                    <View style={{ marginRight: w < 780 ? "30px" : "0px", paddingTop:"20px" }}>
                    <img style={styles.profile_img} src={UI.JOSHUA} />
                    </View>

                    <View style={{paddingTop:"5px"}}>
                      <Text style={styles.profile_DateText}> 10 </Text>
                      <Text style={styles.profile_MonthText}>March</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 15,
                    }}
                  >
                    <Text style={styles.profile_Textfirst}>
                      {" "}
                      Joshua Barnes{" "}
                    </Text>
                    <Text style={styles.profile_Textsecond}>
                      Client
                    </Text>
                   
                  </View>

                  <View
                    style={{
                      paddingLeft: w < 730 ? 35 : 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 30
                    }}
                  >
                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Schedule
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Wed, March 26, 2021 | 10:30 am
                      </Text>
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        https://zoom.us/meetings
                      </Text>
                    </View>

                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Topic / Query
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Noisy Neighbor and Harassment
                      </Text>
                    </View>

                  </View>
                  <View style={styles.borderMini} />
                </View>
               

                {/* SECOND
                 */}
                    <View
                  style={{
                    width: itemwidth,
                    borderRadius: "10px",
                    ...Shadow._4(),
                    marginRight: "20px",
                    marginTop: "20px",
                    alignItems: w < 730 ? "center" : "none",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingLeft: 13,
                      paddingRight: w < 365 ? 35 : 13,
                    }}
                  >
                    <View style={{ marginRight: w < 780 ? "30px" : "0px", paddingTop:"20px" }}>
                    <img style={styles.profile_img} src={UI.CARMEN} />
                    </View>

                    <View style={{paddingTop:"5px"}}>
                      <Text style={styles.profile_DateText}> 26 </Text>
                      <Text style={styles.profile_MonthText}>March</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 15,
                    }}
                  >
                    <Text style={styles.profile_Textfirst}>
                      {" "}
                      Carmen Deleon{" "}
                    </Text>
                    <Text style={styles.profile_Textsecond}>
                      Client
                    </Text>
                   
                  </View>

                  <View
                    style={{
                      paddingLeft: w < 730 ? 35 : 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 30
                    }}
                  >
                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Schedule
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Wed, March 26, 2021 | 10:30 am
                      </Text>
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        https://zoom.us/meetings
                      </Text>
                    </View>

                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Topic / Query
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Noisy Neighbor and Harassment
                      </Text>
                    </View>

                  </View>
                  <View style={styles.borderMini} />
                </View>
               

                {/* Third */}

                <View
                  style={{
                    width: itemwidth,
                    borderRadius: "10px",
                    ...Shadow._4(),
                    marginRight: "20px",
                    marginTop: "20px",
                    alignItems: w < 730 ? "center" : "none",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingLeft: 13,
                      paddingRight: w < 365 ? 35 : 13,
                    }}
                  >
                    <View style={{ marginRight: w < 780 ? "30px" : "0px", paddingTop:"20px" }}>
                    <img style={styles.profile_img} src={UI.AMANDA} />
                    </View>

                    <View style={{paddingTop:"5px"}}>
                      <Text style={styles.profile_DateText}> 31 </Text>
                      <Text style={styles.profile_MonthText}>March</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 15,
                    }}
                  >
                    <Text style={styles.profile_Textfirst}>
                      {" "}
                      Joshua Barnes{" "}
                    </Text>
                    <Text style={styles.profile_Textsecond}>
                      Client
                    </Text>
                   
                  </View>

                  <View
                    style={{
                      paddingLeft: w < 730 ? 35 : 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 30
                    }}
                  >
                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Schedule
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Wed, March 26, 2021 | 10:30 am
                      </Text>
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        https://zoom.us/meetings
                      </Text>
                    </View>

                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Topic / Query
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Noisy Neighbor and Harassment
                      </Text>
                    </View>

                  </View>
                  <View style={styles.borderMini} />
                </View>

                {/* Fourth */}

                <View
                  style={{
                    width: itemwidth,
                    borderRadius: "10px",
                    ...Shadow._4(),
                    marginRight: "20px",
                    marginTop: "20px",
                    alignItems: w < 730 ? "center" : "none",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingLeft: 13,
                      paddingRight: w < 365 ? 35 : 13,
                    }}
                  >
                    <View style={{ marginRight: w < 780 ? "30px" : "0px", paddingTop:"20px" }}>
                    <img style={styles.profile_img} src={UI.JOSHUA} />
                    </View>

                    <View style={{paddingTop:"5px"}}>
                      <Text style={styles.profile_DateText}> 10 </Text>
                      <Text style={styles.profile_MonthText}>March</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 15,
                    }}
                  >
                    <Text style={styles.profile_Textfirst}>
                      {" "}
                      Joshua Barnes{" "}
                    </Text>
                    <Text style={styles.profile_Textsecond}>
                      Client
                    </Text>
                   
                  </View>

                  <View
                    style={{
                      paddingLeft: w < 730 ? 35 : 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 30
                    }}
                  >
                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Schedule
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Wed, March 26, 2021 | 10:30 am
                      </Text>
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        https://zoom.us/meetings
                      </Text>
                    </View>

                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Topic / Query
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Noisy Neighbor and Harassment
                      </Text>
                    </View>

                  </View>
                  <View style={styles.borderMini} />
                </View>

              </View>

              <View
                style={{
                  width: "auto",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                <TouchableOpacity
                  style={{
                    borderRadius: 5,
                    borderWidth: 1,
                    backgroundColor: "#F26E50",
                    marginRight: "20px",
                    padding: "7px",
                    width: "50px",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", color: "#FFFFFF" }}>
                    {" "}
                    1{" "}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    borderRadius: 5,
                    marginRight: "20px",
                    padding: "7px",
                    width: "50px",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", color: "#F26E50" }}>
                    {" "}
                    2{" "}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    borderRadius: 5,
                    marginRight: "20px",
                    padding: "7px",
                    width: "50px",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", color: "#F26E50" }}>
                    {" "}
                    3{" "}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>

          {UI.box(20)}
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  
  },

  content_subcontainer: {
    width: "auto",
    borderRadius: 5,
    padding: 10, 
    paddingLeft: 45,
    justifyContent: "center",
    opacity: 1,
  },
  content_subTitle: {
    fontSize: "20px",
    color: "#575757",
    fontStyle: "Quicksand",
  },
  content_title: {
    fontStyle: "Quicksand",
    color: "#575757",
    fontWeight: "bold",
    fontSize: "35px",
    paddingBottom: "10px",
  },
  booking_subtitle: {
    fontSize: "17px",
    color: "#BABBAB",
    fontStyle: "Quicksand",
  },
  consultation_container: {
    flexDirection: "row",
    flexWrap: "wrap",

  },

  profile_img: {
    height: 120,
    width: 120,
    borderRadius:60,
  },
  profile_DateText: {
    fontStyle: "#F26E50",
    fontSize: "60px",
    color: "#F26E50",
  },
  profile_MonthText: {
    fontStyle: "#F26E50",
    fontSize: "30px",
    color: "#F26E50",
  },

  profile_Textfirst: {
    color: "#575757",
    fontStyle: "Quicksand",
    fontSize: "25px",
  },

  profile_Textsecond: {
    olor: "#BABBAB",
    fontStyle: "Quicksand",
    fontSize: 14,
  },
  profile_ContactText: {
    olor: "#0F4059",
    fontStyle: "Quicksand",
    fontSize: 14,
  },

  borderMini: {
    width: "100%",
    height: "20px",
    backgroundColor: "#F26E50",
    borderRadius: "0px 0px 10px 10px",
  },
});

import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  r,
} from "../../shared/custom-react-native";

import UI from "../../shared/Components/UI/js";
import UserMainLayout from "../_components/User-MainLayout";
import mem from "../../shared/Components/Memory/js";
import { FormHelper } from "../_components/FormHelper";
import CalendarHelper from "../_components/CalendarHelper";

const MonthMap = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default class Booking extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
        //Initial State Here
        data: {
          id: 0,
          email: "",
          emailVerified: null,
          profile_image: "",
          first_name: "",
          last_name: "",
          address: "",
          phone: "",
          user_type: "",
          school_graduated: "",
          graduated_at: "",
          years_of_experience: "",
          specialization_id: 0,
          proposed_fee: "",
          profile_description: "",
          metadata: null,
          created_at: "",
        },
        user: {
          id: 0,
          email: "",
          emailVerified: null,
          profile_image: "",
          first_name: "",
          last_name: "",
          address: "",
          phone: "",
          user_type: "",
          school_graduated: "",
          graduated_at: "",
          years_of_experience: "",
          specialization_id: 0,
          proposed_fee: "",
          profile_description: "",
          metadata: null,
          created_at: "",
        },

        message: "",
        consultation_time: "30",
        scheduled_at: "",
        amount: "",
        currency: "Php",

        calendar_days: [],
        selected_month: "Jan",
        selected_date: "1",
        did_time_update: false,
        selected_time: "10:00",
        available_schedules: [],
        schedule_selected: false,
        schedule_message: "",
        last_month: new Date().getMonth() + 1,
        last_date: 1,
      },
      {
        role: "client",
      }
    );
  }
  onCreate = async () => {
    const d = new Date();
    this.set_calendar_days(d.getMonth(), d.getFullYear());
    const user = JSON.parse(mem.get("user"));
    this.setState({
      user: user,
    });
    const id = UI.path("id");
    this.show_loading();
    r.request({
      method: "get",
      url: "/client/lawyers/" + id,
      params: {},
      onFail: (e) => {},
      onSuccess: (e) => {
        if (e) {
          this.setState({
            data: e,
            amount: e.proposed_fee,
          });
        } else {
          UI.goTo("/lawyers");
        }
      },
      onFinish: () => {
        //this.hide_loading();
        //get all available schedule
        this.get_available_schedule(new Date().getMonth() + 1, 1);
      },
    });
  };

  get_available_schedule = async (month, date) => {
    this.show_loading();
    this.setState({
      last_month: month,
      last_date: date,
    });
    const id = UI.path("id");
    r.request({
      method: "get",
      url: "/lawyers/available-schedule/" + id,
      params: {
        consultation_time: this.state.consultation_time,
        month: month,
        date: date,
        year: new Date().getFullYear(),
      },
      onFail: (e) => {
        this.setState({
          available_schedules: [],
          schedule_selected: false,
          schedule_message: e.message,
        });
      },
      onSuccess: (e) => {
        this.setState({
          available_schedules: e.available_schedules ?? e,
          schedule_message: "",
          schedule_selected: false,
        });
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  set_calendar_days = async (month, year) => {
    const days = CalendarHelper.get_active_days(month, year);
    this.setState({
      calendar_days: days,
      selected_month: MonthMap[month],
    });
  };

  handle_validation = async () => {
    await UI.clear_form_errors();
    return new Promise(async (resolve, reject) => {
      let {
        message,
        consultation_time,
        scheduled_at,
        amount,
        currency,
        schedule_selected,
      } = this.state;

      if (!schedule_selected) {
        await UI.error_form("date_picker", "Please select a schedule first.");
      }
      consultation_time = parseInt(consultation_time);
      await UI.is_empty("message", message);
      await UI.is_empty("scheduled_at", scheduled_at);

      const date_now = new Date();
      date_now.setHours(0);
      date_now.setMinutes(0);
      date_now.setSeconds(0);
      date_now.setMilliseconds(0);
      const this_date = new Date(scheduled_at);
      this_date.setHours(0);
      this_date.setMinutes(0);
      this_date.setSeconds(0);
      this_date.setMilliseconds(0);
      if (date_now > this_date) {
        await UI.error_form("date_picker", "Past Dates are not allowed");
        await UI.error_form("scheduled_at", "Past Dates are not allowed");
      }

      if (await UI.hasNoError()) {
        resolve({ message, consultation_time, scheduled_at, amount, currency });
      }
    });
  };

  submit = async () => {
    const values = await this.handle_validation();
    this.show_loading();
    r.request({
      method: "post",
      url: "/client/book/lawyer/" + this.state.data.id,
      params: values,
      onFail: (e) => {
        this.alert(e.message);
      },
      onSuccess: (e) => {
        this.alert("Successfully booked your appointment. Please wait for the lawyer to accept your request.", () => {
          UI.goTo("/booking");
        });
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  validate_time = async (e, new_val) => {
    let val = "";
    if (e) {
      val = UI.onChangeTime(e);
    }
    if (new_val) {
      val = new_val;
    }
    if (!this.state.scheduled_at) {
      if (e) {
        e.target.value = "10:00";
      }
      UI.error_form("date_picker", "Please pick a date first.");
      return;
    }
    const date = new Date(this.state.scheduled_at);
    const split = val.split(":");
    const hr = parseInt(split[0]);
    const min = parseInt(split[1]);
    // e.target.value = val;
    date.setHours(hr);
    date.setMinutes(min);
    this.setState({
      scheduled_at: date.toISOString(),
      selected_time: val,
    });
  };

  render() {
    const {
      width,
      data,
      user,
      calendar_days,
      available_schedules,
      schedule_selected,
      schedule_message,
      last_date,
      last_month,
    } = this.state;
    const w = width;

    const item = data;
    return (
      <UserMainLayout _this={this} active_tab={"Booking"}>
        <View
          style={{ width: "100%", height: "200%", backgroundColor: "#F9FAFF", }}
        >
           
          {UI.box(20)}

          <View style={styles.content_container}>
            <View style={styles.content_subcontainer}>
              <View style={{ flexDirection: w < 1080 ? "column" : "row" }}>
                <View style={{ paddingRight: "20px" }}>
                  <Text style={styles.booking_title}>Booking</Text>
                </View>

                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View>
                    <Text style={styles.booking_subtitle}>Booking /</Text>
                  </View>

                  <View>
                    <Text style={styles.booking_subtitle}>Information</Text>
                  </View>
                </View>
              </View>

              {UI.box(10)}

              <View style={{ flexDirection: "row",  }}>
                <View style={{ ...styles.body_container, 
                paddingLeft: w < 1119 ? "15px" : "40px",
                    paddingRight: w < 1119 ? "23px" : "40px",
                    }}>

                  <View style={{ flexDirection: w < 1080 ? "column" : "row", marginLeft: "10px",}}>
                    
                    <View style={{ marginLeft: w < 1119 ? "0px" : "35px",
                     marginRight: w < 1119 ? "40px" : "60px",
                        marginTop: w < 1080 ? "0px" : "10px",
                        }}>

                      <View style={{ paddingBottom: "25px", textAlign: w < 1080 ? "center" : "none",}}>
                        <Text style={styles.titleText}> User Information </Text>
                      </View>

                      <View style={{ flexDirection: "row", paddingBottom: "20px", justifyContent: w < 1080 ? "center" : "none",}}>
                        <View style={{ paddingRight: "15px" }}>
                          <img
                            style={{
                              height: 100,
                              width: 100,
                              borderRadius: 50,
                            }}
                            src={UI.download_link(user.profile_image)}
                          />
                        </View>
                        <View style={{}}>
                          <Text style={styles.user_informationTextFirst}>
                            {" "}
                            {user.first_name} {user.last_name}{" "}
                          </Text>
                          <Text style={styles.userInformation_Textsecond}>
                            {user.address}
                          </Text>
                        </View>
                      </View>

                      <View style={{ width: "100%" }}>
                        <FormHelper _this={this} state={"message"}>
                          <textarea
                            style={{
                              minHeight: "100px",
                              backgroundColor: "#F9FAFF",
                              padding: 10,
                            }}
                            type={"text"}
                            placeholder={"Enter Your Message"}
                            onChange={(e) => {
                              UI.onChangeText(e, "message");
                            }}
                          ></textarea>
                        </FormHelper>
                      </View>
                    </View>

                    <View
                      style={{
                        ...styles.Line1_style,
                        width: w < 1080 ? "100%" : "0px",
                        marginRight: w < 1119 ? "40px" : "50px",
                      }}
                    />

                    <View style={{ 
                      marginRight: w < 1119 ? "30px" : "50px", 
                      marginTop: "10px",

                    }}>
                      <View
                        style={{
                          paddingBottom: "20px",
                          textAlign: w < 1080 ? "center" : "none",
                        }}
                      >
                        <Text style={styles.titleText}> Consultant </Text>
                      </View>

                      <View style={{}}>
                        <View style={{ textAlign: "center" }}>
                          <Text style={styles.consultant_textFirst}>
                            {" "}
                            {item.first_name} {item.last_name}{" "}
                          </Text>
                          <Text style={styles.consultant_textSecond}>
                            {UI.specialization_map[item.specialization_id]}
                          </Text>
                          <Text style={styles.consultant_textSecond}>
                            {item.years_of_experience} Years of Experience |
                            Class of {UI.date_iso_to_year(item.graduated_at)}{" "}
                          </Text>
                        </View>

                        <View
                         style={styles.box_container}>
                          <View
                          style={styles.consultationBox_style}>
                            <View>
                              <Text style={{ paddingLeft: "12px" }}>
                                Consultation
                              </Text>
                              {UI.box(5)}
                              <select
                                onChange={(e) => {
                                  this.setState(
                                    {
                                      consultation_time: e.target.value,
                                    },
                                    () => {
                                      this.get_available_schedule(
                                        this.state.last_month,
                                        this.state.last_date
                                      );
                                    }
                                  );
                                }}
                                style={{ marginLeft: 8 }}
                              >
                                <option value="30">30 min</option>
                                <option value="60">1 hr</option>
                                <option value="90">1 hr & 30 min</option>
                                <option value="120">2 hrs</option>
                                <option value="150">2 hrs & 30 min</option>
                                <option value="180">3 hrs</option>
                                <option value="210">3 hrs & 30 min</option>
                                <option value="240">4 hrs</option>
                              </select>
                            </View>
                            {/*    {UI.box(10)}
                            <img
                              src={UI.CARET_DOWN}
                              style={{ height: 10, width: 10 }}
                            /> */}
                          </View>

                          <View
                          style={styles.moneyBox_style}>
                            <Text style={{ fontSize: "16px" }}>
                              ₱{item.proposed_fee}
                            </Text>
                          </View>
                        </View>


                      <View style={{ flexDirection:"row",   justifyContent: w < 1080 ? "center" : "none"}}>
                        <TouchableOpacity
                          onClick={() => {
                            this.submit();
                          }}
                          style={styles.BookAppointment_Button}>
                          <Text style={styles.Button_Text}>
                            Book Appointment
                          </Text>
                        </TouchableOpacity>

                        <TouchableOpacity 
                        style={styles.Cancel_Button}>
                          <Text style={styles.Button_Text}>
                           Cancel
                          </Text>
                        </TouchableOpacity>
                       </View>

                      </View>
                    </View>

                    <View
                      style={{
                        ...styles.Line2_style,              
                        width: w < 1080 ? "100%" : "0px",
                        marginRight: w < 1119 ? "20px" : "40px",
                      }}
                    />

                    <View style={{ marginTop: "10px", 
                    
                    }}>
                      <View
                        style={{
                          paddingBottom: "20px",
                          textAlign: w < 1080 ? "center" : "none",
                        }}
                      >
                        <Text style={styles.titleText}> Date and Time </Text>
                      </View>

                      <FormHelper _this={this} state={"date_picker"}>
                        <View style={{ alignItems: "center" }}>
                          <ScrollView
                            style={{ width: 300, height: 50 }}
                            horizontal={true}
                          >
                            <View style={{ flexDirection: "row" }}>
                              {MonthMap.map((m) => {
                                return <MonthBox _this={this} text={m} />;
                              })}
                            </View>
                          </ScrollView>

                          <ScrollView
                            style={{ width: 300, height: 50 }}
                            horizontal={true}
                          >
                            <View style={{ flexDirection: "row" }}>
                              {calendar_days.map((c) => {
                                return <NumBox _this={this} text={c + ""} />;
                              })}
                            </View>
                          </ScrollView>
                        </View>
                      </FormHelper>

                      <View style={{ alignItems: "center" }}>
                        <View
                          style={{
                            alignItems: "center",
                            textAlign: "center",
                            width: 110,
                            display: schedule_selected ? "flex" : "none",
                          }}
                        >
                          <Text style={{ marginLeft: -10 }}>Enter Time</Text>
                          <FormHelper _this={this} state={"scheduled_at"}>
                            <View
                            style={styles.timeBox_style}>
                              {/* <Text style={{ color: "#F9FAFF" }}>10:30</Text> */}
                              <input
                                value={this.state.selected_time}
                                type={"time"}
                                style={{ backgroundColor: "transparent" }}
                                onChange={(e) => {
                                  this.validate_time(e);
                                }}
                              />
                            </View>
                          </FormHelper>
                        </View>
                      </View>
                      {UI.box(5)}

                      <View>
                        <Text style={{ textAlign: "center"  }}>Available Schedule</Text>
                        <Text style={{ textAlign: "center" }}>
                        {schedule_message}
                        </Text>
    
                        <View
                         style={styles.scheduleBox_style}>
                          {available_schedules.map((d) => {
                            return <ScheduleBox _this={this} {...d} />;
                          })}
                        </View>
                        
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {UI.box(100)}
          </View>

          {UI.box(20)}
          
        </View>
        </UserMainLayout>
     
    );
  }
}

const ScheduleBox = (props = { text: "" }) => {
  const _this = props._this;
  return (
    <TouchableOpacity
      onClick={() => {
        const split = props.start.split(":");
        const h = UI.pad2(split[0]);
        const m = split[1];
        const time = h + ":" + m;
        _this.validate_time(null, time);
        _this.setState({
          schedule_selected: true,
        });
      }}
      style={{
        padding: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: "solid",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 5,
        paddingBottom: 5,
        marginLeft: 10,
        marginBottom: 10,
      }}
    >
      <Text>
        {props.start} to {props.end}
      </Text>
    </TouchableOpacity>
  );
};

const NumBox = (props = { text: "" }) => {
  const _this = props._this;

  const onClick = () => {
    const date = parseInt(props.text);
    const month = MonthMap.indexOf(_this.state.selected_month);
    const year = new Date().getFullYear();
    const new_date = new Date();
    new_date.setDate(date);
    new_date.setMonth(month);
    new_date.setFullYear(year);
    //Defaults to 10:00am
    new_date.setHours(10);
    new_date.setMinutes(0);
    _this.get_available_schedule(month + 1, date);
    new_date.setSeconds(0);
    _this.setState({
      selected_date: props.text,
      scheduled_at: new_date.toISOString(),
      did_time_update: true,
    });
  };

  const is_active = _this.state.selected_date == props.text;

  return (
    <TouchableOpacity
      onClick={onClick}
      style={{
        height: 40,
        width: 40,
        borderWidth: 1,
        borderStyle: "solid",
        backgroundColor: is_active ? "#16a085" : "#E8E8E8",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text style={{ color: is_active ? "white" : "black" }}>{props.text}</Text>
    </TouchableOpacity>
  );
};

const MonthBox = (props = { text: "" }) => {
  const _this = props._this;
  const is_active = _this.state.selected_month == props.text;
  return (
    <TouchableOpacity
      onClick={() => {
        _this.set_calendar_days(
          MonthMap.indexOf(props.text),
          new Date().getFullYear()
        );
        _this.setState({
          selected_date: "1",
        });
      }}
      style={{
        height: 40,
        width: 40,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: is_active ? "#2ecc71" : "white",
      }}
    >
      <Text
        style={{
          fontSize: "15px",
          fontWeight: "bold",
          color: is_active ? "white" : "black",
        }}
      >
        {props.text}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  },

  content_subcontainer: {
    width: "90%",
    // backgroundColor: "white",
    borderRadius: 5,
    padding: 10,
    paddingTop: 35,
    justifyContent: "center",
    opacity: 1,
  },
  body_container:{
    width: "100%",
    borderRadius: "10px",
    ...Shadow._3(),
    paddingTop: "50px",
    paddingBottom: "50px",
    backgroundColor: "white",
    width:"110%"
  },
  booking_title: {
    fontStyle: "Quicksand",
    color: "#575757",
    fontWeight: "bold",
    fontSize: "35px",
  },
  booking_subtitle: {
    fontSize: "20px",
    color: "#BABBAB",
    fontStyle: "Quicksand",
  },
  user_informationTextFirst: {
    color: "#575757",
    fontStyle: "Quicksand",
    fontSize: "20px",
    fontWeight: "bold",
  },
  userInformation_Textsecond: {
    color: "#BABBAB",
    fontStyle: "Quicksand",
    fontSize: "12px",
  },

  Line1_style:{
    height: "70%",
    backgroundColor: "#575757",
    padding: 1,
    marginTop: "3%",
  },

  Line2_style:{
    height: "70%",
    backgroundColor: "#575757",
    padding: 1,
    marginTop: "3%",
  },

  titleText: {
    color: "#575757",
    fontStyle: "Quicksand",
    fontSize: "20px",
    fontWeight: "bold",
  },
  consultant_textFirst: {
    color: "#575757",
    fontStyle: "Quicksand",
    fontSize: "20px",
  },
  consultant_textSecond: {
    color: "#BABBAB",
    fontStyle: "Quicksand",
    fontSize: "12px",
  },
  moneyBox_style:{
    borderColor: "#707070",
    borderWidth: 1,
    height: "50px",
    width: "120px",
    alignItems: "center",
    borderRadius: "5px",
    justifyContent: "center",
  },
  box_container:{
    flexDirection: "row",
    paddingTop: "25px",
    paddingBottom: "20px",
    alignSelf: "center",
  },
  consultationBox_style:{
    borderColor: "#707070",
    borderWidth: 1,
    marginRight: "10px",
    height: "50px",
    width: "120px",
    borderRadius: "5px",
    paddingTop: "4px",
    flexDirection: "row",
    alignItems: "center",
    minWidth: 130,
    height: 70,
  },
  BookAppointment_Button: {
    width: 160,
    alignSelf: "center",
    backgroundColor: "#0F4059",
    height: "50px",
    justifyContent: "center",
    textAlign: "center", 
    marginRight:"15px",
    borderRadius:"5px",
  },
  Cancel_Button:{
    width: 160,
    alignSelf: "center",
    backgroundColor: "#F14336",
    height: "50px",
    justifyContent: "center",
    textAlign: "center",
    borderRadius:"5px",
  },
  Button_Text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
  },
  timeBox_style:{
    borderColor: "#dcddd5",
    borderWidth: 1,
    height: "35px",
    borderRadius: "5px",
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
  },
  scheduleBox_style:{
    flexDirection: "row", 
    flexWrap: "wrap", 
    alignItems:"center",
    marginRight:"10px", 
    justifyContent: "center",
  }
});

import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
} from "../../shared/custom-react-native";

import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";

export default class AdminTest extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(this, {
      //Initial State Here
      // isOnChat: false,
    });
  }

  render() {
    const { width, isMobile } = this.state;
    const w = width;
    const active_tab = this.props.active_tab;

    if (isMobile) {
      //codes for mobile
      return (
        <View
          style={{ width: "100%", backgroundColor: "#F9FAFF", height: "100%" }}
        >
          <View style={{}}>
            <View style={{ paddingLeft: 40 }}>
              <img style={styles.login_banner} src={UI.LOGIN_BANNER} />
            </View>
          </View>

          <View style={{ paddingLeft: "10%", paddingTop: "8%" }}>
            <TouchableOpacity style={{ paddingBottom: 10 }}>
              <Text style={styles.mobNavbar_text}> HOME </Text>
            </TouchableOpacity>

            <TouchableOpacity style={{ paddingBottom: 10 }}>
              <Text style={styles.mobNavbar_text}> Calendar </Text>
            </TouchableOpacity>

            <TouchableOpacity style={{ paddingBottom: 10 }}>
              <Text style={styles.mobNavbar_text}> Booking </Text>
            </TouchableOpacity>

            <TouchableOpacity style={{ paddingBottom: 10 }}>
              <Text style={styles.mobNavbar_text}> Notification </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else {
      //codes for web

        
    return (
        <View style={styles.main_container}>
          
             {/* HEADER */}
           <View>
           
             <View style={styles.header_container}>
             
                   <View style={{ paddingLeft:60 }}>
                       <img style={styles.login_banner}  src={UI.LOGIN_BANNER}/>
                   </View>
                       
                   <View style={{ flexDirection: "row", alignItems: "right", }}>
                       <View style={{ flexDirection: "row", paddingTop:40, paddingRight:80}}> 
                           <TouchableOpacity style={{ paddingRight:25}} onClick={()=>{
                             UI.goTo("/");
                           }}>
                                <Text style={{...styles.header_txt, color: active_tab=="Home" ? "#F14336" : "#575757"}}>Home</Text>
                                <View style={{height: 3, backgroundColor: "#F14336", width: 40, alignSelf: "center", opacity: active_tab=="Home" ? 1 : 0 }}></View>
                           </TouchableOpacity>
                           <TouchableOpacity style={{ paddingRight:25}} >
                                <Text style={styles.header_txt}>Calendar</Text>
                           </TouchableOpacity>
                           <TouchableOpacity style={{ paddingRight:25}} onClick={()=>{
                             UI.goTo("/admin/booking");
                           }}> 
                                <Text style={{...styles.header_txt, color: active_tab=="Booking" ? "#F14336" : "#575757"}}>Booking</Text>
                                <View style={{height: 3, backgroundColor: "#F14336", width: 40, alignSelf: "center", opacity: active_tab=="Booking" ? 1 : 0 }}></View>
                           </TouchableOpacity>
                           <TouchableOpacity>
                                <Text style={styles.header_txt}>Notifications</Text>
                           </TouchableOpacity>
                       </View>  
 
                       <View style={styles.profile_container} > 
                           <TouchableOpacity style={{ paddingRight:20 }}>
                              <img style={styles.profile_img}  src={UI.JOSHUA}/>
                            </TouchableOpacity>
                       </View>  
 
 
                       <TouchableOpacity style={styles.signout_btn} onClick={()=>{
                             UI.goTo("/admin/sign_in");
                           }}>                         
                         <View style={{flexDirection: "row",  paddingRight:10  }}>
                            <img style={{ height: 25, }}  src={UI.MAIN_LINE_3}/>
                              <View style={{ paddingTop:2}}>
                               <img style={{ height: 20, width: 20, }}  src={UI.MAIN_ARROW_RIGHT}/>
                             </View>
                         </View>
                            <Text style={styles.signout_txt}> Sign Out</Text>
                       </TouchableOpacity>  
                   </View>  
                                 
              </View>
             
               <View style={styles.break_line}> 
                 
               </View>
               
                 <View>
                   {this.props.children}
                 </View>
         </View> 
 
             <View style = {styles.footer_line}>
                       <Text style = {styles.footer_txt}> 2021 All Rights Reserved </Text>
             </View> 
       </View>
      );
    }
  }
}

const styles = StyleSheet.create({
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  },

  content_subcontainer: {
    width: "90%", /// width for outside
    // backgroundColor: "white",
    borderRadius: 5,
    padding: 20, /// width for inside
    paddingTop: 35,
    justifyContent: "center",
    opacity: 1,
  },

  // footer_container:{
  //   width: '100%',
  //   backgroundColor: "#171717",
  //   alignItems: "center",
  // },
  //    footer_text:{
  //   color:"#FFFFFF",
  //   padding:10,
  //   fontStyle:"Quicksand",
  //   fontWeight:"bold",
  //   fontSize:"15px"
  //  },

  navbar_text: {
    FontStyle: "Quicksand",
    fontSize: "25px",
    fontWeight: "bold",
    color: "#575757",
  },
  mobNavbar_text: {
    FontStyle: "Quicksand",
    fontSize: "25px",
    fontWeight: "bold",
    color: "#575757",
  },

  search_text: {
    color: "#FFFFFF",
    padding: 10,
    fontStyle: "Quicksand",
    fontWeight: "bold",
    fontSize: "15px",
  },

  login_banner: {
    height: 150,
    width: 300,
  },
  border: {
    width: "100%",
    height: "60px",
    backgroundColor: "#0F4059",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  borderMini: {
    width: "100%",
    height: "20px",
    backgroundColor: "#F26E50",
  },

  header_container: {
    width: "100%",
    backgroundColor: "#F9FAFF",
    borderRadius: 5,
    alignItems:"center",
    paddingBottom: 20,
  },

  main_container: {
    width: "100%",
    backgroundColor: "#F9FAFF",
  },
  login_banner: {              
    height: 150 * .8,
    width:  300 * .8,
  },
    
  break_line: {
    height: 27, 
    width: "100%", 
    backgroundColor: "#0F4059",
  },
    
  navbar_text:{      
    color: "black", 
    fontSize: 14,
    fontWeight: "bold",
  },
  header_container: {     
    width: "100%",
    backgroundColor: "#F9FAFF",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  header_txt: { 
    fontSize:"25px", 
    fontWeight:"bold", 
    color:"#575757" ,
  },
  main_container: {     
    width: "100%",
    height: 50, 
    backgroundColor: "#F9FAFF",
  },
  profile_container: { 
    flexDirection: "row", 
    paddingTop:35, 
    paddingRight:35,
  },
  profile_img: { 
    height: 60, 
    width: 50, 
    borderRadius: 50, 
  },
  signout_btn: { 
    flexDirection: "row", 
    paddingTop:50,  
    paddingRight:50, 
  },
  signout_txt: { 
    FontFace:"Quicksand", 
    color:"#F26E50", 
    fontWeight:"bold", 
  },
  footer_line: {
    height: 50, 
    width: "100%", 
    backgroundColor: "#171717",
    
  },
  footer_txt: {
    color: "white", 
    fontSize: 23, 
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: 10,
  
  },
});

/*

For Chatting


  */

// render() {
//   const { width, isMobile } = this.state;
//   const w = width;

//   return (
//     <MainLayout>

//       <View style = {{height: 500, width: "100%"}}>

//       </View>

//     </MainLayout>
//   );
// }

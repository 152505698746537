import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  Wrap,
  r,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";

export default class AdminBookingHistory extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
        //Initial State Here

        consult_type: "all", //all, pending, previous

        pages_array: [],
        number_of_pages: 1,
        current_page: 1,
        entries: 4,
        data: [],
      },
      {
        role: "lawyer",
      }
    );
  }

  onCreate = async () => {
    this.load_data();
  };
  load_data = async () => {
    this.get_consultations(1);
  };

  get_consultations = async (page) => {
    this.show_loading();
    let additional = "";
    if (this.state.consult_type == "pending") {
      additional = "/pending";
    }
    if (this.state.consult_type == "previous") {
      additional = "/previous";
    }
    r.request({
      method: "get",
      url: "/lawyer/bookings" + additional,
      params: {
        entries: this.state.entries,
        page: page,
      },
      onFail: (e) => {},
      onSuccess: (e) => {
        const pages = [];
        for (let i = 0; i < e.number_of_pages; i++) {
          pages.push(i + 1);
        }
        this.setState({
          data: e.data,
          number_of_pages: e.number_of_pages,
          pages_array: e.total ? pages : [],
          current_page: e.current_page,
        });
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  cancel_consultation = async (id) => {
    this.show_loading();

    r.request({
      method: "delete",
      url: "/booking/cancel/" + id,
      params: {},
      onFail: (e) => {
        this.alert(e.message);
      },
      onSuccess: (e) => {
        this.get_consultations(this.state.current_page);
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  accept_consultation = async (id) => {
    this.show_loading();

    r.request({
      method: "put",
      url: "/booking/accept/" + id,
      params: {},
      onFail: (e) => {
        this.alert(e.message);
      },
      onSuccess: (e) => {
        this.get_consultations(this.state.current_page);
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  go_to_encounter = async (consultation_id) => {
    this.show_loading();

    r.request({
      method: "get",
      url: "/lawyer/get/link/" + consultation_id,
      params: {},
      onFail: (e) => {
        this.alert(e.message);
      },
      onSuccess: (e) => {
        window.open(e.link, '_blank').focus();
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const { width, isMobile, data, consult_type } = this.state;
    const horizontal_padding = width * 0.005;
    const w = width;
    let columns = 4;
    let minus = 180;
    if (width < 1110) {
      minus = 250;
      columns = 3;
    }
    if (width < 920) {
      minus = 180;
      columns = 2;
    }
    if (width < 660) {
      minus = 80;
      columns = 1;
    }
    const itemwidth = (w - minus) / columns - (horizontal_padding / 2 + 10);

    const Data = data.map((obj) => {
      const item = obj.client;
      const date = UI.timestampToDate(new Date(obj.scheduled_at).getTime());

      let Element = View;
      let onClick = () => {};
      if (consult_type == "pending") {
        Element = TouchableOpacity;
        onClick = () => {
          //Create encounter link
        };
      }
     

    
      

      return (
        <View style={{ ...styles.profile1_container, width: itemwidth }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 15,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <View>
              <img
                style={styles.profile_img}
                src={UI.download_link(item.profile_image)}
              />
            </View>
            <View style={{}}>
              <Text style={styles.day_txt}>{date.day}</Text>
              <Text style={styles.month_txt}>{date.month_string}</Text>
            </View>
          </View>

          <Text style={styles.profile_name}>
            {item.first_name} {item.last_name}
          </Text>

          <View
            style={{ alignSelf: "center", marginLeft: 25, marginRight: 20 }}
          >
            <Text style={{ color: "#0F4059", fontSize: 14 }}>
              {item.address}
            </Text>
            {UI.box(25)}
            <Text style={{ color: "#575757", fontSize: 14 }}>Schedule</Text>
            <Text style={{ color: "#F26E50", fontSize: 14 }}>
              {date.day_week_string_3}, {date.month_string} {date.day},{" "}
              {date.year} | {date.h_m} {date.am_pm}
            </Text>
            {UI.box(10)}

            <Text style={{ color: "#575757", fontSize: 14 }}>
              Message: <br /> {obj.message}
            </Text>
            {UI.box(15)}
            <View
              style={{
                ...styles.signup_container,
                display: consult_type == "pending" ? "none" : "flex",
              }}
            >
              <View style={{ paddingRight: 26 }}>
                <TouchableOpacity
                  onClick={() => {
                    this.cancel_consultation(obj.id);
                  }}
                  style={styles.cancel_button}
                >
                  <Text
                    style={{
                      color: "#0F4C59",
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
              </View>

              <TouchableOpacity
                onClick={() => {
                  this.accept_consultation(obj.id);
                }}
                style={styles.register_button}
              >
                <Text
                  style={{
                    color: "white",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Accept
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                ...styles.signup_container,
                display: consult_type == "pending" ? "flex" : "none",
              }}
            >
              <TouchableOpacity
                onClick={() => {
                  this.go_to_encounter(obj.id);
                }}  
                style={{ ...styles.register_button, width: 200 }}
              >
                <Text
                  style={{
                    color: "white",
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  Go to Encounter
                </Text>
              </TouchableOpacity>
            </View>
          </View>

          <img
            style={{ height: 16, width: "100%", marginTop: 20 }}
            src={UI.BOX_BOTTOM}
          />
        </View>

        
      );
    });
    let active_tab = this.props.active_tab;
    let active_tabs = this.props.active_tabs;
    return (
      <MainLayout _this={this} active_tab={"Booking"}>
        <View
          style={{
            ...styles.main_container,
            paddingLeft: horizontal_padding,
            paddingRight: horizontal_padding,
          }}
        >
          <View style={styles.content_container}>
            <View style={styles.content_subcontainer}>

            <Text style={styles.booking_txt}>Booking History</Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: w < 400 ? "space-around" : "space-between",
              paddingRight: w < 470 ? 0 : 30,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                //marginLeft: 50,
                paddingBottom: 20,
                marginRight: 20,
    
              }}
            
            >
              <TouchableOpacity 
                    
                      onClick={() => {
                        UI.goTo("/admin/booking");
                      }}
                      
                    >
                     
                      <Text
                        style={{
                          fontSize: 16 ,
                          color:
                            active_tabs == "History" ? "#F14336" : "#575757",
                            textDecoration: "underline #F26E50 5px",
                            
                        }}
                      >
                        History
                      </Text>
                      
                    </TouchableOpacity>

              {/* <TouchableOpacity
                onClick={() => {
                  UI.goTo("/admin/booking");
                }}
              >
                <Text style={{ fontSize: 16 }}> History</Text>
              </TouchableOpacity> */}


               <TouchableOpacity
               onClick={() => {
                UI.goTo("/admin/booking-removal");
              }}
              >
                <Text style={{ marginLeft: 20, fontSize: 16 }}> Removal</Text>
              </TouchableOpacity> 

              <TouchableOpacity
               onClick={() => {
                UI.goTo("/admin/booking-completion");
              }}
              >
                <Text style={{ marginLeft: 20, fontSize: 16 }}> Completion</Text>
              </TouchableOpacity>
            </View>
            {/*       <View style={{ alignItems: "right", paddingRight: 30 }}>
              <TouchableOpacity
                style={{
                  backgroundColor: "#F26E50",
                  width: 120,
                  borderRadius: 8,
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <Text
                  style={{
                    color: "#FFFFFF",
                    fontSize: 16,
                    fontWeight: "bold",
                    paddingTop: 2,
                    textAlign: "center",
                  }}
                >
                  Filter By
                </Text>
                <img
                  style={{ height: 20, width: 20, marginTop: 5 }}
                  src={UI.FILTER_SLIDERS}
                />
              </TouchableOpacity>
            </View> */}
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: w < 400 ? "space-around" : "space-between",
              paddingRight: w < 470 ? 0 : 30,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                //marginLeft: 50,
                paddingBottom: 20,
                marginRight: 20,
              }}
            >
              <TouchableOpacity
                onClick={() => {
                  this.setState(
                    {
                      consult_type: "all",
                    },
                    () => {
                      this.get_consultations(1);
                    }
                  );
                }}
              >
                 <Text
                        style={{
                          fontSize: 16 ,
                          color:
                            active_tab == "All" ? "#F14336" : "#575757",
                            textDecoration: "underline #F26E50 3px",
                            
                        }}
                      >
                        All
                      </Text>
              </TouchableOpacity>

              <TouchableOpacity
                onClick={() => {
                  this.setState(
                    {
                      consult_type: "pending",
                    },
                    () => {
                      this.get_consultations(1);
                    }
                  );
                }}
              >
                <Text style={{ marginLeft: 20, fontSize: 16 }}> Pending</Text>
              </TouchableOpacity>

              <TouchableOpacity
                onClick={() => {
                  this.setState(
                    {
                      consult_type: "previous",
                    },
                    () => {
                      this.get_consultations(1);
                    }
                  );
                }}
              >
                <Text style={{ marginLeft: 20, fontSize: 16 }}> Previous</Text>
              </TouchableOpacity>
            </View>
                 <View style={{ alignItems: "right", paddingRight: 30 }}>
              <TouchableOpacity
                style={{
                  backgroundColor: "#F26E50",
                  width: 120,
                  borderRadius: 8,
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <Text
                  style={{
                    color: "#FFFFFF",
                    fontSize: 16,
                    fontWeight: "bold",
                    paddingTop: 2,
                    textAlign: "center",
                  }}
                >
                  Filter By
                </Text>
                <img
                  style={{ height: 20, width: 20, marginTop: 5 }}
                  src={UI.FILTER_SLIDERS}
                />
              </TouchableOpacity>
            </View> 
          </View>

          {/* <View style={styles.inside_container}>
            {Data}
            {data.length == 0 && (
              <Text
                style={{
                  marginTop: 10,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                No Data Available
              </Text>
            )}
          </View>
          <View
            style={{
              flexDirection: "row",
              marginTop: "30px",
              paddingLeft: horizontal_padding,
              paddingRight: horizontal_padding,
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            {this.state.pages_array.map((i) => {
              let default_style = {
                backgroundColor: "#F26E50",
                color: "#FFFFFF",
              };
              if (this.state.current_page != i) {
                default_style = {
                  backgroundColor: "#FFFFFF",
                  color: "#F26E50",
                };
              }

              return (
                <TouchableOpacity
                  onClick={() => {
                    this.get_consultations(i);
                  }}
                  style={{
                    borderRadius: 5,
                    borderWidth: 1,
                    marginRight: "20px",
                    padding: "7px",
                    width: "50px",
                    textAlign: "center",
                    backgroundColor: default_style.backgroundColor,
                  }}
                >
                  <Text
                    style={{ fontSize: "20px", color: default_style.color }}
                  >
                    {" "}
                    {i}{" "}
                  </Text>
                </TouchableOpacity>
              );
            })}
          </View>

          {UI.box(30)} */}
<View style={styles.consultation_container}>
                {/* FIRST */}

                <View
                  style={{
                    width: itemwidth,
                    heigt: "100%",
                    borderRadius: "10px",
                    ...Shadow._4(),
                    marginRight: "20px",
                    marginTop: "20px",
                    alignItems: w < 730 ? "center" : "none",
                    backgroundColor: "#FFFFFF",
                    
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingLeft: 13,
                      paddingRight: w < 365 ? 35 : 13,
                      paddingTop: 20,
                    
                    }}
                  >
                    <View style={{ marginRight: w < 780 ? "30px" : "0px" }}>
                      <img style={styles.profile_img} src={UI.ENRICO} />
                    </View>

                    <View style={{}}>
                      <Text style={styles.profile_DateText}> 10 </Text>
                      <Text style={styles.profile_MonthText}>March</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                    <Text style={styles.profile_Textfirst}>
                      {" "}
                      Enrico Juliano{" "}
                    </Text>
                    <Text style ={{color: "#0F4059"}}> 270 Robles Manggahan, Quezon City </Text>
                  </View>

                  <View
                    style={{
                      paddingLeft: w < 730 ? 35 : 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                   

                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Schedule
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Wed, March 10, 2021 | 10:30 am
                      </Text>
                      
                      {UI.box(15)}

                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Message: <br/> I'm looking for a lawyer that can help me about Finance
                      </Text>
                      {UI.box(15)}
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Enter Zoom Link
                      </Text>
                      <InputFormName type={"text"} placeholder={"Input Link Here"} />

                    </View>
                            {UI.box(20)}
                            <UI.Row _this={this} breakpoint={800}>
                 

                 <View style={{ flexDirection: "row" }}>
              
             </View>

             <View style={{ paddingRight: 26 }}>
                   <TouchableOpacity
                     onClick={() => {
                     
                     }}
                     style={styles.cancel_button}
                   >
                     <Text
                       style={styles.cancel_txt}
                     >
                       Cancel
                     </Text>
                   </TouchableOpacity>
                 </View>
                 <TouchableOpacity
                 
                 style={styles.register_button}
               >
                 <Text style={styles.register_txt}>Confirm</Text>
               </TouchableOpacity>
                 </UI.Row>
                  </View>
                  {UI.box(10)}
                  <View style={styles.borderMini} />
                </View>

                {/* SECOND
                 */}

<View
                  style={{
                    width: itemwidth,
                    heigt: "100%",
                    borderRadius: "10px",
                    ...Shadow._4(),
                    marginRight: "20px",
                    marginTop: "20px",
                    alignItems: w < 730 ? "center" : "none",
                    backgroundColor: "#FFFFFF",
                    
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingLeft: 13,
                      paddingRight: w < 365 ? 35 : 13,
                      paddingTop: 20,
                    
                    }}
                  >
                    <View style={{ marginRight: w < 780 ? "30px" : "0px" }}>
                      <img style={styles.profile_img} src={UI.CHRISTIAN} />
                    </View>

                    <View style={{}}>
                      <Text style={styles.profile_DateText}> 26 </Text>
                      <Text style={styles.profile_MonthText}>March</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                    <Text style={styles.profile_Textfirst}>
                      {" "}
                      Christian Tolentino {" "}
                    </Text>
                    <Text style ={{color: "#0F4059"}}> Parkwood Maybunga, Pasig City </Text>
                  </View>

                  <View
                    style={{
                      paddingLeft: w < 730 ? 35 : 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                   

                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Schedule
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                       Fri, March 26, 2021 | 10:30 am
                      </Text>
                      
                      {UI.box(15)}

                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Message: <br/> I'm looking for a lawyer that can help me about Finance
                      </Text>
                      {UI.box(15)}
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Enter Zoom Link
                      </Text>
                      <InputFormName type={"text"} placeholder={"Input Link Here"} />

                    </View>
                            {UI.box(20)}
                            <UI.Row _this={this} breakpoint={800}>
                 

                 <View style={{ flexDirection: "row" }}>
              
             </View>

             <View style={{ paddingRight: 26 }}>
                   <TouchableOpacity
                     onClick={() => {
                     
                     }}
                     style={styles.cancel_button}
                   >
                     <Text
                       style={styles.cancel_txt}
                     >
                       Cancel
                     </Text>
                   </TouchableOpacity>
                 </View>
                 <TouchableOpacity
                 
                 style={styles.register_button}
               >
                 <Text style={styles.register_txt}>Confirm</Text>
               </TouchableOpacity>
                 </UI.Row>
                  </View>
                  {UI.box(10)}
                  <View style={styles.borderMini} />
                </View>

                {/* Third */}

                <View
                  style={{
                    width: itemwidth,
                    heigt: "100%",
                    borderRadius: "10px",
                    ...Shadow._4(),
                    marginRight: "20px",
                    marginTop: "20px",
                    alignItems: w < 730 ? "center" : "none",
                    backgroundColor: "#FFFFFF",
                    
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingLeft: 13,
                      paddingRight: w < 365 ? 35 : 13,
                      paddingTop: 20,
                    
                    }}
                  >
                    <View style={{ marginRight: w < 780 ? "30px" : "0px" }}>
                      <img style={styles.profile_img} src={UI.AMANDA} />
                    </View>

                    <View style={{}}>
                      <Text style={styles.profile_DateText}> 31 </Text>
                      <Text style={styles.profile_MonthText}>March</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                    <Text style={styles.profile_Textfirst}>
                      {" "}
                      Amanda Clark{" "}
                    </Text>
                    <Text style ={{color: "#0F4059"}}> Kalachuchi St. Greenpark Vill. Cainta, Rizal </Text>
                  </View>

                  <View
                    style={{
                      paddingLeft: w < 730 ? 35 : 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                   

                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Schedule
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                       Wed, March 31, 2021 | 10:30 am
                      </Text>
                      
                      {UI.box(15)}

                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Message: <br/> I'm looking for a lawyer that can help me about Finance
                      </Text>
                      {UI.box(15)}
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Enter Zoom Link
                      </Text>
                      <InputFormName type={"text"} placeholder={"Input Link Here"} />

                    </View>
                            {UI.box(20)}
                            <UI.Row _this={this} breakpoint={800}>
                 

                 <View style={{ flexDirection: "row" }}>
              
             </View>

             <View style={{ paddingRight: 26 }}>
                   <TouchableOpacity
                     onClick={() => {
                     
                     }}
                     style={styles.cancel_button}
                   >
                     <Text
                       style={styles.cancel_txt}
                     >
                       Cancel
                     </Text>
                   </TouchableOpacity>
                 </View>
                 <TouchableOpacity
                 
                 style={styles.register_button}
               >
                 <Text style={styles.register_txt}>Confirm</Text>
               </TouchableOpacity>
                 </UI.Row>
                  </View>
                  {UI.box(10)}
                  <View style={styles.borderMini} />
                </View>

                {/* Fourth */}

                <View
                  style={{
                    width: itemwidth,
                    heigt: "100%",
                    borderRadius: "10px",
                    ...Shadow._4(),
                    marginRight: "20px",
                    marginTop: "20px",
                    alignItems: w < 730 ? "center" : "none",
                    backgroundColor: "#FFFFFF",
                   
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingLeft: 13,
                      paddingRight: w < 365 ? 35 : 13,
                      paddingTop: 20,
                    
                    }}
                  >
                    <View style={{ marginRight: w < 780 ? "30px" : "0px" }}>
                      <img style={styles.profile_img} src={UI.CHRISTIAN} />
                    </View>

                    <View style={{}}>
                      <Text style={styles.profile_DateText}> 26 </Text>
                      <Text style={styles.profile_MonthText}>March</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                    <Text style={styles.profile_Textfirst}>
                      {" "}
                      Christian Tolentino{" "}
                    </Text>
                    <Text style ={{color: "#0F4059"}}> Parkwood Maybunga, Pasig City </Text>
                  </View>

                  <View
                    style={{
                      paddingLeft: w < 730 ? 35 : 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                   

                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Schedule
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Fri, March 26, 2021 | 10:30 am
                      </Text>
                      
                      {UI.box(15)}

                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Message: <br/> I'm looking for a lawyer that can help me about Finance
                      </Text>
                      {UI.box(15)}
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Enter Zoom Link
                      </Text>
                      <InputFormName type={"text"} placeholder={"Input Link Here"} />

                    </View>
                            {UI.box(20)}
                            <UI.Row _this={this} breakpoint={800}>
                 

                 <View style={{ flexDirection: "row" }}>
              
             </View>

             <View style={{ paddingRight: 26 }}>
                   <TouchableOpacity
                     onClick={() => {
                     
                     }}
                     style={styles.cancel_button}
                   >
                     <Text
                       style={styles.cancel_txt}
                     >
                       Cancel
                     </Text>
                   </TouchableOpacity>
                 </View>
                 <TouchableOpacity
                 
                 style={styles.register_button}
               >
                 <Text style={styles.register_txt}>Confirm</Text>
               </TouchableOpacity>
                 </UI.Row>
                  </View>
                  {UI.box(10)}
                  <View style={styles.borderMini} />
                </View>
              </View>

              <View
                style={{
                  width: "auto",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                <TouchableOpacity
                  style={{
                    borderRadius: 5,
                    borderWidth: 1,
                    backgroundColor: "#F26E50",
                    marginRight: "20px",
                    padding: "7px",
                    width: "50px",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", color: "#FFFFFF" }}>
                    {" "}
                    1{" "}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    borderRadius: 5,
                    marginRight: "20px",
                    padding: "7px",
                    width: "50px",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", color: "#F26E50" }}>
                    {" "}
                    2{" "}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    borderRadius: 5,
                    marginRight: "20px",
                    padding: "7px",
                    width: "50px",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", color: "#F26E50" }}>
                    {" "}
                    3{" "}
                  </Text>
                </TouchableOpacity>
              </View>

              
              </View>
            </View>
          {UI.box(20)}
        </View>
        
      </MainLayout>
    );
    
  }
  
}
const InputFormName = (props = { placeholder: "", type: "" }) => {
  return (
    <View style={styles.input_container}>
      <input
        style={styles.input_style}
        type={props.type}
        placeholder={props.placeholder}
        onChange={(e) => {
            UI.onChangeTextOnly(e, props.state);                  
        }}
      />
    </View>
  );
};
const styles = StyleSheet.create({
  main_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#f3f3f3",
  },
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  },

  content_subcontainer: {
    width: "auto",
    borderRadius: 5,
    padding: 10, /// width for inside
    //paddingLeft: 55,
    justifyContent: "center",
    opacity: 1,
  },
  booking_txt: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#575757",
    paddingTop: 30,
    paddingBottom: 10,
  },
  inside_container: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingLeft: 50,
    paddingRight: 50,
  },
  profile1_container: {
    marginTop: 20,
    marginRight: 20,
    alignSelf: "flex-start",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    minWidth: 275,
    ...Shadow._4(),
  },

  profile_img: {
    height: 100,
    width: 100,
    borderRadius: 50,
  },
  day_txt: {
    fontSize: 35,
    fontWeight: "bold",
    color: "#F26E50",
  },
  month_txt: {
    fontSize: 12,
    color: "#F26E50",
  },
  profile_name: {
    fontSize: 28,
    color: "#575757",
    marginLeft: 23,
    marginTop: "-15px",
  },
  input_style: {
    padding: 10,
    paddingRight: 5,
    paddingLeft: 5,
    fontSize: 10,
    backgroundColor: "#F9FAFF",
  },
  input_container: {
    width: 230,
  },
  signup_container: {
    flexDirection: "row",
  },
  cancel_button: {
    width: 100,
    height: 39,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderWidth: 2,
    borderRadius: 10,
    borderStyle: "solid",
    borderColor: "#0F4059",
  },
  cancel_txt: {
    color: "#0F4059",
    fontSize: 20,
    
  },
  register_button: {
    width: 100,
    height: 39,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0F4059",
    borderRadius: 10,
  },
  register_txt: {
    color: "white",
    fontSize: 20,
    
  },
  consultation_container: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  profile_img: {
    height: 100,
    width: 90,
    borderRadius: 50,
  },
  profile_DateText: {
    fontStyle: "#F26E50",
    fontSize: "50px",
    color: "#F26E50",
    marginTop: -15,
  },
  profile_MonthText: {
    fontStyle: "#F26E50",
    fontSize: "25px",
    color: "#F26E50",
  },

  profile_Textfirst: {
    color: "#575757",
    fontStyle: "Quicksand",
    fontSize: "25px",
  },

  
  

  borderMini: {
    width: "100%",
    height: "20px",
    backgroundColor: "#F26E50",
    borderRadius: "0px 0px 10px 10px",
  },
});

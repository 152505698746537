import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
} from "../../shared/custom-react-native";

import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";

export default class AdminMessages extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   is_tab_opened: false,
    // };
    UI.initiateView(
      this,
      {
        //Initial State Here
        // signin_string: "Hello world",
        // icon: UI.LOGIN_BANNER,
      },
      {
        role: "lawyer",
      }
    );
  }

  // toggle_tab = () => {
  //   this.setState({
  //     is_tab_opened: !this.state.is_tab_opened
  //   }, () => {

  //     if (this.state.is_tab_opened) {
  //       document.body.style.overflow = "hidden";
  //     } else {
  //       document.body.style.overflow = "auto";
  //     }

  //   } )
  // }

  render() {
    const _this = this.props._this;
    const { width } = this.state;
    const w = width;

    let tab_style = { position: "relative", display: "none" };
    if (this.state.is_tab_opened) {
      tab_style = { position: "absolute", top: 341, left: 550, width: "auto" };
    }

    return (
        <MainLayout _this={this} message_active_tab={UI.ON_CLICKED_MESSAGES}>
      
        <View
          style={{ width: "100%", height: "200%", backgroundColor: "#F9FAFF" }}
        >
          {UI.box(20)}

          <View style={styles.content_container}>
            <View style={styles.content_subcontainer}>
              <View style={{ flexDirection: w < 1080 ? "column" : "row" }}>
                <View style={{ paddingRight: "20px" }}>
                  <Text style={styles.booking_title}>Messages</Text>
                </View>

                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TouchableOpacity>
                    <Text style={styles.booking_subtitle}>Message /</Text>
                  </TouchableOpacity>

                  <TouchableOpacity>
                    <Text style={styles.booking_subtitle}>Joshua Bames</Text>
                  </TouchableOpacity>
                </View>
              </View>

              {UI.box(40)}

              <View
                style={{
                  flexDirection: "row",
                  backgroundColor: "#FFFFFF",
                  borderRadius: 10,
                  ...Shadow._3(),
                }}
              >
                <View style={{ width: "40%", paddingLeft: "10px" }}>
                  <View
                    style={{
                      width: "80%",
                      marginLeft: "43px",
                      marginTop: "125px",
                      backgroundColor: "#dcddd5",
                      padding: 1,
                    }}
                  />

                  <View
                    style={{
                      marginTop: "33px",
                      marginLeft: w < 500 ? "10px" : "33px",
                      flexDirection: w < 925 ? "column" : "row",
                      marginBottom: "25px",
                    }}
                  >
                    <View
                      style={{
                        paddingRight: "28px",
                        paddingLeft: w < 925 ? "20px" : "0px",
                      }}
                    >
                      <img
                        style={{ height: 60, width: 60, borderRadius: 100 }}
                        src={UI.CARMEN}
                      />
                    </View>
                    <View
                      style={{
                        paddingTop: "14px",
                        paddingLeft: w < 600 ? "20px" : "0px",
                      }}
                    >
                      <Text style={{ fontSize: "25px" }}> Eric Juliano </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      marginLeft: "23px",
                      marginRight: "28px",
                      flexDirection: "row",
                      padding: "15px",
                      justifyContent: "space-between",
                    }}
                  >
                    <TouchableOpacity style={{}}>
                      <Text style={{ fontSize: "20px" }}> Active Chats </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        paddingTop: "5px",
                        display: w < 925 ? "none" : "",
                      }}
                    >
                      <img style={{ height: 20, width: 20 }} src={UI.Plus} />
                    </TouchableOpacity>
                  </View>

                  <ScrollView
                    style={{
                      width: 400,
                      height: 800,
                      borderStyle: "solid",
                      borderWidth: 1,
                      marginLeft: "32px",
                    }}
                  >
                    <View style={{ flexDirection: "column" }}>
                      <View
                        style={{
                          marginLeft: "23px",
                          marginTop: "25px",
                          height: "50px",
                          width: 350,
                          backgroundColor: "#f9fafc",
                          borderColor: "#dcddd5",
                          borderWidth: 2,
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <Text
                            style={{
                              paddingTop: 8,
                              fontSize: "20px",
                              paddingRight: 170,
                              color: "#dcddd5",
                            }}
                          >
                            Search People
                          </Text>

                          <View style={{ paddingTop: 15 }}>
                            <img
                              style={{ height: 20, width: 20 }}
                              src={UI.Grey}
                            />
                          </View>
                        </TouchableOpacity>
                      </View>

                      <TouchableOpacity
                        style={{
                          paddingTop: "15px",
                          flexDirection: "row",
                          paddingBottom: "15px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          justifyContent: "space-around",
                        }}
                      >
                        <View style={{}}>
                          <img
                            style={{ height: 50, width: 50, borderRadius: 100 }}
                            src={UI.CARMEN}
                          />
                        </View>
                        <View style={{}}>
                          <Text
                            style={{ fontSize: "18px", fontStyle: "Quicksand" }}
                          >
                            {" "}
                            Carmena Barnes{" "}
                          </Text>
                          <Text
                            style={{
                              fontSize: "14px",
                              fontStyle: "Quicksand",
                              color: "#949494",
                            }}
                          >
                            {" "}
                            Me: What does this dummy...{" "}
                          </Text>
                        </View>
                        <View style={{ paddingTop: "5px" }}>
                          <img
                            style={{ height: 40, width: 40 }}
                            src={UI.Clock}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onClick={this.toggle_tab}
                        style={{
                          paddingTop: "15px",
                          flexDirection: "row",
                          paddingBottom: "15px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          justifyContent: "space-around",
                        }}
                      >
                        <View style={{}}>
                          <img
                            style={{ height: 50, width: 50, borderRadius: 100 }}
                            src={UI.AMANDA}
                          />
                        </View>
                        <View style={{}}>
                          <Text
                            style={{ fontSize: "18px", fontStyle: "Quicksand" }}
                          >
                            {" "}
                            Sasha Cohen{" "}
                          </Text>
                          <Text
                            style={{
                              fontSize: "14px",
                              fontStyle: "Quicksand",
                              color: "#949494",
                            }}
                          >
                            {" "}
                            That was wonderful. Thanks....{" "}
                          </Text>
                        </View>
                        <View style={{}}>
                          <Text
                            style={{
                              fontSize: "14px",
                              fontStyle: "Quicksand",
                              color: "#949494",
                            }}
                          >
                            {" "}
                            8:05{" "}
                          </Text>
                        </View>
                      </TouchableOpacity>
                      <MessBox />
                      <MessBox />
                      <MessBox />
                      <MessBox />
                      <MessBox />
                      <MessBox />
                      <MessBox />
                      <MessBox />
                      <MessBox />
                    </View>
                  </ScrollView>
                </View>

                <View
                  style={{
                    backgroundColor: "#F9FAFC",
                    width: "60%",
                    ...Shadow._3(),
                    marginRight: "30px",
                    marginTop: "50px",
                    marginBottom: "30px",
                  }}
                >
                  <View
                    style={{
                      padding: 20,
                      borderWidth: 1,
                      borderStyle: "solid",
                      backgroundColor: "#F4F5F8",
                    }}
                  >
                    <Text
                      style={{
                        color: "#575757",
                        fontSize: "20px",
                        fontStyle: "Quicksand",
                      }}
                    >
                      Carmena Barnes
                    </Text>
                  </View>

                  <ScrollView
                    style={{
                      height: 900,
                      borderStyle: "solid",
                      borderWidth: 1,
                    }}
                  >
                    <View style={{ flexDirection: "column" }}>
                      {/* /////With Picture Right */}
                      <View
                        style={{
                          paddingTop: "40px",
                          paddingRight: "15px",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <View
                          style={{
                            padding: 10,
                            backgroundColor: "#AFBBC6",
                            marginRight: "15px",
                            borderRadius: "10px 10px 0px 10px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "16px",
                              fontStyle: "Quicksand",
                              color: "#F9FAFF",
                            }}
                          >
                            Hello Mr. Carmena Barnes{" "}
                          </Text>
                        </View>

                        <View style={{ paddingRight: w < 600 ? 0 : 20 }}>
                          <img
                            style={{ height: 50, width: 50, borderRadius: 50 }}
                            src={UI.CARMEN}
                          />
                        </View>
                      </View>

                      {/* ////Without Picture Right */}
                      <View
                        style={{
                          paddingTop: "10px",
                          paddingRight: w < 600 ? "60px" : "85px",
                          marginLeft: w < 480 ? "70%" : "0%",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <View
                          style={{
                            padding: 10,
                            backgroundColor: "#AFBBC6",
                            marginRight: "15px",
                            borderRadius: "10px 0px 10px 10px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "16px",
                              fontStyle: "Quicksand",
                              color: "#F9FAFF",
                            }}
                          >
                            How are you doing today? I need a consultation about
                            finance.{" "}
                          </Text>
                        </View>
                      </View>

                      {/* ////With Picture Left */}
                      <View
                        style={{
                          paddingTop: "40px",
                          paddingLeft: "20px",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        <View style={{ paddingRight: 20 }}>
                          <img
                            style={{
                              height: 50,
                              width: w < 600 ? 222 : 55,
                              borderRadius: 100,
                            }}
                            src={UI.CARMEN}
                          />
                        </View>

                        <View
                          style={{
                            padding: 10,
                            backgroundColor: "#FFFFFF",
                            borderRadius: "10px 0px 10px 10px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "18px",
                              fontStyle: "Quicksand",
                              color: "#949494",
                            }}
                          >
                            Hi Mr. Juliano. Sure just book an appointment and we
                            can start the consultation.{" "}
                          </Text>
                        </View>
                      </View>

                      {/* ////Without Picture Left */}
                      <View
                        style={{
                          paddingTop: "15px",
                          paddingLeft: "90px",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "#FFFFFF",
                            padding: 10,
                            borderRadius: "10px 0px 10px 10px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "18px",
                              fontStyle: "Quicksand",
                              color: "#949494",
                            }}
                          >
                            I'm fine. Thank you! And what about you? Is
                            everything ok?{" "}
                          </Text>
                        </View>
                      </View>

                      {/* /////With Picture Right */}
                      <View
                        style={{
                          paddingTop: "40px",
                          paddingRight: "15px",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <View
                          style={{
                            padding: 10,
                            backgroundColor: "#AFBBC6",
                            marginRight: "15px",
                            borderRadius: "10px 10px 0px 10px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "16px",
                              fontStyle: "Quicksand",
                              color: "#F9FAFF",
                            }}
                          >
                            Okay. Thanks{" "}
                          </Text>
                        </View>

                        <View style={{ paddingRight: w < 600 ? 0 : 20 }}>
                          <img
                            style={{ height: 50, width: 50, borderRadius: 100 }}
                            src={UI.CARMEN}
                          />
                        </View>
                      </View>

                      {/* ////Without Picture Right */}
                      <View
                        style={{
                          paddingTop: "10px",
                          paddingRight: w < 600 ? "65px" : "85px",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <View
                          style={{
                            padding: 10,
                            backgroundColor: "#AFBBC6",
                            marginRight: "15px",
                            borderRadius: "10px 0px 10px 10px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "16px",
                              fontStyle: "Quicksand",
                              color: "#F9FAFF",
                            }}
                          >
                            May I ask you a favor ?{" "}
                          </Text>
                        </View>
                      </View>

                      {/* ////Without Picture Right Text Long */}
                      <View
                        style={{
                          paddingTop: "10px",
                          paddingRight: w < 600 ? "65px" : "85px",
                          paddingLeft: "112px",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <View
                          style={{
                            padding: 10,
                            backgroundColor: "#AFBBC6",
                            marginRight: "15px",
                            borderRadius: "10px 0px 10px 10px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "16px",
                              fontStyle: "Quicksand",
                              color: "#F9FAFF",
                            }}
                          >
                            What is Lorem Ipsum Lorem ipsum is simply a dummy
                            test of the printing and typesetting industry Lorem
                            ipsum has been the industry's standard dummy text
                            ever since 1500s when an unknown printer took a
                            gallery of type and scrambled it to make a type
                            specimen book it has?{" "}
                          </Text>
                        </View>
                      </View>

                      {/* ////Without Picture Right GREEN BGCOLOR*/}
                      <View
                        style={{
                          paddingTop: "10px",
                          paddingRight: w < 600 ? "65px" : "85px",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <View
                          style={{
                            padding: 10,
                            backgroundColor: "#70C278",
                            marginRight: "15px",
                            borderRadius: "10px 10px 0px 10px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "16px",
                              fontStyle: "Quicksand",
                              color: "#F9FAFF",
                            }}
                          >
                            What does this dummy txt mean?{" "}
                          </Text>
                        </View>
                      </View>

                      {/* ////Without Picture Left */}
                      <View
                        style={{
                          paddingTop: "40px",
                          paddingLeft: "90px",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "#FFFFFF",
                            padding: 10,
                            borderRadius: "10px 0px 10px 10px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "18px",
                              fontStyle: "Quicksand",
                              color: "#949494",
                            }}
                          >
                            I'm fine. Thank you! And what about you? Is
                            everything ok?{" "}
                          </Text>
                        </View>
                      </View>

                      {/* ////Without Picture Left */}
                      <View
                        style={{
                          paddingTop: "15px",
                          paddingLeft: "90px",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "#FFFFFF",
                            padding: 10,
                            borderRadius: "10px 0px 10px 10px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "18px",
                              fontStyle: "Quicksand",
                              color: "#949494",
                            }}
                          >
                            I'm fine. Thank you! And what about you? Is
                            everything ok?{" "}
                          </Text>
                        </View>
                      </View>
                      {/* ////Without Picture Left */}
                      <View
                        style={{
                          paddingTop: "15px",
                          paddingLeft: "90px",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "#FFFFFF",
                            padding: 10,
                            borderRadius: "10px 0px 10px 10px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "18px",
                              fontStyle: "Quicksand",
                              color: "#949494",
                            }}
                          >
                            I'm fine. Thank you! And what about you? Is
                            everything ok?{" "}
                          </Text>
                        </View>
                      </View>

                      {/* ////Without Picture Left */}
                      <View
                        style={{
                          paddingTop: "15px",
                          paddingBottom: "15px",
                          paddingLeft: "90px",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "#FFFFFF",
                            padding: 10,
                            borderRadius: "10px 10px 10px 0px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "18px",
                              fontStyle: "Quicksand",
                              color: "#949494",
                            }}
                          >
                            Joshua is typing
                          </Text>
                        </View>
                      </View>
                    </View>
                  </ScrollView>

                  <View
                    style={{
                      marginLeft: "8px",
                      marginRight: "8px",
                      marginTop: "10px",
                      height: "50px",
                      marginBottom: "10px",
                      borderRadius: "30px",
                      backgroundColor: "#FFFFFF",
                      borderColor: "#dcddd5",
                      borderWidth: 1,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <TouchableOpacity style={{ paddingLeft: "15px" }}>
                        <Text
                          style={{
                            paddingTop: 10,
                            fontSize: "20px",
                            color: "#AFBBC6",
                          }}
                        >
                          Enter your Message Here
                        </Text>
                      </TouchableOpacity>

                      <View
                        style={{
                          flexDirection: "row",
                          paddingTop: "10px",
                          paddingRight: "15px",
                        }}
                      >
                        <TouchableOpacity
                          style={{ paddingRight: "15px", paddingTop: "3px" }}
                        >
                          <img
                            style={{ height: 25, width: 25 }}
                            src={UI.Happy}
                          />
                        </TouchableOpacity>

                        <TouchableOpacity
                          style={{ paddingRight: "25px", paddingTop: "3px" }}
                        >
                          <img
                            style={{ height: 25, width: 25 }}
                            src={UI.Paperclip}
                          />
                        </TouchableOpacity>

                        <TouchableOpacity
                          style={{
                            height: 35,
                            width: 35,
                            borderRadius: "50px",
                            backgroundColor: "#70C278",
                            paddingBottom: "3px",
                          }}
                        >
                          <View
                            style={{ paddingTop: "4px", paddingLeft: "6px" }}
                          >
                            <img
                              style={{ height: 25, width: 25 }}
                              src={UI.Path}
                            />
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>

                {/* <View style={{ backgroundColor:"#F9FAFC", width:"60%", ...Shadow._3(), ...tab_style, marginRight:"30px", marginTop:"50px", marginBottom:"30px", }}>
                         <View style={{ padding:20, borderWidth: 1, borderStyle: "solid", backgroundColor:"#F4F5F8", }}>
                             <Text style={{ color:"#575757", fontSize:"20px", fontStyle:"Quicksand" }}>Sasha Cohen</Text>
                         </View>



                         <ScrollView  style = {{  height: 900, borderStyle: "solid", borderWidth: 1}}>

                                <View style = {{ flexDirection: "column" }}>

                                  
                                  <View style={{ paddingTop:"40px", paddingRight:"15px", flexDirection:"row",justifyContent:"flex-end"}}> 
                                      <View style={{ padding:10, backgroundColor:"#AFBBC6", marginRight:"15px", borderRadius: "10px 10px 0px 10px"  }}>
                                        <Text style={{ fontSize:"16px", fontStyle:"Quicksand",color:"#F9FAFF" }}>Hello Mr. Joshua Barnes </Text>
                                      </View>    
                                                                
                                      <View style={{ paddingRight:  w < 600 ? 0 : 20, }}>
                                          <img style={{  height: 50, width: 50 , borderRadius: 50, }} src={UI.AMANDA} />                                         
                                      </View>
                                  </View> 
 
                                 
                                  <View style={{ paddingTop:"10px", paddingRight: w < 600 ? "60px" : "85px", marginLeft: w < 480 ? "70%" : "0%",  flexDirection:"row", justifyContent:"flex-end"}}> 
                                      <View style={{ padding:10, backgroundColor:"#AFBBC6",  marginRight:"15px", borderRadius: "10px 0px 10px 10px" }}>
                                        <Text style={{ fontSize:"16px", fontStyle:"Quicksand", color:"#F9FAFF" }}>How are you doing today? I need a consultation about finance. </Text>
                                      </View>    
                                     
                                  </View> 

                                   
                                  <View style={{ paddingTop:"40px", paddingLeft:"20px", flexDirection:"row",justifyContent:"flex-start", width:"100%"}}> 
                                      <View style={{paddingRight: 20 }}>
                                          <img style={{  height: 50 , width:  w < 600 ? 222 : 55, borderRadius: 100, }} src={UI.AMANDA} />                                         
                                      </View>
                                        
                                      <View style={{padding:10, backgroundColor:"#FFFFFF", borderRadius: "10px 0px 10px 10px"}}>
                                        <Text style={{ fontSize:"18px", fontStyle:"Quicksand", color:"#949494"}}>Hi Mr. Juliano. Sure just book an appointment and we can start the consultation. </Text>
                                      </View> 
                                                               
                                  </View> 

                                 
                                  <View style={{ paddingTop:"15px", paddingLeft: "90px", flexDirection:"row", justifyContent:"flex-start"}}> 
                                      <View style={{backgroundColor:"#FFFFFF", padding:10, borderRadius: "10px 0px 10px 10px" }}>
                                        <Text style={{ fontSize:"18px", fontStyle:"Quicksand", color:"#949494" }}>I'm fine. Thank you! And what about you? Is everything ok? </Text>
                                      </View>    
                                     
                                  </View> 
 

                                
                                  <View style={{ paddingTop:"40px", paddingRight:"15px", flexDirection:"row",justifyContent:"flex-end"}}> 
                                      <View style={{ padding:10, backgroundColor:"#AFBBC6", marginRight:"15px", borderRadius: "10px 10px 0px 10px" }}>
                                        <Text style={{ fontSize:"16px", fontStyle:"Quicksand", color:"#F9FAFF"}}>Okay. Thanks </Text>
                                      </View>    
                                                                
                                      <View style={{paddingRight:  w < 600 ? 0 : 20, }}>
                                          <img style={{  height: 50, width: 50, borderRadius: 100, }} src={UI.AMANDA} />                                         
                                      </View>
                                  </View> 

                                  
                                   <View style={{ paddingTop:"10px", paddingRight: w < 600 ? "65px" : "85px",  flexDirection:"row", justifyContent:"flex-end"}}> 
                                      <View style={{ padding:10, backgroundColor:"#AFBBC6",  marginRight:"15px", borderRadius: "10px 0px 10px 10px" }}>
                                        <Text style={{ fontSize:"16px", fontStyle:"Quicksand", color:"#F9FAFF" }}>May I ask you a favor ? </Text>
                                      </View>    
                                     
                                  </View> 

                             
                                  <View style={{ paddingTop:"10px", paddingRight: w < 600 ? "65px" : "85px",  paddingLeft:"112px", flexDirection:"row", justifyContent:"flex-end"}}> 
                                      <View style={{ padding:10, backgroundColor:"#AFBBC6",  marginRight:"15px", borderRadius: "10px 0px 10px 10px" }}>
                                        <Text style={{ fontSize:"16px", fontStyle:"Quicksand", color:"#F9FAFF" }}>What is Lorem Ipsum Lorem ipsum is simply a dummy test of the 
                                        printing and typesetting  industry Lorem ipsum has been the industry's standard dummy text ever since 1500s when an unknown printer
                                        took a gallery of type and scrambled it to make a type specimen book it has? </Text>
                                      </View>    
                                     
                                  </View> 


                      
                                  <View style={{ paddingTop:"10px", paddingRight: w < 600 ? "65px" : "85px", flexDirection:"row", justifyContent:"flex-end"}}> 
                                      <View style={{ padding:10, backgroundColor:"#70C278",  marginRight:"15px",borderRadius: "10px 10px 0px 10px" }}>
                                        <Text style={{ fontSize:"16px", fontStyle:"Quicksand", color:"#F9FAFF" }}>What does this dummy txt mean? </Text>
                                      </View>    
                                     
                                  </View> 



                                
                               
                                  <View style={{ paddingTop:"40px", paddingLeft:"90px", flexDirection:"row", justifyContent:"flex-start"}}> 
                                      <View style={{backgroundColor:"#FFFFFF", padding:10,  borderRadius: "10px 0px 10px 10px"   }}>
                                        <Text style={{ fontSize:"18px", fontStyle:"Quicksand", color:"#949494" }}>I'm fine. Thank you! And what about you? Is everything ok? </Text>
                                      </View>    
                                     
                                  </View> 

                            
                                      <View style={{ paddingTop:"15px", paddingLeft:"90px", flexDirection:"row", justifyContent:"flex-start"}}> 
                                      <View style={{backgroundColor:"#FFFFFF", padding:10,  borderRadius: "10px 0px 10px 10px" }}>
                                        <Text style={{ fontSize:"18px", fontStyle:"Quicksand", color:"#949494" }}>I'm fine. Thank you! And what about you? Is everything ok? </Text>
                                      </View>    
                                     
                                  </View> 
                                  
                                      <View style={{ paddingTop:"15px", paddingLeft:"90px", flexDirection:"row", justifyContent:"flex-start"}}> 
                                      <View style={{backgroundColor:"#FFFFFF", padding:10, borderRadius: "10px 0px 10px 10px"  }}>
                                        <Text style={{ fontSize:"18px", fontStyle:"Quicksand", color:"#949494" }}>I'm fine. Thank you! And what about you? Is everything ok? </Text>
                                      </View>    
                                     
                                  </View> 
                                  
                                  

                                     <View style={{ paddingTop:"15px", paddingBottom:"15px", paddingLeft:"90px", flexDirection:"row", justifyContent:"flex-start"}}> 
                                      <View style={{backgroundColor:"#FFFFFF", padding:10, borderRadius: "10px 10px 10px 0px" }}>
                                        <Text style={{ fontSize:"18px", fontStyle:"Quicksand", color:"#949494" }}>Joshua is typing</Text>
                                      </View>    
                                     
                                  </View> 
                                    
                                </View>

                        </ScrollView>


                                 <View style={{ marginLeft:"8px", marginRight:"8px", marginTop:"10px", height: "50px", marginBottom:"10px",  borderRadius:"30px", backgroundColor:"#FFFFFF", 
                                 borderColor:"#dcddd5", borderWidth:1}}>
                                      <View style={{ flexDirection: "row", justifyContent:"space-between"  }}>
                                            <TouchableOpacity style={{ paddingLeft:"15px" }}>
                                              <Text style={{ paddingTop:10,fontSize:"20px", color:"#AFBBC6"  }}>Enter your Message Here</Text>
                                            </TouchableOpacity>

                                          
                                                <View style={{ flexDirection: "row", paddingTop:"10px", paddingRight:"15px", }}>
                                                   <TouchableOpacity style={{ paddingRight:"15px", paddingTop:"3px", }}>
                                                       <img style={{ height: 25, width: 25 }} src={UI.Happy} />   
                                                    </TouchableOpacity>
                                                                                      
                                                    <TouchableOpacity style={{ paddingRight:"25px", paddingTop:"3px" }}>
                                                      <img style={{ height: 25, width: 25 }} src={UI.Paperclip} />
                                                    </TouchableOpacity>

                                                   <TouchableOpacity style={{height:35, width:35, borderRadius:"50px", backgroundColor:"#70C278", paddingBottom:"3px"}}>
                                                        <View style={{ paddingTop:"4px", paddingLeft:"6px" }}>
                                                          <img style={{ height: 25, width: 25 }} src={UI.Path} />  
                                                        </View>
                                                   </TouchableOpacity>
                                               </View>   
                                      </View>
                                  </View>      
                     </View>


 */}
              </View>
            </View>
          </View>

          {UI.box(20)}
        </View>
      </MainLayout>
    );
  }
}

const MessBox = (props = { text: "" }) => {
  return (
    <View
      style={{
        paddingTop: "15px",
        flexDirection: "row",
        paddingBottom: "15px",
        paddingLeft: "10px",
        paddingRight: "10px",
        justifyContent: "space-around",
      }}
    >
      <View style={{}}>
        <img
          style={{ height: 50, width: 50, borderRadius: 100 }}
          src={UI.CARMEN}
        />
      </View>
      <View style={{}}>
        <Text style={{ fontSize: "18px", fontStyle: "Quicksand" }}>
          {" "}
          Eric Juliano{" "}
        </Text>
        <Text
          style={{ fontSize: "14px", fontStyle: "Quicksand", color: "#949494" }}
        >
          {" "}
          Me: What does this dummy...{" "}
        </Text>
      </View>
      <View style={{}}>
        <Text
          style={{ fontSize: "14px", fontStyle: "Quicksand", color: "#949494" }}
        >
          {" "}
          08:05{" "}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  },

  content_subcontainer: {
    width: "90%", /// width for outside
    // backgroundColor: "white",
    borderRadius: 5,
    padding: 10, /// width for inside
    paddingTop: 35,
    justifyContent: "center",
    opacity: 1,
  },
  booking_title: {
    fontStyle: "Quicksand",
    color: "#575757",
    fontWeight: "bold",
    fontSize: "35px",
  },
  booking_subtitle: {
    fontSize: "20px",
    color: "#BABBAB",
    fontStyle: "Quicksand",
    fontWeight: 400,
  },
});

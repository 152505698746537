import React from "react";
import { Route, Switch } from "react-router-dom";

import Animation from "./modules/animation";

import Test from "./modules/test";
import AnotherPage from "./modules/another-page";

import AdminRegistration from "./modules/admin-registration";
import AdminHome from "./modules/admin-home";
import AdminSignin from "./modules/admin-sign_in";
import AdminBookingHistory from "./modules/admin-booking";
import AdminBookingRemoval from "./modules/admin-booking-removal";
import AdminBookingCompletion from "./modules/admin-booking-completion";
import AdminCalendar from "./modules/admin-calendar";
import AdminMessages from "./modules/admin-messages";
import AdminTest from "./modules/admin-test";
import AdminCurrentBookings from "./modules/admin-current-bookings";
import AdminPendingBookings from "./modules/admin-pending-bookings";
import AdminMySchedule from "./modules/admin-my_schedule";
import AdminViewAll from "./modules/admin-notifications-view_all";
import AdminViewDetails from "./modules/admin-notifications-view_details";
import AdminCalendarView from "./modules/admin-calendar-view_information";
import AdminEditProfile from "./modules/admin-edit_profile";

import Sign_in from "./modules/user-sign_in";
import Registration from "./modules/user-registration";
import Consultation from "./modules/user-consultation";
import Lawyer_List from "./modules/user-lawyer_list";
import Lawyer_Info from "./modules/user-lawyer_info";
import Booking from "./modules/user-booking";
import Consultation_List from "./modules/user-consultation_list";
import Booking_List from "./modules/user-booking_list";
import Messages from "./modules/user-messages";
import View from "./modules/user-notifications-view_all";
import Details from "./modules/user-notifications-view_details";
import Edit from "./modules/user-my_profile";
import User from "./modules/_components/User-MainLayout";

function AppRouter() {
  return (
    <Switch>
     
     <Route exact path="/another-page" component={AnotherPage} />
      
      {/* User - WEB */}

      <Route exact path="/" component={Sign_in} />
      <Route exact path="/login" component={Sign_in} />
      <Route exact path="/register" component={Registration} />
      <Route exact path="/dashboard" component={Consultation} />
      <Route exact path="/lawyers" component={Lawyer_List} />
      <Route exact path="/lawyers/:id" component={Lawyer_Info} />
      <Route exact path="/booking/:id" component={Booking} />
      <Route exact path="/booking" component={Booking_List} />
      <Route exact path="/consultations" component={Consultation_List} />
      <Route exact path="/chat" component={Messages} />
      <Route exact path="/view" component={View} />
      <Route exact path="/details" component={Details} />
      <Route exact path="/edit" component={Edit} />

 
      {/* ADMIN - WEB */}
      <Route exact path="/admin" component={AdminSignin} />
      <Route exact path="/admin/login" component={AdminSignin} />
      <Route exact path="/admin/dashboard" component={AdminHome} />
      <Route exact path="/admin/register" component={AdminRegistration} />
      <Route exact path="/admin/booking" component={AdminBookingHistory} />
      <Route exact path="/admin/booking-removal" component={AdminBookingRemoval} />
      <Route exact path="/admin/booking-completion" component={AdminBookingCompletion} />
      <Route exact path="/admin/calendar" component={AdminCalendar} />
      <Route exact path="/admin/messages" component={AdminMessages} />
      <Route exact path="/admin/current-bookings" component={AdminCurrentBookings} />
      <Route exact path="/admin/pending-bookings" component={AdminPendingBookings} />
      <Route exact path="/admin/schedule" component={AdminMySchedule} />
      <Route exact path="/admin/view-all" component={AdminViewAll} />
      <Route exact path="/admin/view-details" component={AdminViewDetails} />
   
      <Route exact path="/admin/view-information" component={AdminCalendarView} />
      <Route exact path="/admin/edit-profile" component={AdminEditProfile} />

      

      <Route exact path="/admin/test" component={AdminTest} />
      <Route exact path="test" component={Test}/>
      

    </Switch>
  );
}

export default AppRouter;

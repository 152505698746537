import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  Wrap,
} from "../../shared/custom-react-native";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import CalendarSchedule from "../_components/CalendarSchedule";

// import { Calendar, dateFnsLocalizer } from "react-big-calendar";
// import format from "date-fns/format";
// import parse from "date-fns/parse";
// import startOfWeek from "date-fns/startOfWeek";
// import getDay from "date-fns/getDay";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import 'react-big-calendar/lib/sass/styles';

// // Setup the localizer by providing the moment (or globalize) Object
// // to the correct localizer.
// const localizer = BigCalendar.momentLocalizer(moment) // or globalizeLocalizer
// localizer.formats.yearHeaderFormat = 'YYYY'
// // localizer.messages.year = 'Year'

// const locales = {
//   "en-US": require("date-fns/locale/en-US")
// };
// const localizer = dateFnsLocalizer({
//   format,
//   parse,
//   startOfWeek,
//   getDay,
//   locales
// });
// const myEventsList = [
//   { start: new Date(), end: new Date(), title: "special event" }
// ];
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';

export default class AdminCalendar extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
        //Initial State Here
      },
      {
        role: "lawyer",
      }
    );
  }

  render() {
    const { width, isMobile } = this.state;
    const horizontal_padding = width * 0.025;
    const w = width;
    const _this = this;

    return (
      <MainLayout _this={this} active_tab={"Calendar"}>
        <View
          style={{
            ...styles.main_container,
            paddingLeft: horizontal_padding,
            paddingRight: horizontal_padding,
            
          }}
        >
          <Text style={styles.calendar_txt}>Calendar</Text>
          {/* <Text style={styles.calendar_txt}>All Booking</Text> */}
          
          <View
            style={{
              flexDirection: w < 1000 ? "column" : "row",
              justifyContent: w < 1000 ? "flex-start" : "space-around",
              alignItems: w < 1000 ? "center" : "flex-start",
              
            }}
          >
            <View
              style={{
                backgroundColor: "#FFFFFF",
                width: "90%",
                height: "flex-start",
                marginLeft: w < 500 ? 0 : 50,
                marginBottom: 50,
                padding: 30,
                paddingTop: 20,
                justifyContent: w < 500 ? "center" : "none",
                alignSelf: w < 500 ? "center" : "none",
                ...Shadow._3(),
              }}
            >
              <CalendarSchedule
                data={[
                  { item: {}, timestamp: Date.now() + 100000000 },
                  { item: {}, timestamp: Date.now() + 109000000 },
                ]}
                _this={_this}
                id={"appointments"}
              />
            </View>
            {UI.box(30)}
            <View
              style={{
                width: w < 970 ? "80%" : "25%",
                padding: 10,
                paddingLeft: 10,
                paddingRight: 5,
                marginTop: -75,
              }}
            >
              <Text style={{ fontSize: 25 }}>Today's Schedule</Text>
              <View
                style={{
                  ...styles.profile_container,
                  width: "100%",
                  alignItems: w < 660 ? "center" : "flex-start",
                }}
              >
                <View
                  style={{
                    alignSelf: "center",
                    marginLeft: 25,
                    marginRight: 15,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                    }}
                  >
                    <View>
                      <img style={styles.profile_img} src={UI.ENRICO} />
                    </View>
                    <View style={{}}>
                      <Text style={styles.day_txt}>10</Text>
                      <Text style={styles.month_txt}>March</Text>
                    </View>
                  </View>
                  <Text style={styles.profile_name}>Enrico Juliano</Text>
                  <Text style={{ color: "#0F4059", fontSize: 14 }}>
                    270 Robles Manggahan, Quezon City
                  </Text>
                  {UI.box(25)}
                  <Text style={{ color: "#575757", fontSize: 14 }}>
                    Schedule
                  </Text>
                  <Text style={{ color: "#F26E50", fontSize: 14 }}>
                    Wed, March 10, 2021 | 10:30 am
                  </Text>
                  <Text style={{ color: "#575757", fontSize: 14 }}>
                    https://zoom.us/meetings
                  </Text>
                </View>

                <img
                  style={{ height: 16, width: "100%", marginTop: 20 }}
                  src={UI.BOX_BOTTOM}
                />
              </View>



              {UI.box(15)}
              <View
                style={{
                  ...styles.profile_container,
                  width: "100%",
                  alignItems: w < 660 ? "center" : "flex-start",
                }}
              >
                <View
                  style={{
                    alignSelf: "center",
                    marginLeft: 25,
                    marginRight: 15,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                    }}
                  >
                    <View>
                      <img style={styles.profile_img} src={UI.ENRICO} />
                    </View>
                    <View style={{}}>
                      <Text style={styles.day_txt}>10</Text>
                      <Text style={styles.month_txt}>March</Text>
                    </View>
                  </View>
                  <Text style={styles.profile_name}>Enrico Juliano</Text>
                  <Text style={{ color: "#0F4059", fontSize: 14 }}>
                    270 Robles Manggahan, Quezon City
                  </Text>
                  {UI.box(25)}
                  <Text style={{ color: "#575757", fontSize: 14 }}>
                    Schedule
                  </Text>
                  <Text style={{ color: "#F26E50", fontSize: 14 }}>
                    Wed, March 10, 2021 | 10:30 am
                  </Text>
                  <Text style={{ color: "#575757", fontSize: 14 }}>
                    https://zoom.us/meetings
                  </Text>
                </View>

                <img
                  style={{ height: 16, width: "100%", marginTop: 20 }}
                  src={UI.BOX_BOTTOM}
                />
              </View>


              {UI.box(25)}

              <Text style={{ fontSize: 25 }}>Upcoming Schedule</Text>
              <View
                style={{
                  ...styles.profile_container,
                  width: "100%",
                  alignItems: w < 660 ? "center" : "flex-start",
                }}
              >
                <View
                  style={{
                    alignSelf: "center",
                    marginLeft: 25,
                    marginRight: 15,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                    }}
                  >
                    <View>
                      <img style={styles.profile_img} src={UI.ENRICO} />
                    </View>
                    <View style={{}}>
                      <Text style={styles.day_txt}>10</Text>
                      <Text style={styles.month_txt}>March</Text>
                    </View>
                  </View>
                  <Text style={styles.profile_name}>Enrico Juliano</Text>
                  <Text style={{ color: "#0F4059", fontSize: 14 }}>
                    270 Robles Manggahan, Quezon City
                  </Text>
                  {UI.box(25)}
                  <Text style={{ color: "#575757", fontSize: 14 }}>
                    Schedule
                  </Text>
                  <Text style={{ color: "#F26E50", fontSize: 14 }}>
                    Wed, March 10, 2021 | 10:30 am
                  </Text>
                  <Text style={{ color: "#575757", fontSize: 14 }}>
                    https://zoom.us/meetings
                  </Text>
                </View>

                <img
                  style={{ height: 16, width: "100%", marginTop: 20 }}
                  src={UI.BOX_BOTTOM}
                />
              </View>


            </View>
          </View>
        </View>
      </MainLayout>
    );
  }
}

// const rootElement = document.getElementById('root')
// ReactDOM.render(<App />, rootElement)

const InputForm = (props = { placeholder: "", type: "" }) => {
  return (
    <View style={styles.input_container}>
      <input
        style={styles.input_style}
        type={props.type}
        placeholder={props.placeholder}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  main_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#F9FAFF",
  },
  calendar_txt: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#575757",
    padding: 30,
    paddingLeft: 50,
  },
  profile_container: {
    marginTop: 20,
    marginRight: 10,
    alignSelf: "flex-start",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",

    ...Shadow._4(),
  },

  day_txt: {
    fontSize: 35,
    fontWeight: "bold",
    color: "#F26E50",
  },
  month_txt: {
    fontSize: 12,
    color: "#F26E50",
  },
  profile_name: {
    fontSize: 28,
    color: "#575757",
    marginTop: "-15px",
  },
  profile1_container: {
    height: 285,

    borderRadius: 8,
    backgroundColor: "#FFFFFF",

    ...Shadow._4(),
  },
  profile2_container: {
    height: 285,
    width: 270,
    borderRadius: 8,

    backgroundColor: "#FFFFFF",
    ...Shadow._4(),
  },
  profile_img: {
    height: 100,
    width: 100,
    borderRadius: 50,
  },
  day_txt: {
    fontSize: 35,
    fontWeight: "bold",
    color: "#F26E50",
  },
  month_txt: {
    fontSize: 12,
    color: "#F26E50",
  },

  practice_area: {
    fontSize: 11,
    color: "#BABBAB",
    paddingLeft: 10,
  },
  year_exp: {
    fontSize: 9,
    color: "#BABBAB",
    paddingLeft: 10,
  },
  school_container: {
    flexDirection: "row",
    paddingLeft: 10,
  },
});

import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  r,
} from "../../shared/custom-react-native";

import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import mem from "../../shared/Components/Memory/js";
import { FormHelper } from "../_components/FormHelper";
import CalendarHelper from "../_components/CalendarHelper";


export default class AdminViewDetails extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
       
      },
      {
        role: "lawyer",
      }
    );
  }
  

  render() {
    const {
      width,
      data,
      user,
      calendar_days,
      available_schedules,
      schedule_selected,
      schedule_message,
      last_date,
      last_month,
    } = this.state;
    const w = width;


   
    const item = data;
    return (
      <MainLayout _this={this}>
        <View style={{ width: "100%", backgroundColor: "#F9FAFF" }}>
          {UI.box(20)}

          <View style={styles.content_container}>
            <View style={styles.content_subcontainer}>
              <Text style={styles.content_title}>Notifications</Text>

              {UI.box(10)}
           
              <View style={{ ...Shadow._4(), padding:20, flexDirection: w < 875 ? "column" : "row" }}>

               <View style={{ width: w < 875 ? "100%" : "30%", flexDirection:"row",
                
                 justifyContent:  w < 875 ? "center" : "none",
                 textAlign: w < 875 ? "center" : "none"}}>
                <View style={{ borderRadius: "10px", padding: "25px", justifyContent: w < 875 ? "space-between" : "none",
               }}>
    
                    <View style={{paddingRight:"15px", flexDirection:"row", }}>
                        <View style={{ marginRight:"20px"}}>
                            <img style={styles.image_style}
                            src={UI.ENRICO}/>
                         </View>

                        <View style={{ marginTop:"15px" }}>
                            <Text style={styles.title_text}>
                             Enrico Juliano
                            </Text>
                            
                            <Text style={styles.subtitle_text}>
                              Client
                            </Text>
                        </View>

                    </View>

                    <View style={{ marginTop:"25px" }}>
                        <Text style={styles.text_style}> Schedule </Text>
                        <Text style={styles.otherText_style}> Wed, March 10, 2021 | 10:30 am </Text>
                        <Text style={styles.text_style}> https://zoom.us/meetings </Text>
                    </View>

                    <View style={{ marginTop:"25px" }}>
                        <Text style={styles.text_style}> Topic / Query</Text>
                        <Text style={styles.otherText_style}> Noisy Neighbor and Harassment </Text>
                    </View>
    
                    <View style={{marginTop: w < 800 ? "15px" : "25px", alignItems: w < 875 ? "center" : "none",}}>
                        <TouchableOpacity 
                         style={styles.payNow_button}>
                        <Text style={styles.payNow_text}>
                         Confirm Booking
                        </Text>
                        </TouchableOpacity>
                    </View>
                </View>

               
              
                <View style={{
                        height: w < 875 ? "0%" : "85%" ,
                        backgroundColor: w < 875 ? "#F9FAFF" : "#575757",
                        padding: w < 875 ? 0 : 1,
                        marginTop: w < 875 ? "0%" : "7%", 
                      }}
                    />

                    
                </View>
                
                <View style={{
                        width:  w < 875 ? "100%" : "0%",
                        backgroundColor: w < 875 ? "#575757" : "#F9FAFF",
                        padding: w < 875 ? 1 : 0,
                      }}
                    />
    
            <View style={{ width: w < 875 ? "100%" : "70%", padding:"25px", alignSelf: w < 875 ? "center" : "none"}}>
                <View>
                    <Text style={{ fontSize: "25px", color:"#575757"}}>
                      Client's Concern
                    </Text>

                    <Text style={{ fontSize: "15px", fontWeight:"bold"}}>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                    eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et.
                    </Text>
               </View>
             
            </View>
 
               
                



                 
              </View>

            {UI.box(100)}
          </View>
          </View>

          {UI.box(20)}
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  },

  content_subcontainer: {
    width: "90%",
    // backgroundColor: "white",
    borderRadius: 5,
    padding: 10,
    paddingTop: 35,
    justifyContent: "center",
    opacity: 1,
  },
 
  content_title: {
    fontStyle: "Quicksand",
    color: "#575757",
    fontWeight: "bold",
    fontSize: "35px",
    paddingBottom: "10px",
  },
  title_text:{
    fontStyle: "Quicksand", 
    color: "#575757", 
    fontWeight: "bold", 
    fontSize: "25px",
  },
  subtitle_text:{
    fontStyle: "Quicksand",
     color: "#BABBAB", 
     fontWeight: 500, 
     fontSize: "18px",
  },
  
  payNow_button:{
    width: 120, 
    alignItems: "center", 
    paddingTop: 12,
    backgroundColor: "#0F4059",
    height: "50px",
    borderRadius:"5px",
  },
  payNow_text:{
    color: "#FFFFFF", 
    fontSize: 14,
    fontWeight: "bold",
  },
  image_style:{
    height: 80, 
    width: 80,
     borderRadius: "50%", 
      marginTop: 10,
  },
  text_style:{
    fontSize:"15px", 
    color:"#575757"
  },
  otherText_style:{
    fontSize:"15px", 
    color:"#F26E50"
  },
});

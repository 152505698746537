import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  r,
} from "../../shared/custom-react-native";

import UI from "../../shared/Components/UI/js";
import { FormHelper } from "../_components/FormHelper";
import ZLayout from "../_components/ZLayout";
import MainLayout from "../_components/MainLayout";

export default class AdminRegistration extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
        //Initial State Here

        email: "",
        password: "",
        your_name: "",
        address: "",
        phone: "",
        profile_image: "",
        profile_image_uri: "",

        school_graduated: "",
        lawyer_type: "",
        graduated_at: "",
        years_of_experience: "",
        specialization_id: "1",
        proposed_fee: "",
        form_style: {
          paddingBottom: 20,
          marginRight: 30,
        },
      },
      {
        role: "lawyer",
      }
    );
  }

  onCreate = async () => {};

  handle_validation = async () => {
    await UI.clear_form_errors();
    return new Promise(async (resolve, reject) => {
      const {
        your_name,
        email,
        password,
        address,
        profile_image,
        confirm_password,
        phone,

        school_graduated,
        lawyer_type,
        graduated_at,
        years_of_experience,
  
      } = this.state;
      await UI.is_empty("your_name", your_name);
      await UI.is_email("email", email);
      await UI.is_empty("email", email);
      //validate email here
      await UI.minimum_length(
        "password",
        password,
        8,
        "Password should be have atleast 8 characters"
      );
      await UI.is_empty("password", password);
      await UI.is_empty("address", address);
      await UI.required_length("phone", phone, 11, "Invalid phone number.");
      await UI.is_empty("phone", phone);
      await UI.is_empty("profile_image", profile_image);
      await UI.is_empty("confirm_password", confirm_password);
      if (confirm_password) {
        if (password != confirm_password) {
          await UI.error_form("confirm_password", "Passwords do not match.");
        }
      }
      
      await UI.is_empty("school_graduated", school_graduated);
      await UI.is_empty("lawyer_type", lawyer_type);
      await UI.is_empty("years_of_experience", years_of_experience);
    
    

      //Lawyer things here
      if (await UI.hasNoError()) {
        resolve({
          your_name,
          email,
          password,
          address,
          profile_image,
          confirm_password,
          phone,
          school_graduated,
          lawyer_type,
          graduated_at,
          years_of_experience,
  
        });
      }
    });
  };

  submit = async () => {
    const values = await this.handle_validation();
    delete values["confirm_password"];
    this.show_loading();
    r.request({
      method: "post",
      url: "/lawyer/register",
      params: values,
      onFail: (e) => {
        UI.handle_error(e);
      },
      onSuccess: (e) => {
        this.alert("Successfully created your account", () => {
          UI.goTo("/admin/login");
        });
      },
      onFinish: () => {
        this.hide_loading();
      },
    });
  };
  

  render() {
    const {
      width,
      profile_image,
      profile_image_originalname,
      profile_image_uri,
    } = this.state;
    const w = width;
    const formwidth = width < 800 ? "100%" : "50%";
    let pad = width > 560 ? 75 : 20;

    return (
        <MainLayout _this={this}>
      <View style={styles.main_container}>
        <ZLayout _this={this} />
        <ScrollView>
          {/* <View>
            <View style={styles.header_container}>
              <img style={styles.header_img} src={UI.HEADER_CREATE} />

              <TouchableOpacity
                onClick={() => {
                  UI.goTo("/admin/login");
                }}
                style={styles.login_arrowButton}
              >
                <img style={styles.login_arrow} src={UI.LOGIN_ARROW} />
                <Text style={styles.login_text}>Login</Text>
              </TouchableOpacity>
            </View>

            <View style={styles.break_line}></View>
          </View> */}

          <View
            style={{
              ...styles.registration_container,
              paddingLeft: pad,
              paddingRight: pad,
            }}
          >
            <Text style={styles.registration_txt}>Edit Profile</Text>
            {UI.box(10)}
            <View style={styles.inside_container}>
              <View style={styles.lawyerinfo_container}>
                <Text style={styles.userinfo_txt}>LAWYER INFORMATION</Text>

                <UI.Row _this={this} breakpoint={800} breakpoint2={360}>
                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_name}>Your Name</Text>
                    <FormHelper _this={this} state={"last_name"}>
                      <InputFormName type={"text"} placeholder={"Last Name"} />
                    </FormHelper>
                  </View>
                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_name}>Address</Text>
                    <FormHelper _this={this} state={"address"}>
                      <InputForm type={"text"} placeholder={"Enter Address"} />
                    </FormHelper>
                  </View>
                </UI.Row>

                <UI.Row _this={this} breakpoint={800} breakpoint2={360}>
                <View style={{ width: formwidth }}>
                    <Text style={styles.input_name}>Email Address</Text>
                    <FormHelper _this={this} state={"email"}>
                      <InputForm type={"text"} placeholder={"Email Address"} />
                    </FormHelper>
                  </View>

                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_name}>Contact Number</Text>
                    <FormHelper _this={this} state={"phone"}>
                      <InputForm
                        type={"number"}
                        maxLength={11}
                        placeholder={"Enter Phone (09120000000)"}
                      />
                    </FormHelper>
                  </View>
                </UI.Row>

                <UI.Row _this={this} breakpoint={800} breakpoint2={360}>
                <View style={{ width: formwidth }}>
                    <Text style={styles.input_name}>Lawyer Type</Text>
                    <FormHelper _this={this} state={"lawyer_type"}>
                      <InputForm type={"text"} placeholder={"Enter Lawyer Type"} />
                    </FormHelper>
                  </View>
                  
                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_name}>School</Text>
                    <FormHelper _this={this} state={"school_graduated"}>
                      <InputForm type={"text"} placeholder={"Enter School"} />
                    </FormHelper>
                  </View>

                  
                </UI.Row>

                <UI.Row _this={this} breakpoint={800} breakpoint2={360}>
                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_name}>Year Graduated</Text>
                    <FormHelper _this={this} state={"graduated_at"}>
                      <InputForm type={"date"} placeholder={"MM/DD/YY"} />
                    </FormHelper>
                  </View>

                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_name}>
                    Years of Experience
                    </Text>
                    <FormHelper _this={this} state={"years_of_experience"}>
                      <InputForm
                        type={"number"}
                        maxLength={2}
                        placeholder={"Enter Years of Experience"}
                      />
                    </FormHelper>
                  </View>
                </UI.Row>

                

                

                
              </View>
            </View>

            <View style={styles.proof_container}>
              <Text style={styles.proof_txt}>PROOF OF IDENTIFICATION</Text>
              <Text style={styles.upload_txt}>Upload your profile picture</Text>
              <View style={{ flexDirection: "row" }}>
                <FormHelper
                  style={{ marginLeft: 20, marginTop: 20 }}
                  _this={this}
                  state={"profile_image"}
                >
                  <TouchableOpacity
                    onClick={async () => {
                      await UI.select_image("profile_image");
                    }}
                    style={styles.upload_container}
                  >
                    {profile_image ? (
                      <img
                        style={{ height: "100%", width: "100%" }}
                        src={profile_image_uri}
                      />
                    ) : (
                      <View style={{ alignItems: "center" }}>
                        <img style={styles.upload_img} src={UI.UPLOAD_BUTTON} />
                        <Text
                          style={{ color: "#949494", fontStyle: "Quicksand" }}
                        >
                          Upload Here
                        </Text>
                      </View>
                    )}
                  </TouchableOpacity>
                </FormHelper>
                <Text style={styles.nofile_txt}>
                  {profile_image
                    ? profile_image_originalname
                    : "No File Uploaded"}
                </Text>
              </View>

              <View style={styles.password_container}>
              <Text style={styles.userinfo_txt}>PASSWORD</Text>
              <UI.Row _this={this} breakpoint={800} breakpoint2={360} >
                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_name}>Password</Text>
                    <FormHelper _this={this} state={"password"}>
                      {/* <InputForm type={"password"} placeholder={"********"} /> */}
                      <InputFormPassword/>
                    </FormHelper>
                  </View>

                  <View style={{ width: formwidth }}>
                    <Text style={styles.input_name}>Confirm Password</Text>
                    <FormHelper _this={this} state={"confirm_password"}>
                      {/* <InputForm type={"password"} placeholder={"********"} /> */}
                      <InputFormPassword/>
                    </FormHelper>
                  </View>
                </UI.Row>

              </View>
              

                <Text style ={{paddingLeft: 20}}>By submitting, you accept our <TouchableOpacity style={{color: "#F14336"}}>Terms of Use</TouchableOpacity> and <TouchableOpacity style={{color: "#F14336"}}>Privacy Policy.</TouchableOpacity> </Text>

                <UI.Row _this={this} breakpoint={800}>
                 

                  <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  onClick={this.submit}
                  style={styles.register_btn}
                >
                  <Text style={styles.register_txt}>Update Profile</Text>
                </TouchableOpacity>
              </View>

              <View style={{ paddingRight: 26 }}>
                    <TouchableOpacity
                      onClick={() => {
                        UI.goTo("/");
                      }}
                      style={styles.cancel_button}
                    >
                      <Text
                        style={styles.cancel_txt}
                      >
                        Cancel
                      </Text>
                    </TouchableOpacity>
                  </View>
              
                  </UI.Row>
             
            </View>
          </View>

         
                
          {/* <View style={styles.footer_line}>
            <Text style={styles.footer_txt}> 2021 All Rights Reserved </Text>
          </View> */}
        </ScrollView>
      </View>
      </MainLayout>
    );
  }
}
const InputForm = (props = { placeholder: "", type: "" }) => {
  let max = {};
  if (props.maxLength > 0) {
    max = { maxLength: props.maxLength };
  }
  let max_date = {};
  if (props.type == "date") {
    max_date = { max: UI.date_obj_to_dashed(new Date()) };
  }
  return (
    <View style={styles.input_container}>
      <input
        style={styles.input_style}
        type={props.type}
        placeholder={props.placeholder}
        onChange={(e) => {
          if (props.type == "number") {
            UI.onChangeNumber(e, props.state);
          } else if (props.type == "date") {
            UI.onChangeDate(e, props.state);
          } else {
            UI.onChangeText(e, props.state);
          }
        }}
        {...max}
        {...max_date}
      />
    </View>
  );
};

const InputFormName = (props = { placeholder: "", type: "" }) => {
  return (
    <View style={styles.input_container}>
      <input
        style={styles.input_style}
        type={props.type}
        placeholder={props.placeholder}
        onChange={(e) => {
            UI.onChangeTextOnly(e, props.state);                  
        }}
      />
    </View>
  );
};


// const Box = (props) => {

//   return (
//     <View style = {{height: 173, width: 207, backgroundColor: "pink", margin: 10}}>

//     </View>
//   );
// }

class InputFormPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      is_Password_Shown: false,
    }; 

  }
  
  toggle_Password = () => {  
    this.setState(
    {
      is_Password_Shown: !this.state.is_Password_Shown,
    },
    );
  };

 
  render() {     
        const props = this.props;
        return(
          
          <View style={{ flexDirection:"row", backgroundColor: "#F9FAFF"
         }}>

          <View style={{ width:"90%"}}>
            <input style={styles.input_style} 
            type={this.state.is_Password_Shown ? "text" : "password" }
              placeholder={"********"}
               onChange={(e) => {
                UI.onChangeText(e, props.state);
              }}/>
          </View>

          <TouchableOpacity onClick={this.toggle_Password} style={{ width:"10%", alignSelf:"center"}}> 
              <View style={{paddingLeft:"15%"}}>
              <img style={{ height:20,width:20,}} src={this.state.is_Password_Shown ? UI.EYE_OPEN : UI.EYE_CLOSE} />
              </View>
          </TouchableOpacity>
      
        </View>
      
        )
  }

}
const styles = StyleSheet.create({
  login_text: {
    color: "white",
    fontSize: 14,
    fontWeight: "bold",
    paddingLeft: 10,
    paddingRight: 20,
  },
  login_banner: {
    height: 150,
    width: 300,
  },
  login_arrow: {
    height: 30,
    width: 30,
  },
  login_arrowButton: {
    width: 125,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#0F4C59",
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 14,
    height: 50,
    borderRadius: 10,
    marginTop: 30,
    marginRight: 30,
  },
  main_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#F9FAFF",
  },
  header_container: {
    height: 100,
    width: "100%",
    backgroundColor: "white",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  header_img: {
    height: 179 * 0.6,
    width: 356 * 0.6,
    marginLeft: 20,
  },
  login_btn: {
    flexDirection: "row",
    height: 72 * 0.6,
    width: 182 * 0.7,
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: 10,
    backgroundColor: "#0F4C59",
    margin: 25,
    paddingTop: 10,
    paddingBottom: 10,
  },

  login_img: {
    height: 72 / 4,
    width: 72 / 4,
  },
  login_txt: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
  },
  break_line: {
    height: 27,
    width: "100%",
    backgroundColor: "#0F4059",
  },
  registration_container: {
    width: "100%",
    backgroundColor: "#F9FAFF",
    paddingLeft: 75,
    paddingRight: 75,
    paddingBottom: 50,
    marginTop: 30,
  },
  registration_txt: {
    fontSize: 25 ,
    fontWeight: "semibold",
    color: "#0F4059",
  },
  inside_container: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: 20,
  },
  lawyerinfo_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: 20,
  },
  password_container: {
    height: "100%",
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: 20,
  },
  userinfo_txt: {
    fontSize: 22,
    fontWeight: "semibold",
    color: "#0F4059",
    paddingBottom: 10,
  },

  input_style: {
    padding: 10,
    paddingRight: 5,
    paddingLeft: 5,
    fontSize: 16,
    backgroundColor: "#F9FAFF",
  },
  input_container: {
    width: "100%",
  },

  input_name: {
    fontSize: 16,
    color: "#0F4059",
  },
  proof_container: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: 20,
  },
  proof_txt: {
    fontSize: 22,
    fontWeight: "semibold",
    color: "#0F4059",
    paddingBottom: 10,
    marginLeft: 20,
  },
  upload_container: {
    height: 173 * 0.8,
    width: 207 * 0.8,
    backgroundColor: "#FFFFFF",
    borderWidth: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  upload_txt: {
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 20,
    color: "#575757",
  },
  upload_img: {
    alignSelf: "center",
    height: 77,
    width: 77,
  },
  nofile_txt: {
    fontSize: 16,
    color: "#F14336",
    justifyContent: "center",
    alignSelf: "center",
  },
  input_submit: {
    fontSize: 16,
    marginLeft: 20,
    color: "#0F4059",
  },
  cancel_button: {
    flexDirection: "row",
    height: 72 * 0.6,
    width: 182,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    margin: 25,
    paddingTop: 28,
    paddingBottom: 28,
    borderWidth: 2,
    borderColor: "#0F4C59",
  },
  footer_line: {
    height: 60,
    width: "100%",
    backgroundColor: "#171717",
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  cancel_txt: {
    color: "#0F4059",
    fontSize: 20,
    fontWeight: "bold",
  },
  footer_txt: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  register_btn: {
    flexDirection: "row",
    height: 72 * 0.6,
    width: 182,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "#0F4C59",
    margin: 25,
    paddingTop: 30,
    paddingBottom: 30,
  },
  register_txt: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

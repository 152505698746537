import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  Wrap,
  r,
} from "../../shared/custom-react-native";
import mem from "../../shared/Components/Memory/js";
import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";

export default class MySchedule extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
        //Initial State Here
        current_page: 1,
        entries: 4,
        data: [],
        number_of_pages: 1,
        pages_array: [],
        specialization_id: 1,
      },
      {
        role: "lawyer",
      }
    );
  }


  render() {
 
    const { width } = this.state;
    const horizontal_padding = width * 0.025;
    const none = 0;
    const x = width;
    let pillar = 6;
    let minuses = 220;
    let space = 0;
    let spacetop = 0;
    if(width < 1119)
    {
     minuses = 200;
     pillar = 5;
    }
    if (width < 1080) {
      minuses = 180;
      pillar = 4;
    }
    if (width < 960) {
      minuses = 160;
      pillar = 3;
   
    }
    if (width < 780) {
      minuses = 140;
      pillar = 2;
      
    }
    if (width < 660) {
      minuses = 120;
      pillar = 1;
      spacetop = "20px";
    }
    const boxwidth = (x - minuses) / pillar - horizontal_padding / 2;
    const left = width * 0.060;
    const margintop = spacetop;
   

    return (
      <MainLayout _this={this} active_tab={"My Schedule"}>
        <View style={{ width: "100%", height: "200%", backgroundColor: "#F9FAFF",  
        paddingLeft: horizontal_padding,
            paddingRight: horizontal_padding,}}>
         
        {UI.box(35)}
          <View style={styles.content_container}>
            <View style={styles.content_subcontainer}>

            <View style={{}}>
                  <Text style={styles.schedule_title}>My Schedule</Text>
            </View>
       
            {UI.box(20)}

            <View style={{ ...Shadow._4(), width:"100%"}}>
            {UI.box(10)}
                 <View style={{ paddingLeft:"20px" }}>
                    <Text style={{ fontSize:"28px", fontStyle:"Quicksand", color:"#0F4059" }}>Set Your Availability</Text>
                  </View>

                    <View style={{ flexDirection:"row", flexWrap: "wrap",   marginLeft: width < 1119 ? left : none, padding:"15px"  }}>
                      {/* FIRST */}
                          <View style={{ width:boxwidth, 
                            
                             marginTop:margintop  }}>

                          <View>
                                  <View style={{ alignSelf:"center" }}>
                                    <Text style={{ fontSize:"28px", fontStyle:"Quicksand", color:"#0F4059" }}>Sunday</Text>      
                                  </View>  

                                  {UI.box(15)}  
                                  
                                  <View style={{ 
                                    borderRightWidth: 2,
                                    borderRightColor: "#707070",
                                    borderRightStyle: "solid",   
                                    borderTopWidth: 2,
                                    borderTopColor: "#707070",
                                    borderTopStyle: "solid",  
                                    borderBottomWidth: 2,
                                    borderBottomColor: "#707070",
                                    borderBottomStyle: "solid",     
                                  padding:"20px",
                                  }}>

                                    <View>
                                      <Text > Start </Text>  
                                      {UI.box(10)}   
                                      <View style={{ 
                                          border:" 0.5px solid #707070",
                                          width:"100%",
                                        }}>
                                          <input
                                          
                                            value={this.state.selected_time}
                                            type={"time"} 
                                            style={styles.input_style}
                                            
                                          />
                                      </View>
                                    </View>

                                    {UI.box(15)}
                                    
                                      <View>
                                          <Text> End </Text> 
                                          {UI.box(10)} 
                                          <View style={{ 
                                            border:" 0.5px solid #707070",
                                            width:"100%"
                                          }}>
                                              <input
                                                value={this.state.selected_time}
                                                type={"time"} 
                                                style={styles.input_style}
                                              />
                                          </View>
                                      </View>
                                  </View>
                          </View>

                          <View>
                                <View style={{ 
                                  borderRightWidth: 2,
                                  borderRightColor: "#707070",
                                  borderRightStyle: "solid",   
                                  borderBottomWidth: 2,
                                  borderBottomColor: "#707070",
                                  borderBottomStyle: "solid",     
                                padding:"20px",
                                }}>

                                  <View>
                                    <Text > Start </Text>  
                                    {UI.box(10)}   
                                    <View style={{ 
                                        border:" 0.5px solid #707070",
                                        width:"100%",
                                      }}>
                                        <input
                                          value={this.state.selected_time}
                                          type={"time"} 
                                          style={styles.input_style}
                                        />
                                    </View>
                                  </View>

                                  {UI.box(15)}
                                  
                                  <View>
                                      <Text> End </Text> 
                                      {UI.box(10)} 
                                      <View style={{ 
                                        border:" 0.5px solid #707070",
                                        width:"100%"
                                      }}>
                                          <input
                                            value={this.state.selected_time}
                                            type={"time"} 
                                            style={styles.input_style}
                                          />
                                      </View>
                                  </View>
                              </View>
                        </View>

                        </View>


                    {/* SECOND */}

                  <View style={{ width:boxwidth, 
                  
                    marginTop:margintop}}>

                    <View>
                            <View style={{ alignSelf:"center" }}>
                              <Text style={{ fontSize:"28px", fontStyle:"Quicksand", color:"#0F4059" }}>Monday</Text>      
                            </View>  

                            {UI.box(15)}  
                            
                            <View style={{ 
                              borderRightWidth: 2,
                              borderRightColor: "#707070",
                              borderRightStyle: "solid",   
                              borderTopWidth: 2,
                              borderTopColor: "#707070",
                              borderTopStyle: "solid",  
                              borderBottomWidth: 2,
                              borderBottomColor: "#707070",
                              borderBottomStyle: "solid",     
                            padding:"20px",
                            }}>

                              <View>
                                <Text > Start </Text>  
                                {UI.box(10)}   
                                <View style={{ 
                                    border:" 0.5px solid #707070",
                                    width:"100%",
                                  }}>
                                    <input
                                      value={this.state.selected_time}
                                      type={"time"} 
                                      style={styles.input_style}
                                    />
                                </View>
                              </View>

                              {UI.box(15)}
                              
                                <View>
                                    <Text> End </Text> 
                                    {UI.box(10)} 
                                    <View style={{ 
                                      border:" 0.5px solid #707070",
                                      width:"100%"
                                    }}>
                                        <input
                                          value={this.state.selected_time}
                                          type={"time"} 
                                          style={styles.input_style}
                                        />
                                    </View>
                                </View>
                            </View>
                    </View>

                    <View>
                          <View style={{ 
                            borderRightWidth: 2,
                            borderRightColor: "#707070",
                            borderRightStyle: "solid",   
                            borderBottomWidth: 2,
                            borderBottomColor: "#707070",
                            borderBottomStyle: "solid",     
                          padding:"20px",
                          }}>

                            <View>
                              <Text > Start </Text>  
                              {UI.box(10)}   
                              <View style={{ 
                                  border:" 0.5px solid #707070",
                                  width:"100%",
                                }}>
                                  <input
                                    value={this.state.selected_time}
                                    type={"time"} 
                                    style={styles.input_style}
                                  />
                              </View>
                            </View>

                            {UI.box(15)}
                            
                            <View>
                                <Text> End </Text> 
                                {UI.box(10)} 
                                <View style={{ 
                                  border:" 0.5px solid #707070",
                                  width:"100%"
                                }}>
                                    <input
                                      value={this.state.selected_time}
                                      type={"time"} 
                                      style={styles.input_style}
                                    />
                                </View>
                            </View>
                        </View>
                  </View>

                  </View>

                  {/* THIRD */}

                  <View style={{ width:boxwidth, 
                  
                     marginTop:margintop}}>

                    <View>
                            <View style={{ alignSelf:"center" }}>
                              <Text style={{ fontSize:"28px", fontStyle:"Quicksand", color:"#0F4059" }}>Tuesday</Text>      
                            </View>  

                            {UI.box(15)}  
                            
                            <View style={{ 
                              borderRightWidth: 2,
                              borderRightColor: "#707070",
                              borderRightStyle: "solid",   
                              borderTopWidth: 2,
                              borderTopColor: "#707070",
                              borderTopStyle: "solid",  
                              borderBottomWidth: 2,
                              borderBottomColor: "#707070",
                              borderBottomStyle: "solid",     
                            padding:"20px",
                            }}>

                              <View>
                                <Text > Start </Text>  
                                {UI.box(10)}   
                                <View style={{ 
                                    border:" 0.5px solid #707070",
                                    width:"100%",
                                  }}>
                                    <input
                                      value={this.state.selected_time}
                                      type={"time"} 
                                      style={styles.input_style}
                                    />
                                </View>
                              </View>

                              {UI.box(15)}
                              
                                <View>
                                    <Text> End </Text> 
                                    {UI.box(10)} 
                                    <View style={{ 
                                      border:" 0.5px solid #707070",
                                      width:"100%"
                                    }}>
                                        <input
                                          value={this.state.selected_time}
                                          type={"time"} 
                                          style={styles.input_style}
                                        />
                                    </View>
                                </View>
                            </View>
                    </View>

                    <View>
                          <View style={{ 
                            borderRightWidth: 2,
                            borderRightColor: "#707070",
                            borderRightStyle: "solid",   
                            borderBottomWidth: 2,
                            borderBottomColor: "#707070",
                            borderBottomStyle: "solid",     
                          padding:"20px",
                          }}>

                            <View>
                              <Text > Start </Text>  
                              {UI.box(10)}   
                              <View style={{ 
                                  border:" 0.5px solid #707070",
                                  width:"100%",
                                }}>
                                  <input
                                    value={this.state.selected_time}
                                    type={"time"} 
                                    style={styles.input_style}
                                  />
                              </View>
                            </View>

                            {UI.box(15)}
                            
                            <View>
                                <Text> End </Text> 
                                {UI.box(10)} 
                                <View style={{ 
                                  border:" 0.5px solid #707070",
                                  width:"100%"
                                }}>
                                    <input
                                      value={this.state.selected_time}
                                      type={"time"} 
                                      style={styles.input_style}
                                    />
                                </View>
                            </View>
                        </View>
                  </View>

                  </View>

                  {/* FOURTH */}

                  <View style={{ width:boxwidth, 
                   
                     marginTop:margintop }} >

                    <View>
                            <View style={{ alignSelf:"center" }}>
                              <Text style={{ fontSize:"28px", fontStyle:"Quicksand", color:"#0F4059" }}>Wednesday</Text>      
                            </View>  

                            {UI.box(15)}  
                            
                            <View style={{ 
                              borderRightWidth: 2,
                              borderRightColor: "#707070",
                              borderRightStyle: "solid",   
                              borderTopWidth: 2,
                              borderTopColor: "#707070",
                              borderTopStyle: "solid",  
                              borderBottomWidth: 2,
                              borderBottomColor: "#707070",
                              borderBottomStyle: "solid",     
                            padding:"20px",
                            }}>

                              <View>
                                <Text > Start </Text>  
                                {UI.box(10)}   
                                <View style={{ 
                                    border:" 0.5px solid #707070",
                                    width:"100%",
                                  }}>
                                    <input
                                      value={this.state.selected_time}
                                      type={"time"} 
                                      style={styles.input_style}
                                    />
                                </View>
                              </View>

                              {UI.box(15)}
                              
                                <View>
                                    <Text> End </Text> 
                                    {UI.box(10)} 
                                    <View style={{ 
                                      border:" 0.5px solid #707070",
                                      width:"100%"
                                    }}>
                                        <input
                                          value={this.state.selected_time}
                                          type={"time"} 
                                          style={styles.input_style}
                                        />
                                    </View>
                                </View>
                            </View>
                    </View>

                    <View>
                          <View style={{ 
                            borderRightWidth: 2,
                            borderRightColor: "#707070",
                            borderRightStyle: "solid",   
                            borderBottomWidth: 2,
                            borderBottomColor: "#707070",
                            borderBottomStyle: "solid",     
                          padding:"20px",
                          }}>

                            <View>
                              <Text > Start </Text>  
                              {UI.box(10)}   
                              <View style={{ 
                                  border:" 0.5px solid #707070",
                                  width:"100%",
                                }}>
                                  <input
                                    value={this.state.selected_time}
                                    type={"time"} 
                                    style={styles.input_style}
                                  />
                              </View>
                            </View>

                            {UI.box(15)}
                            
                            <View>
                                <Text> End </Text> 
                                {UI.box(10)} 
                                <View style={{ 
                                  border:" 0.5px solid #707070",
                                  width:"100%"
                                }}>
                                    <input
                                      value={this.state.selected_time}
                                      type={"time"} 
                                      style={styles.input_style}
                                    />
                                </View>
                            </View>
                        </View>
                  </View>

                  </View>

                  
                  {/* FIFTH */}

                  <View style={{ width:boxwidth, 
                    
                     marginTop:margintop}}>

                    <View>
                            <View style={{ alignSelf:"center" }}>
                              <Text style={{ fontSize:"28px", fontStyle:"Quicksand", color:"#0F4059" }}>Thursday</Text>      
                            </View>  

                            {UI.box(15)}  
                            
                            <View style={{ 
                              borderRightWidth: 2,
                              borderRightColor: "#707070",
                              borderRightStyle: "solid",   
                              borderTopWidth: 2,
                              borderTopColor: "#707070",
                              borderTopStyle: "solid",  
                              borderBottomWidth: 2,
                              borderBottomColor: "#707070",
                              borderBottomStyle: "solid",     
                            padding:"20px",
                            }}>

                              <View>
                                <Text > Start </Text>  
                                {UI.box(10)}   
                                <View style={{ 
                                    border:" 0.5px solid #707070",
                                    width:"100%",
                                  }}>
                                    <input
                                      value={this.state.selected_time}
                                      type={"time"} 
                                      style={styles.input_style}
                                    />
                                </View>
                              </View>

                              {UI.box(15)}
                              
                                <View>
                                    <Text> End </Text> 
                                    {UI.box(10)} 
                                    <View style={{ 
                                      border:" 0.5px solid #707070",
                                      width:"100%"
                                    }}>
                                        <input
                                          value={this.state.selected_time}
                                          type={"time"} 
                                          style={styles.input_style}
                                        />
                                    </View>
                                </View>
                            </View>
                    </View>

                    <View>
                          <View style={{ 
                            borderRightWidth: 2,
                            borderRightColor: "#707070",
                            borderRightStyle: "solid",   
                            borderBottomWidth: 2,
                            borderBottomColor: "#707070",
                            borderBottomStyle: "solid",     
                          padding:"20px",
                          }}>

                            <View>
                              <Text > Start </Text>  
                              {UI.box(10)}   
                              <View style={{ 
                                  border:" 0.5px solid #707070",
                                  width:"100%",
                                }}>
                                  <input
                                    value={this.state.selected_time}
                                    type={"time"} 
                                    style={styles.input_style}
                                  />
                              </View>
                            </View>

                            {UI.box(15)}
                            
                            <View>
                                <Text> End </Text> 
                                {UI.box(10)} 
                                <View style={{ 
                                  border:" 0.5px solid #707070",
                                  width:"100%"
                                }}>
                                    <input
                                      value={this.state.selected_time}
                                      type={"time"} 
                                      style={styles.input_style}
                                    />
                                </View>
                            </View>
                        </View>
                  </View>

                  </View>


                    {/* SIXTH */}

                  <View style={{ width:boxwidth, 
                
                    marginTop:margintop }}>

                    <View>
                            <View style={{ alignSelf:"center" }}>
                              <Text style={{ fontSize:"28px", fontStyle:"Quicksand", color:"#0F4059" }}>Friday</Text>      
                            </View>  

                            {UI.box(15)}  
                            
                            <View style={{ 
                              borderRightWidth: 2,
                              borderRightColor: "#707070",
                              borderRightStyle: "solid",   
                              borderTopWidth: 2,
                              borderTopColor: "#707070",
                              borderTopStyle: "solid",  
                              borderBottomWidth: 2,
                              borderBottomColor: "#707070",
                              borderBottomStyle: "solid",     
                            padding:"20px",
                            }}>

                              <View>
                                <Text > Start </Text>  
                                {UI.box(10)}   
                                <View style={{ 
                                    border:" 0.5px solid #707070",
                                    width:"100%",
                                  }}>
                                    <input
                                      value={this.state.selected_time}
                                      type={"time"} 
                                      style={styles.input_style}
                                    />
                                </View>
                              </View>

                              {UI.box(15)}
                              
                                <View>
                                    <Text> End </Text> 
                                    {UI.box(10)} 
                                    <View style={{ 
                                      border:" 0.5px solid #707070",
                                      width:"100%"
                                    }}>
                                        <input
                                          value={this.state.selected_time}
                                          type={"time"} 
                                          style={styles.input_style}
                                        />
                                    </View>
                                </View>
                            </View>
                    </View>

                    <View>
                          <View style={{ 
                            borderRightWidth: 2,
                            borderRightColor: "#707070",
                            borderRightStyle: "solid",   
                            borderBottomWidth: 2,
                            borderBottomColor: "#707070",
                            borderBottomStyle: "solid",     
                          padding:"20px",
                          }}>

                            <View>
                              <Text > Start </Text>  
                              {UI.box(10)}   
                              <View style={{ 
                                  border:" 0.5px solid #707070",
                                  width:"100%",
                                }}>
                                  <input
                                    value={this.state.selected_time}
                                    type={"time"} 
                                    style={styles.input_style}
                                  />
                              </View>
                            </View>

                            {UI.box(15)}
                            
                            <View>
                                <Text> End </Text> 
                                {UI.box(10)} 
                                <View style={{ 
                                  border:" 0.5px solid #707070",
                                  width:"100%"
                                }}>
                                    <input
                                      value={this.state.selected_time}
                                      type={"time"} 
                                      style={styles.input_style}
                                    />
                                </View>
                            </View>
                        </View>
                  </View>

                  </View>

                    {/* SEVENTH */}

                    <View style={{ width:boxwidth, 
                      
                      marginTop:margintop}}>

                      <View>
                            <View style={{ alignSelf:"center" }}>
                              <Text style={{ fontSize:"28px", fontStyle:"Quicksand", color:"#0F4059" }}>Saturday</Text>      
                            </View>  

                            {UI.box(15)}  
                            
                            <View style={{ 
                            
                              borderTopWidth: 2,
                              borderTopColor: "#707070",
                              borderTopStyle: "solid",  
                              borderBottomWidth: 2,
                              borderBottomColor: "#707070",
                              borderBottomStyle: "solid",     
                            padding:"20px",
                            }}>

                              <View>
                                <Text> Start </Text>  
                                {UI.box(10)}   
                                <View style={{ 
                                    border:" 0.5px solid #707070",
                                    width:"100%",
                                  }}>
                                    <input
                                      value={this.state.selected_time}
                                      type={"time"} 
                                      style={styles.input_style}
                                    />
                                </View>
                              </View>

                              {UI.box(15)}
                              
                                <View>
                                    <Text> End </Text> 
                                    {UI.box(10)} 
                                    <View style={{ 
                                      border:" 0.5px solid #707070",
                                      width:"100%"
                                    }}>
                                        <input
                                          value={this.state.selected_time}
                                          type={"time"} 
                                          style={styles.input_style}
                                        />
                                    </View>
                                </View>
                            </View>
                      </View>

                      <View>
                          <View style={{ 
                            borderBottomWidth: 2,
                            borderBottomColor: "#707070",
                            borderBottomStyle: "solid",     
                          padding:"20px",
                          }}>

                            <View>
                              <Text > Start </Text>  
                              {UI.box(10)}   
                              <View style={{ 
                                  border:" 0.5px solid #707070",
                                  width:"100%",
                                }}>
                                  <input
                                    value={this.state.selected_time}
                                    type={"time"} 
                                    style={styles.input_style}
                                  />
                              </View>
                            </View>

                            {UI.box(15)}
                            
                            <View>
                                <Text> End </Text> 
                                {UI.box(10)} 
                                <View style={{ 
                                  border:" 0.5px solid #707070",
                                  width:"100%"
                                }}>
                                    <input
                                      value={this.state.selected_time}
                                      type={"time"} 
                                      style={styles.input_style}
                                    />
                                </View>
                            </View>
                        </View>
                      </View>

                    </View>

            
                    </View>


                  
                    <View style={{ flexDirection:"row", paddingTop:"20px", 
                    justifyContent: width < 1119 ? "center" : "right", paddingBottom:"20px", paddingRight:"20px"}}>

                        <TouchableOpacity 
                         style={{ 
                            width: 130, 
                            alignItems: "center", 
                            paddingTop: 12,
                            backgroundColor: "#0F4059",
                            height: "50px",
                            borderRadius:"5px",
                            marginRight:"20px"
                          }}>
                        <Text style={{  
                         color: "#FFFFFF", 
                        fontSize: 14,
                         fontWeight: "bold",
                          }}>
                        Update
                        </Text>
                        </TouchableOpacity>

                        <TouchableOpacity 
                         style={{ 
                          width: 130, 
                          alignItems: "center", 
                          paddingTop: 12,
                          backgroundColor: "#F26E50",
                          height: "50px",
                          borderRadius:"5px",
                          }}>
                        <Text style={{ 
                           color: "#FFFFFF", 
                           fontSize: 14,
                            fontWeight: "bold",
                         }}>
                        Cancel
                        </Text>
                        </TouchableOpacity>
                    </View>
          </View>
          

            
            </View>
          </View>

          {UI.box(80)}
        </View>
      </MainLayout>
    );
  }
}

const styles = StyleSheet.create({
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  
  },

  content_subcontainer: {
    width: "auto",
    borderRadius: 5,
    justifyContent: "center",
    opacity: 1,
   
  },

  schedule_title:{
    fontStyle: "Quicksand",
    color: "#575757",
    fontWeight: "bold",
    fontSize: "35px",
  },
  
 

  ///
//   categories_container: {
//     flexDirection: "row",
//     flexWrap: "wrap",
//     marginLeft: "10px",
//   },


  input_style: {
    padding: 12,
    fontSize: 16,
    backgroundColor: "#F9FAFF",
   
  },
  
  
});

import React, { Component } from "react";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  Wrap,
} from "../../shared/custom-react-native";
import "./css.css";
import UI from "../../shared/Components/UI/js";
import { Modal } from "@chakra-ui/react";

export default class ZLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const _this = this.props._this;
    return (
      <View>
        <Loading _this={_this} />
        <CustomAlert _this={_this} />
      </View>
    );
  }
}
/* 














 */
class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      message: "",
      speed: 1.1,
    };
  }

  componentDidMount = async () => {
    const _this = this.props._this;
    _this.show_loading = this.show_loading;
    _this.hide_loading = this.hide_loading;
  };

  show_loading = (txt, onDismiss) => {
    this.setState({
      is_loading: true,
      message: txt,
    });
  };

  hide_loading = () => {
    this.setState({
      is_loading: false,
      message: "",
    });
  };

  render() {
    const _this = this.props._this;
    const { is_loading } = this.state;
    return (
      <View
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0.85)",
          zIndex: 100000,
          justifyContent: "center",
          alignItems: "center",
          display: is_loading ? "flex" : "none",
        }}
      >
        <img
          style={{
            height: 60,
            width: 60,
            userSelect: "none",
            animation: `spin ${this.state.speed}s linear infinite`,
          }}
          src={UI.LOADER}
        />
        {UI.box(20)}
        <Text style={{ textAlign: "center", color: "white", fontSize: 14 }}>
          {this.state.message}
        </Text>
      </View>
    );
  }
}

/* 














 */

class CustomAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_modal: false,
      message: "",
      speed: 1.1,
      onDismiss: () => {},
    };
  }

  componentDidMount = async () => {
    const _this = this.props._this;
    _this.alert = this.alert;
    _this.hide_alert = this.hide_alert;
  };

  alert = (txt, onDismiss) => {
    this.setState({
      is_modal: true,
      message: txt,
      onDismiss: onDismiss ? onDismiss : () => {},
    });
  };

  hide_alert = () => {
    this.setState({
      is_modal: false,
      message: "",
    });
  };

  render() {
    const _this = this.props._this;
    const { is_modal, message, onDismiss } = this.state;
    return (
      <View
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0.85)",
          zIndex: 100000,
          justifyContent: "center",
          alignItems: "center",
          display: is_modal ? "flex" : "none",
        }}
      >
        <View
          style={{
            maxWidth: 300,
            borderRadius: 10,
            backgroundColor: "white",
            minWidth: 100,
            padding: 20,
          }}
        >
          <Wrap>
            <Text style={{ fontSize: 14, fontWeight: "bold" }}>{message}</Text>
          </Wrap>

          {UI.box(20)}

          <TouchableOpacity
            onClick={() => {
              this.hide_alert();
              if (onDismiss) {
                onDismiss();
              }
            }}
            style={{
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              padding: 20,
              backgroundColor: "#1c1c1c",
              paddingTop: 10,
              alignSelf: "center",
              paddingBottom: 10,
            }}
          >
            <Text style={{ color: "white", fontSize: 14, fontWeight: "bold" }}>
              Dismiss
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  Wrap,
  r,
} from "../../shared/custom-react-native";

import UI from "../../shared/Components/UI/js";
import UserMainLayout from "../_components/User-MainLayout";

export default class BookingList extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
        //Initial State Here
        // signin_string: "Hello world",
        // icon: UI.LOGIN_BANNER,
      },
      {
        role: "client",
      }
    );
  }

  onCreate = async () => {
    this.show_loading();
    r.request({
      method: "get",
      url: "/lawyers/available-schedule",
      params: {},
      onFail: (e) => {},
      onSuccess: (e) => {},
      onFinish: () => {
        this.hide_loading();
      },
    });
  };

  render() {
    const { width } = this.state;
    const horizontal_padding = width * 0.015;
    const w = width;
    let columns = 4;
    let minus = 160;
    if (width < 960) {
      minus = 140;
      columns = 3;
    }
    if (width < 780) {
      minus = 120;
      columns = 2;
    }
    if (width < 660) {
      minus = 80;
      columns = 1;
    }
    const itemwidth = (w - minus) / columns - (horizontal_padding / 2 + 10);

    return (
      <UserMainLayout _this={this} active_tab={"Booking"}>
        <View style={{ width: "100%", backgroundColor: "#F9FAFF" }}>
          {UI.box(20)}

          <View style={styles.content_container}>
            <View style={styles.content_subcontainer}>
            <View style={{ flexDirection: w < 470 ? "column" : "row" }}>
                <View style={{ paddingRight: "20px" }}>
                  <Text style={styles.content_title}>Booking</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    marginTop: "15px",
                    marginBottom: "20px",
                  }}
                >
                  <TouchableOpacity
                    
                  >
                    <Text style={styles.booking_subtitle}>Booking /</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                   
                  >
                    <Text style={styles.booking_subtitle}>
                      Current Appointments
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>


              <View
                style={{
                  flexDirection: "row",
                  paddingBottom:"15px"
                }}
              >
                <TouchableOpacity style={{ paddingRight: "25px" }}>
                  <Text style={styles.content_subTitle}>Current Appointment</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{ paddingRight: "20px" }}>
                  <Text style={styles.content_subTitle}>Booking History</Text>
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <TouchableOpacity style={{ paddingRight: "25px" }}>
                  <Text style={styles.content_subTitle}>All</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{ paddingRight: "20px" }}>
                  <Text style={styles.content_subTitle}>Pending</Text>
                </TouchableOpacity>
                <TouchableOpacity>
                  <Text style={styles.content_subTitle}>Previous</Text>
                </TouchableOpacity>
              </View>

           

              <View style={styles.consultation_container}>
                {/* FIRST */}

                <View
                  style={{
                    width: itemwidth,
                    borderRadius: "10px",
                    ...Shadow._4(),
                    marginRight: "20px",
                    marginTop: "20px",
                    alignItems: w < 730 ? "center" : "none",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingLeft: 13,
                      paddingRight: w < 365 ? 35 : 13,
                      paddingTop: 20,
                    }}
                  >
                    <View style={{ marginRight: w < 780 ? "30px" : "0px" }}>
                      <img style={styles.profile_img} src={UI.CARMEN} />
                    </View>

                    <View style={{}}>
                      <Text style={styles.profile_DateText}> 10 </Text>
                      <Text style={styles.profile_MonthText}>March</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                    <Text style={styles.profile_Textfirst}>
                      {" "}
                      Joshua Barnes{" "}
                    </Text>
                    <Text style={styles.profile_Textsecond}>
                      Finance & Security Lawyer
                    </Text>
                    <Text style={styles.profile_Textsecond}>
                      15 Years of Experience | Class of 200{" "}
                    </Text>
                  </View>

                  <View
                    style={{
                      paddingLeft: w < 730 ? 35 : 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                    <View style={{ flexDirection: "row", paddingBottom: 10 }}>
                      <View style={{ paddingRight: 10 }}>
                        <img style={styles.school} src={UI.SCHOOL} />
                      </View>
                      <View style={{}}>
                        <Text style={styles.profile_ContactText}>
                          University of the Philippines
                        </Text>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View style={{ paddingRight: 10 }}>
                        <img style={styles.map_marker} src={UI.Map_Marker} />
                      </View>
                      <View style={{}}>
                        <Text style={styles.profile_ContactText}>
                          {" "}
                          No. 123, Sub Street, Main Street, City Name, Province{" "}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Schedule
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Wed, March 26, 2021 | 10:30 am
                      </Text>
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        https://zoom.us/meetings
                      </Text>
                    </View>
                  </View>
                  <View style={styles.borderMini} />
                </View>

                {/* SECOND
                 */}

                <View
                  style={{
                    width: itemwidth,
                    borderRadius: "10px",
                    ...Shadow._4(),
                    marginRight: "20px",
                    marginTop: "20px",
                    alignItems: w < 730 ? "center" : "none",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingLeft: 13,
                      paddingRight: w < 365 ? 35 : 13,
                      paddingTop: 20,
                    }}
                  >
                    <View style={{ marginRight: w < 780 ? "30px" : "0px" }}>
                      <img style={styles.profile_img} src={UI.CARMEN} />
                    </View>

                    <View style={{}}>
                      <Text style={styles.profile_DateText}> 10 </Text>
                      <Text style={styles.profile_MonthText}>March</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                    <Text style={styles.profile_Textfirst}>
                      {" "}
                      Joshua Barnes{" "}
                    </Text>
                    <Text style={styles.profile_Textsecond}>
                      Finance & Security Lawyer
                    </Text>
                    <Text style={styles.profile_Textsecond}>
                      15 Years of Experience | Class of 200{" "}
                    </Text>
                  </View>

                  <View
                    style={{
                      paddingLeft: w < 730 ? 35 : 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                    <View style={{ flexDirection: "row", paddingBottom: 10 }}>
                      <View style={{ paddingRight: 10 }}>
                        <img style={styles.school} src={UI.SCHOOL} />
                      </View>
                      <View style={{}}>
                        <Text style={styles.profile_ContactText}>
                          University of the Philippines
                        </Text>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View style={{ paddingRight: 10 }}>
                        <img style={styles.map_marker} src={UI.Map_Marker} />
                      </View>
                      <View style={{}}>
                        <Text style={styles.profile_ContactText}>
                          {" "}
                          No. 123, Sub Street, Main Street, City Name, Province{" "}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Schedule
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Wed, March 26, 2021 | 10:30 am
                      </Text>
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        https://zoom.us/meetings
                      </Text>
                    </View>
                  </View>
                  <View style={styles.borderMini} />
                </View>

                {/* Third */}

                <View
                  style={{
                    width: itemwidth,
                    borderRadius: "10px",
                    ...Shadow._4(),
                    marginRight: "20px",
                    marginTop: "20px",
                    alignItems: w < 730 ? "center" : "none",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingLeft: 13,
                      paddingRight: w < 365 ? 35 : 13,
                      paddingTop: 20,
                    }}
                  >
                    <View style={{ marginRight: w < 780 ? "30px" : "0px" }}>
                      <img style={styles.profile_img} src={UI.CARMEN} />
                    </View>

                    <View style={{}}>
                      <Text style={styles.profile_DateText}> 10 </Text>
                      <Text style={styles.profile_MonthText}>March</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                    <Text style={styles.profile_Textfirst}>
                      {" "}
                      Joshua Barnes{" "}
                    </Text>
                    <Text style={styles.profile_Textsecond}>
                      Finance & Security Lawyer
                    </Text>
                    <Text style={styles.profile_Textsecond}>
                      15 Years of Experience | Class of 200{" "}
                    </Text>
                  </View>

                  <View
                    style={{
                      paddingLeft: w < 730 ? 35 : 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                    <View style={{ flexDirection: "row", paddingBottom: 10 }}>
                      <View style={{ paddingRight: 10 }}>
                        <img style={styles.school} src={UI.SCHOOL} />
                      </View>
                      <View style={{}}>
                        <Text style={styles.profile_ContactText}>
                          University of the Philippines
                        </Text>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View style={{ paddingRight: 10 }}>
                        <img style={styles.map_marker} src={UI.Map_Marker} />
                      </View>
                      <View style={{}}>
                        <Text style={styles.profile_ContactText}>
                          {" "}
                          No. 123, Sub Street, Main Street, City Name, Province{" "}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Schedule
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Wed, March 26, 2021 | 10:30 am
                      </Text>
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        https://zoom.us/meetings
                      </Text>
                    </View>
                  </View>
                  <View style={styles.borderMini} />
                </View>

                {/* Fourth */}

                <View
                  style={{
                    width: itemwidth,
                    borderRadius: "10px",
                    ...Shadow._4(),
                    marginRight: "20px",
                    marginTop: "20px",
                    alignItems: w < 730 ? "center" : "none",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingLeft: 13,
                      paddingRight: w < 365 ? 35 : 13,
                      paddingTop: 20,
                    }}
                  >
                    <View style={{ marginRight: w < 780 ? "30px" : "0px" }}>
                      <img style={styles.profile_img} src={UI.CARMEN} />
                    </View>

                    <View style={{}}>
                      <Text style={styles.profile_DateText}> 10 </Text>
                      <Text style={styles.profile_MonthText}>March</Text>
                    </View>
                  </View>

                  <View
                    style={{
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                    <Text style={styles.profile_Textfirst}>
                      {" "}
                      Joshua Barnes{" "}
                    </Text>
                    <Text style={styles.profile_Textsecond}>
                      Finance & Security Lawyer
                    </Text>
                    <Text style={styles.profile_Textsecond}>
                      15 Years of Experience | Class of 200{" "}
                    </Text>
                  </View>

                  <View
                    style={{
                      paddingLeft: w < 730 ? 35 : 20,
                      paddingRight: w < 730 ? 35 : 20,
                      paddingBottom: 20,
                    }}
                  >
                    <View style={{ flexDirection: "row", paddingBottom: 10 }}>
                      <View style={{ paddingRight: 10 }}>
                        <img style={styles.school} src={UI.SCHOOL} />
                      </View>
                      <View style={{}}>
                        <Text style={styles.profile_ContactText}>
                          University of the Philippines
                        </Text>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View style={{ paddingRight: 10 }}>
                        <img style={styles.map_marker} src={UI.Map_Marker} />
                      </View>
                      <View style={{}}>
                        <Text style={styles.profile_ContactText}>
                          {" "}
                          No. 123, Sub Street, Main Street, City Name, Province{" "}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        paddingTop: "10px",
                        textAlign: w < 730 ? "center" : "none",
                        marginRight: w < 730 ? "30px" : "0px",
                      }}
                    >
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Schedule
                      </Text>
                      <Text
                        style={{
                          color: "#F26E50",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        Wed, March 26, 2021 | 10:30 am
                      </Text>
                      <Text
                        style={{
                          color: "#575757",
                          fontSize: "14px",
                          fontStyle: "Quicksand",
                        }}
                      >
                        https://zoom.us/meetings
                      </Text>
                    </View>
                  </View>
                  <View style={styles.borderMini} />
                </View>
              </View>

              <View
                style={{
                  width: "auto",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                <TouchableOpacity
                  style={{
                    borderRadius: 5,
                    borderWidth: 1,
                    backgroundColor: "#F26E50",
                    marginRight: "20px",
                    padding: "7px",
                    width: "50px",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", color: "#FFFFFF" }}>
                    {" "}
                    1{" "}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    borderRadius: 5,
                    marginRight: "20px",
                    padding: "7px",
                    width: "50px",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", color: "#F26E50" }}>
                    {" "}
                    2{" "}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    borderRadius: 5,
                    marginRight: "20px",
                    padding: "7px",
                    width: "50px",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "20px", color: "#F26E50" }}>
                    {" "}
                    3{" "}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>

          {UI.box(20)}
        </View>
      </UserMainLayout>
    );
  }
}

const styles = StyleSheet.create({
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  },

  content_subcontainer: {
    width: "auto",
    borderRadius: 5,
    padding: 10, /// width for inside
    paddingLeft: 55,
    justifyContent: "center",
    opacity: 1,
  },
  content_subTitle: {
    fontSize: "20px",
    color: "#575757",
    fontStyle: "Quicksand",
  },
  content_title: {
    fontStyle: "Quicksand",
    color: "#575757",
    fontWeight: "bold",
    fontSize: "35px",
    paddingBottom: "10px",
  },
  booking_subtitle: {
    fontSize: "17px",
    color: "#BABBAB",
    fontStyle: "Quicksand",
  },
  consultation_container: {
    flexDirection: "row",
    flexWrap: "wrap",
  },

  profile_img: {
    height: 120,
    width: 120,
    borderRadius:60,
  },
  profile_DateText: {
    fontStyle: "#F26E50",
    fontSize: "60px",
    color: "#F26E50",
  },
  profile_MonthText: {
    fontStyle: "#F26E50",
    fontSize: "30px",
    color: "#F26E50",
  },

  profile_Textfirst: {
    color: "#575757",
    fontStyle: "Quicksand",
    fontSize: "25px",
  },

  profile_Textsecond: {
    olor: "#BABBAB",
    fontStyle: "Quicksand",
    fontSize: 14,
  },
  school: {
    height: 25,
    width: 25,
    paddingRight: 5,
  },
  map_marker: {
    height: 20,
    width: 20,
  },
  profile_ContactText: {
    olor: "#0F4059",
    fontStyle: "Quicksand",
    fontSize: 14,
  },

  borderMini: {
    width: "100%",
    height: "20px",
    backgroundColor: "#F26E50",
    borderRadius: "0px 0px 10px 10px",
  },
});

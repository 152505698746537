import React, { Component } from "react";
import "./css.css";
import {
  TouchableOpacity,
  ScrollView,
  View,
  Image,
  ImageBackground,
  ImageFit,
  Text,
  StyleSheet,
  Shadow,
  r,
} from "../../shared/custom-react-native";

import UI from "../../shared/Components/UI/js";
import MainLayout from "../_components/MainLayout";
import mem from "../../shared/Components/Memory/js";
import { FormHelper } from "../_components/FormHelper";
import CalendarHelper from "../_components/CalendarHelper";


export default class AdminViewAll extends Component {
  constructor(props) {
    super(props);
    UI.initiateView(
      this,
      {
      },
      {
        role: "lawyer",
      }
    );
  }
  

  render() {
    const {
      width,
      data,
      user,
      calendar_days,
      available_schedules,
      schedule_selected,
      schedule_message,
      last_date,
      last_month,
    } = this.state;
    const w = width;

    const item = data;
    return (
      <MainLayout _this={this}>
        <View style={{ width: "100%", backgroundColor: "#F9FAFF" }}>
          {UI.box(20)}

          <View style={styles.content_container}>
            <View style={styles.content_subcontainer}>
              <Text style={styles.content_title}>Notifications</Text>

              {UI.box(10)}
           
              <View style={{ ...Shadow._4(), padding:20 }}>
                <NotificationsPage />
                <NotificationsPage />
                <NotificationsPage />
                <NotificationsPage />
                



                 
              </View>

            {UI.box(100)}
          </View>
          </View>

          {UI.box(20)}
        </View>
      </MainLayout>
    );
  }
}




class NotificationsPage extends Component {
    constructor(props){
        super(props);
        UI.initiateView(
            this,
            {

            },
            {
                role: "lawyer",
            }
          );
      } 

      
    render() {     
        const { width } = this.state;
        const w = width;
          return(
             <View>
            <View style={{ 
              flexDirection: w < 800 ? "column" : "row", 
              ...styles.notification_container,
             }}>
    
             <View style={{ flexDirection: w < 800 ? "column" : "row" }}>
                <View style={{paddingRight:"25px", alignItems: w < 800 ? "center" : "none" }}>
                    <img style={{ height: 80, width: 80, borderRadius: "50%",  marginTop: 10,}}
                    src={UI.ENRICO}/>
                </View>
    
                <View style={{ marginTop:"15px" , alignItems: w < 800 ? "center" : "none",}}>
                  <Text style={styles.Name_Text}>
                   Enrico Juliano
                  </Text>
                 
                   <Text style={styles.Body_Text}>
                   The client is trying to book a lawyer
                  </Text>
               </View>
            </View>
    
                <View style={{marginTop: w < 800 ? "15px" : "25px", alignItems: w < 800 ? "center" : "none",}}>
                    <TouchableOpacity onClick={() => {
                      UI.goTo("/admin/view-details");
                    }}
                     style={styles.ViewDetails_Button}>
                    <Text style={styles.Button_Text}>
                       View Details
                    </Text>
                    </TouchableOpacity>
                </View>

    
            </View>
 
                
            <View style={{width: "95%", alignSelf:"center",
             backgroundColor: "#575757",padding: 1}}/>
            
         

           </View>
        
          )
    }
  
  }



const styles = StyleSheet.create({
  content_container: {
    justifyContent: "center",
    alignItems: "center",
  },

  content_subcontainer: {
    width: "90%",
    // backgroundColor: "white",
    borderRadius: 5,
    padding: 10,
    paddingTop: 35,
    justifyContent: "center",
    opacity: 1,
  },

  content_title: {
    fontStyle: "Quicksand",
    color: "#575757",
    fontWeight: "bold",
    fontSize: "35px",
    paddingBottom: "10px",
  },

  notification_container:{
    borderRadius: "10px", 
   padding: "25px",
  justifyContent:"space-between"
  },

  ViewDetails_Button:{
    width: 120, 
    alignItems: "center", 
    paddingTop: 12, 
    backgroundColor: "#0F4059",
    height: "50px",
    borderRadius:"5px",
  },

  Button_Text:{
    color: "#FFFFFF", 
    fontSize: 14,
    fontWeight: "bold",
  },

  Name_Text:{
    fontStyle: "Quicksand", 
    color: "#575757", 
    fontWeight: "bold", 
    fontSize: "25px",
  },

  Body_Text:{
    fontStyle: "Quicksand", 
    color: "#0F4059", 
    fontWeight: 500, 
    fontSize: "15px",
  },
});
